import { BasePagination } from "@/components/atom/pagination/BasePagination";

type BasicPaginationType = {
  currentPage: number | undefined;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const BasicPagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: BasicPaginationType) => {
  return (
    <BasePagination
      style={{
        justifyContent: "center",
      }}
      total={totalPages}
      value={currentPage}
      onChange={onPageChange}
    />
  );
};
