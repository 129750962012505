
import DefaultInstance from "@/instance/axios";
import { createMutationKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiWmsFlushPutRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const mutateFlush = createMutationKeys('flush', {
    update: (params: DefaultApiWmsFlushPutRequest) => ({
        mutationKey: [params],
        mutationFn: () => DefaultInstance.wmsFlushPut(params)
    }),
});

export const defectQueryKeys = mergeQueryKeys(mutateFlush);
