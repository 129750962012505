import { CounterProps } from "@/hooks/useSocket.hook";
import { Socket, io } from "socket.io-client";
import create from "zustand";

interface SocketStore {
  socket: Socket | null;
  data: any;
  initializeSocket: () => void;
  joinRoom: (room: string) => void;
  leaveRoom: (room: string) => void;
  setData: (code: string, data: CounterProps) => void;
  getData: (code: string) => CounterProps | undefined;
}

export const useSocketStore = create<SocketStore>((set, get) => ({
  socket: null,
  data: null,
  initializeSocket: (): void => {
    const socket = io(process.env.REACT_APP_BASE_URL as string);
    socket.on("connect", () => {
      console.log("WebSocket에 연결됨");
    });
    useSocketStore.setState({ socket });
  },
  joinRoom: (room: string): void => {
    const { socket } = useSocketStore.getState();
    if (socket) {
      socket.emit("call", "socket.join", { room: room });
    }
  },
  leaveRoom: (room: string): void => {
    const { socket } = useSocketStore.getState();
    if (socket) {
      socket.emit("leave", room, (error?: string) => {
        if (error) {
          console.error(`Failed to leave room ${room}:`, error);
        }
      });
    }
  },
  setData: (code: string, data: CounterProps): void =>
    set({ data: { ...get().data, [code]: data } }),
  getData: (code: string) => get().data?.[code],
}));
