import { mutateWorks, WORK_KEY } from "@/api/work/useWorkQuery";
import { COMMON_TEXT } from "@/constants/text";
import { customNotification } from "@/utils/notificationShow";
import { DefaultApiWorksWorkIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSettingHandler = () => {
  const queryClient = useQueryClient();

  const { mutate: settingMutate, isLoading: settingLoading } = useMutation(
    (params: DefaultApiWorksWorkIdPutRequest) =>
      mutateWorks.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.SETTING_SUCCESS,
        });
        queryClient.invalidateQueries([WORK_KEY]);
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.SETTING_FAIL,
        });
      },
    }
  );

  const onSubmit = (params: DefaultApiWorksWorkIdPutRequest) => {
    settingMutate(params);
  };

  return {
    onSubmit,
  };
};
