import { HeaderActionButton } from "@/components/atom/button/headerAction/HeaderActionButton";
import { StatusButtonGroup } from "@/components/molecule/statusButtonGroup/StatusButtonGroup";
import { InputLogsForm } from "@/components/organism/form/InputLogsForm";
import { PalletLogsForm } from "@/components/organism/form/PalletLogsForm";
import { WorkLogsForm } from "@/components/organism/form/WorkLogsForm";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useStatusHandler } from "@/hooks/handler/useStatusHandler";
import { useWorkDataStore } from "@/store/work.store";
import { Flex } from "@mantine/core";
import {
  IconArticle,
  IconCircleArrowUpLeft,
  IconClipboardCheck,
  IconPlayerPause,
  IconPlayerPlay,
  IconPower,
  IconStack,
} from "@tabler/icons-react";

export interface ButtonConfig {
  condition?: () => boolean;
  buttons: {
    key: string;
    color: string;
    icon?: JSX.Element;
    onClick: () => void;
    text: string;
  }[];
}

const Header = Object.assign(
  {},
  {
    ActionButton: HeaderActionButton,
    StatusButtonGroup: StatusButtonGroup,
  }
);

export const WorkHeader = () => {
  const { openModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { handleStart, handleEnd, handlePause, handleResume, handleCancelEnd } =
    useStatusHandler();

  const logButtonConfigs: ButtonConfig[] = [
    {
      buttons: [
        {
          key: "log",
          color: "yellow.6",
          icon: <IconClipboardCheck />,
          onClick: () =>
            openModal(<InputLogsForm />, "", COMMON_TEXT.FORM.INPUT_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.INPUT_LOGS,
        },
        {
          key: "workLogs",
          color: "cyan.6",
          icon: <IconArticle />,
          onClick: () =>
            openModal(<WorkLogsForm />, "", COMMON_TEXT.FORM.WORK_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.WORK_LOGS,
        },
      ],
    },
  ];

  const statusButtonConfig: ButtonConfig[] = [
    {
      condition: () =>
        work?.externalCounterSetting !== "HANDWRITING" &&
        work?.trackingStatus === "WAITING",
      buttons: [
        {
          key: "start",
          color: "green.6",
          icon: <IconPlayerPlay />,
          onClick: () => handleStart(),
          text: COMMON_TEXT.STATUS.START,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "WORKING",
      buttons: [
        {
          key: "pause",
          color: "orange.6",
          icon: <IconPlayerPause />,
          onClick: () => handlePause(),
          text: COMMON_TEXT.STATUS.PAUSED,
        },
        {
          key: "end",
          color: "red",
          icon: <IconPower />,
          onClick: () => handleEnd(),
          text: COMMON_TEXT.STATUS.END,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "PAUSED",
      buttons: [
        {
          key: "resume",
          color: "green.6",
          icon: <IconPlayerPlay />,
          onClick: () => handleResume(), // ✅ 호출 명확히 처리
          text: COMMON_TEXT.STATUS.RESUME,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "DONE",
      buttons: [
        {
          key: "cancelEnd",
          color: "blue.6",
          icon: <IconCircleArrowUpLeft />,
          onClick: () => handleCancelEnd(),
          text: COMMON_TEXT.STATUS.CANCEL_END,
        },
      ],
    },
  ];

  if (process.env.REACT_APP_ADD_PALLET_LOGS) {
    logButtonConfigs[0].buttons.push({
      key: "palletLogs",
      color: "orange.6",
      icon: <IconStack />,
      onClick: () =>
        openModal(<PalletLogsForm />, "", COMMON_TEXT.FORM.PALLET_LOGS_TITLE),
      text: COMMON_TEXT.BUTTON.PALLET_LOGS,
    });
  }

  return (
    <Flex gap={"xs"} justify={"space-between"}>
      <Flex gap={"xs"}>
        {logButtonConfigs.map((config) =>
          config.buttons.map((button) => (
            <Header.ActionButton
              key={button.key}
              onClick={button.onClick}
              color={button.color}
              leftIcon={button.icon}
            >
              {button.text}
            </Header.ActionButton>
          ))
        )}
      </Flex>
      <Header.StatusButtonGroup buttonConfigs={statusButtonConfig} />
    </Flex>
  );
};
