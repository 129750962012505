import { BaseBox } from "@/components/atom/box/BaseBox";

interface BoxTemplateProps {
  children: React.ReactNode;
}

export const BoxTemplate = ({ children }: BoxTemplateProps) => {
  return (
    <BaseBox p={"xs"} bg={"#22262A"} w={"100%"} h={"100%"}>
      {children}
    </BaseBox>
  );
};
