import { CommonTextType } from "@/constants/type";
import i18n from "@/language/i18n";

const createProxy = (object: any, path: string[] = []): any => {
  return new Proxy(object, {
    get(target, prop) {
      const newPath = [...path, prop.toString()];
      if (typeof target[prop] === "object" && target[prop] !== null) {
        return createProxy(target[prop], newPath);
      }
      return i18n.t(newPath.join("."));
    },
  });
};

export const COMMON_TEXT: CommonTextType = createProxy({
  TITLE: {
    WORK_TIME_TRACK: "작업 시간 추적",
    LOCATION: "로케이션",
    PRODUCT_INVENTORY: "생산 가능재고",
    FROM_LOCATION: "원부자재 로케이션",
    TO_LOCATION: "재공품 입고 로케이션",
    TARGET_LOCATION: "생산 로케이션",
    OUTSOURCE: "외주",
    LOT_NUMBER: "로트 번호",
    CURRENT_LOT_PROGRESS: "현재 로트 진행도",
    CURRENT_LOT_DEFECT: "현재 로트 불량기록",
    CURRENT_DEFECT: "불량 기록",
    TEMPORARY_LOT_DEFECT: "임시 로트 불량기록",
    DEFECT_REPORT: "불량 보고",
    PRODUCTION_INPUT: "생산실적 입력",
    MAIN_TITLE: "생산 POP",
    TARGET_QUANTITY: "목표 생산량",
    PROGRESS: "진행률",
    PRODUCTION_QUANTITY: "생산 수량",
    TOTAL_QUANTITY: "양품 수량",
    DEFECT_QUANTITY: "불량 수량",
    TODO_QUANTITY: "생산 잔량",
    SETTING: "설정",
    WORK_CLOSE: "작업 종료",
    PROD_INPUT: "생산실적 입력 여부",
    OUT_OF_STOCK: "재고 부족 여부",
    INVENTORY_AVAILABLE: "재고 사용 여부 확인",
    INPUT_CANCEL: "투입 취소",
    TOTAL_DEFECT_QUANTITY: "총 불량수량",
    LIMIT_HIT: "작업 제한 횟수 도달",
    PALLET_SEPARATE: "파레트 분리",
    USER: "사용자",
    EQUIPMENT: "설비",
    PRINTER: "프린터",
    CANCEL_END: "작업 종료 취소",
    FLUSH: "재고 초기화",
  },

  LABEL: {
    DELIVERY_LOCATION_CODE: "출고 로케이션 코드",
    TARGET_LOCATION_CODE: "입고 로케이션 코드",
    INPUT_BARCODE_INFORM: "바코드 정보 입력",
    LOT_INFORM: "로트 정보",
    DEFECT_LOCATION: "불량 창고",
    INVENTORY: "재고",
    QUANTITY: "수량",
    TARGET_LOCATION: "이동 창고",
    ITEM: "품목",
    USER_ID: "사용자 ID",
    USER_NAME: "사용자 이름",
    EQUIPMENT: "설비",
    LOCATION: "로케이션",
    PRINTER: "프린터",
    PASSWORD: "비밀번호",
    CURRENT_WORK_STATUS: "현재 작업 상태",
    ITEM_CODE: "품목코드",
    ITEM_NAME: "품목명",
    SPEC: "규격",
    WIDTH: "가로",
    DEPTH: "세로",
    HEIGHT: "높이",
    DESCRIPTION: "비고",
    CURRENT_LOT_NUMBER: "현재 로트번호",
    ROUTING_CODE: "라우팅 코드",
    COUNTER_AND_AUTO_REPORT: "카운터 연동 + 자동 보고",
    HANDWRITING: "수기 입력 사용",
    COUNTER_AND_HANDWRITING: "연동 + 수기 입력 사용",
    TOTAL_QUANTITY: "양품 수량",
    LOT_NAME: "로트명",
  },

  BUTTON: {
    RAW_MATERIAL_INPUT: "원부자재 투입",
    RAW_MATERIAL_ALL_INPUT: "원부자재 일괄 투입",
    INPUT_LOGS: "투입 기록",
    RECORD_INSPECT: "초중종품 검사",
    DEFECT_RECYCLE: "불량 재활용",
    WORK_LOGS: "작업 로그",
    INSPECT_LOGS: "검사 기록",
    TRANSFER_ALL: "벌크 이동",
    OUTGOING_ALL: "벌크 출고",
    RESET_INVENTORY: "재고 초기화",
    PALLET_SEPARATE: "파레트 분리",
    DEFECT_REPORT: "불량 보고",
    PRODUCTION_SAVE: "생산실적 저장",
    LOGIN: "로그인",
    FLUSHING: "재고 초기화",
    BARCODE_PRINT: "바코드 출력",
    PALLET_LOGS: "파레트 분리 로그",
    PRINT: "출력",
    CANCEL: "취소",
    CANCELLED: "취소됨",
    USER_UPDATE: "사용자 설정",
    LOGOUT: "로그아웃",
  },

  COLUMN: {
    ITEM_CODE: "품목코드",
    ITEM_NAME: "품목명",
    ROUTING_CODE: "라우팅 코드",
    LOT_NAME: "로트명",
    LOT_ID: "로트ID",
    LOT_EXPIRATION_DATE: "로트유효기한",
    LOT_LOCATION: "로트위치",
    RELEASE_LOCATION: "출고로케이션",
    QUANTITY: "수량",
    INPUT_LOCATION: "입고로케이션",
    WORKER: "작업자",
    CREATED_AT: "일시",
    ACTION: "액션",
    LOG_NUM: "로그 번호",
    RELATED_LOG_NUM: "연관 로그 번호",
    TYPE: "타입",
    ORDER: "순서",
    SCHEDULE_SEQ: "작업 순서",
    EQUIPMENT_NAME: "설비명",
    LINE_NAME: "라인명",
    TARGET_QUANTITY: "계획 수량",
    SPEC: "규격",
    SCHEDULED_AT: "작업일",
    TRACKING_STATUS: "진행 상태",
    DESCRIPTION: "비고",
    INSPECT_SUMMARY: "검사 기준 요약",
    PASS_OR_NOT: "합격 여부",
    LOCATION: "위치",
    INSPECTION_AT: "측정일",
    FINAL_QUANTITY: "최종 재고",
    CLOSED_QUANTITY: "마감 재고",
    CLOSED_AT: "마감 일시",
    INCREASE_QUANTITY: "추가된 재고",
    DECREASE_QUANTITY: "감소된 재고",
    DELTA_QUANTITY: "재고 변화량",
    START_TIME: "시작 시간",
    END_TIME: "종료 시간",
    DOWNTIME_REASON: "비가동 사유",
    REMAIN_QUANTITY: "잔량",
    DEFECT_NAME: "불량명",
    DEFECT_QUANTITY: "불량수량",
    LOG_NUMBER: "로그번호",
    PRINT: "출력",
  },

  FORM: {
    INPUT_LOGS_TITLE: "투입 기록 확인",
    RECORD_INSPECT_TITLE: "검사 기록 확인",
    RECYCLE_LOGS_TITLE: "불량 재활용",
    WORK_LOGS_TITLE: "작업 로그 확인",
    INSPECT_LOGS_TITLE: "검사 기록 보고",
    RAW_MATERIAL_TITLE: "원부자재 투입",
    RAW_MATERIAL_ALL_TITLE: "원부자재 일괄 투입",
    RECYCLABLE_DEFECT_TITLE: "재활용 할 불량",
    RECYCLED_DEFECT_TITLE: "재활용 될 불량",
    OUTGOING_ALL_TITLE: "재고 벌크 출고",
    TRANSFER_ALL_TITLE: "재고 벌크 이동",
    PALLET_BARCODE_TITLE: "투입 기록 확인",
    PALLET_LOGS_TITLE: "파레트 분리 로그",
    CALENDAR: "날짜 선택",
    SETTING_INPUT: "실적 입력 방식 설정",
    RAW_MATERIAL_ALL_SUBTITLE: "바코드 LOT 입력",
    WORK_PAUSE: "작업 일시정지",
    DOWNTIME_REASONS: "비가동 사유",
  },

  INSPECT: {
    PASS: "합격",
    FAIL: "불합격",
    NO_RESPONSE: "응답없음",
    POINT: "포인트",
    CREATOR: "검사자",
    CREATED_AT: "검사날짜",
    INITIAL_PRODUCT: "초물",
    INTERMEDIATE_PRODUCT: "종물",
    FINAL_PRODUCT: "종물",
    INSPECTING: "검사중",
  },

  NOTIFICATION: {
    INSPECT_SUCCESS: "초중종품 검사가 성공적으로 되었습니다.",
    WORK_CANCEL: "해당 작업을 취소하시겠습니까?",
    RAW_MATERIAL_INPUT_SUCCESS: "원/부자재가 성공적으로 투입되었습니다.",
    RAW_MATERIAL_INPUT_FAIL: "원/부자재 투입에 실패하였습니다.",
    LOT_NOT_FOUND: "바코드에 해당하는 로트가 존재하지 않습니다.",
    DUPLICATE_LOT: "이미 추가된 LOT입니다.",
    TRANSFORM: "변환되었습니다",
    TRANSFORM_FAIL: "변환에 실패하였습니다.",
    NO_LOCATION: "사용자의 로케이션 또는 출고 로케이션을 선택해주세요.",
    OUTGOING_ALL_SUCCESS: "벌크 이동이 성공적으로 되었습니다.",
    OUTGOING_ALL_FAIL: "벌크 이동이 실패하였습니다 ",
    TRANSFER_ALL_SUCCESS: "벌크 출고가 성공적으로 되었습니다.",
    TRANSFER_ALL_FAIL: "벌크 출고가 실패하였습니다 ",
    USER_MODIFY: "사용자 정보가 수정되었습니다.",
    LOGIN_FAIL: "로그인에 실패하였습니다.",
    START_SUCCESS: "작업을 시작하였습니다.",
    START_FAIL: "작업 시작을 실패하였습니다.",
    PAUSE_SUCCESS: "작업을 일시정지하였습니다.",
    PAUSE_FAIL: "일시정지를 실패하였습니다.",
    END_SUCCESS: "작업을 종료하였습니다.",
    END_FAIL: "작업 종료를 실패하였습니다.",
    RESUME_SUCCESS: "작업을 재개하였습니다.",
    RESUME_FAIL: "작업 재개를 실패하였습니다.",
    PERFORMANCE_SUCCESS: "실적 저장에 성공하였습니다.",
    PERFORMANCE_FAIL: "실적 저장에 실패하였습니다.",
    DEFECT_SUCCESS: "불량 저장에 성공하였습니다.",
    DEFECT_FAIL: "불량 저장에 실패하였습니다.",
    SETTING_SUCCESS: "설정 저장에 성공하였습니다.",
    SETTING_FAIL: "설정 저장에 실패하였습니다.",
    FLUSH_SUCCESS: "초기화 되었습니다.",
    FLUSH_FAIL: "초기화에 실패 했습니다.",
    CORRECT_SUCCESS: "재고가 보정되었습니다.",
    CORRECT_FAIL: "재고 보정에 실패하였습니다.",
    CANCEL_SUCCESS: "취소되었습니다.",
    CANCEL_FAIL: "취소에 실패하였습니다.",
    PALLET_SEPARATE_SUCCESS: "파레트 분리가 성공적으로 되었습니다.",
    PALLET_SEPARATE_FAIL: "파레트 분리가 실패하였습니다.",
    BARCODE_PRINT_FAIL:
      "바코드 출력에 실패하였습니다. \n 프린터 지정 또는 프린터 상태를 확인해주세요.",
    CANCEL_END_SUCCESS: "작업 종료 취소가 성공적으로 되었습니다.",
    CANCEL_END_FAIL: "작업 종료 취소가 실패하였습니다.",
  },

  DATA_EMPTY: {
    NO_DATA: "데이터가 없습니다.",
    NO_LOT_INFORM: "바코드 스캔 또는 로트정보를 입력하여 LOT를 등록하세요.",
    NO_INSPECT: "검사 기준이 없습니다.",
    NO_WORK:
      "선택된 작업지시가 없습니다. \n 작업지시서에서 먼저 선택하여 주시기 바랍니다.",
    NO_EQUIPMENT: "지정된 설비가 없습니다.",
    NO_PRINTER: "지정된 프린터가 없습니다.",
    NO_LOCATION: "지정된 로케이션이 없습니다.",
  },

  VALIDATE: {
    LOCATION: "창고를 선택해주세요",
    INVENTORY: "재고를 선택해주세요",
    QUANTITY: "수량을 입력해주세요",
    ITEM: "품목을 선택해주세요",
  },

  STATUS: {
    START: "작업시작",
    END: "작업종료",
    WAITING: "작업대기",
    PAUSED: "일시정지",
    WORKING: "작업중",
    DONE: "작업완료",
    CANCEL_END: "작업종료 취소",
  },

  PLACEHOLDER: {
    ITEMS: "품목을 선택해주세요",
    SPEC: "규격을 선택해주세요",
  },

  LANGUAGE: {
    KOREAN: "한국어",
    ENGLISH: "영어",
  },

  DESCRIPTION: {
    COUNTER_AND_AUTO_REPORT_DESC:
      "실적 수량을 수기로 입력할 수 없습니다. 카운터 값이 변경되었을 때마다 자동 보고됩니다.",
    HANDWRITING_DESC:
      "실적 수량을 수기로 입력할 수 있습니다. 임시저장이 필요합니다.",
    COUNTER_AND_HANDWRITING_DESC:
      "실적 수량이 카운터를 기준으로 입력되지만, 임시저장이 필요합니다.",
    PREV_ORDER_CLOSURE_DESC:
      "이전 작업 지시서가 종료되지 않았습니다. 종료하시겠습니까?",
    PROD_RECORD_ENTERED_DESC:
      "투입과 투입 사이에 생산 실적을 입력하지 않았습니다. 생산실적을 입력 없이 진행하시겠습니까?",
    INVENTORY_LACK_DESC:
      "재고가 부족합니다. 원/부자재 투입 후 진행하시겠습니까?",
    PREV_MATERIAL_USED_DESC: "이전 자재를 사용 완료 하였습니까?",

    WORK_CLOSURE_DESC: "작업을 종료하시겠습니까?",

    END_QUANTITY_MISS_MATCH:
      "작업지시 수량보다 생산량이 적습니다. 작업을 종료하시겠습니까?",
    CANCEL_INPUT_DESC: "투입을 취소하시겠습니까?",

    LIMIT_HIT_DESC:
      "설비별 생산량이 제한에 도달하였습니다. 실적을 저장하시겠습니다.",
    PALLET_SPLIT_DESC: "파레트를 분리하시겠습니까?",

    PALLET_CURRENT_SPLIT_DESC:
      "현재 로트에 대해 생산된 실적이 없습니다. \n 파레트 분리를 진행하시겠습니까?",
    INPUT_LOGS_DESC: "원/부자재 투입 기록을 확인합니다.",

    PALLET_LOGS_DESC: "파레트 분리 로그를 확인합니다.",

    WORK_LOGS_CANCEL_DESC:
      "취소 버튼을 클릭하여 특정 투입을 취소할 수 있습니다.",
    RAW_MATERIAL_ALL_DESC:
      "바코드를 스캔하여 자동으로 데이터를 입력합니다. \n 일괄 투입 버튼을 클릭하여 원부자재를 일괄 투입합니다.",
    TRANSFER_ALL_DESC:
      "바코드를 스캔하여 자동으로 데이터를 입력합니다. \n 벌크 이동 버튼을 클릭하여 이동을 완료합니다.",
    OUTGOING_ALL_DESC:
      "바코드를 스캔하여 자동으로 데이터를 입력합니다. \n 등록 버튼을 클릭하여 일괄 출고를 완료합니다.",
    CANCEL_END_DESC: "작업 종료를 취소하시겠습니까?",
  },

  PAGE: {
    WORKS: "작업지시",
    POP: "POP",
    INVENTORY: "재고",
    INSPECT_LOG: "검사기록",
  },

  COMMON: {
    SUCCESS: "성공하였습니다.",
    INFORMATION: "정보가 없습니다.",
    FAIL: "실패하였습니다.",
  },
});
