import { whoami } from "@/api/whoami/useAuthWhoamiGetQuery";
import useFetcher from "@/hooks/useFetcher";

interface useUserFetchProps {
  token: string;
  enabled?: boolean;
}

export const useUserFetch = ({ token, enabled }: useUserFetchProps) => {
  const { queryKey, queryFn } = whoami.get({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    {
      enabled: enabled,
    }
  );

  return { data, Fetcher };
};
