import { mutateSplit } from "@/api/split/useSplitQuery";
import { WORK_KEY } from "@/api/work/useWorkQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useWorkDataStore } from "@/store/work.store";
import { customNotification } from "@/utils/notificationShow";
import { ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const usePalletHandler = () => {
  const queryClient = useQueryClient();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { mutate: splitMutate } = useMutation(
    (
      params: ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest
    ) => mutateSplit.split(params).mutationFn(undefined),

    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.PALLET_SEPARATE_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        if (error?.response?.data.message === undefined) return;
        customNotification.error({
          message:
            error?.response?.data.message ??
            COMMON_TEXT.NOTIFICATION.PALLET_SEPARATE_FAIL,
        });
      },
    }
  );

  const onSubmit = () => {
    splitMutate({
      productionPlanId: work?.productionPlanId as number,
      productionPlansProductionPlanIdSplitProductLotPostRequest: {
        quantity: work?.currentLotSummary?.totalQuantity as string,
      },
    });
  };

  return { onSubmit };
};
