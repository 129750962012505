import { StatusMultipleChipGroup } from "@/components/molecule/statusMultipleChipGroup/StatusMultipleChipGroup";
import { COMMON_TEXT } from "@/constants/text";
import { useWorksHeaderFetch } from "@/fetch/page/useWorksHeaderFetch";
import { Flex, FlexProps, Select } from "@mantine/core";

const WorksAction = Object.assign(
  {},
  {
    Select: Select,
    StatusMultipleChipGroup: StatusMultipleChipGroup,
  }
);

interface WorksActionHeaderProps extends FlexProps {
  equipmentCode?: string;
  item: string;
  spec: string;
  value: string[];
  setItem: (item: string) => void;
  setSpec: (spec: string) => void;
  setValue: (value: string[]) => void;
}

export const WorksActionHeader = ({
  item,
  spec,
  value,
  equipmentCode,
  setItem,
  setSpec,
  setValue,
  ...etcProps
}: WorksActionHeaderProps) => {
  const { data } = useWorksHeaderFetch({ equipmentCode: equipmentCode });

  const itemSelect =
    data?.data
      ?.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.itemName === item.itemName)
      )
      ?.map((item) => ({
        value: item.itemName ?? "",
        label: item.itemName ?? "",
      })) ?? [];

  const itemSpecs =
    data && Array.isArray(data.data)
      ? data.data
          ?.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.spec === item.spec) && item.spec
          )
          ?.map((item) => {
            return {
              value: item.spec ?? "",
              label: item.spec ?? "-",
            };
          })
      : [];

  return (
    <Flex {...etcProps} w={"100%"} direction={"column"}>
      <Flex justify={"flex-end"} mb={"xs"}>
        <WorksAction.StatusMultipleChipGroup
          value={value}
          onChange={setValue}
          chipStatus={["DONE", "WORKING", "PAUSED", "WAITING"]}
        />
      </Flex>
      <Flex gap={"xs"}>
        <WorksAction.Select
          size="sm"
          placeholder={COMMON_TEXT.PLACEHOLDER.ITEMS}
          onChange={(code) => setItem(code ?? "")}
          data={itemSelect ?? []}
          value={item}
          clearable
          searchable
        />
        <WorksAction.Select
          size="sm"
          placeholder={COMMON_TEXT.PLACEHOLDER.SPEC}
          data={itemSpecs ?? []}
          value={spec}
          onChange={(value) => setSpec(value ?? "")}
          clearable
          searchable
        />
      </Flex>
    </Flex>
  );
};
