import { InventoriesGet200Response, ItemsGet200ResponseRowsInnerLotsInner, ProductionPlansGet200ResponseRowsInnerWorksInner, RoutingOutsourcesGet200ResponseRowsInner, WorkLogsGet200Response, WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export type WorkDataTypes = {
    work?: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
    lots?: ItemsGet200ResponseRowsInnerLotsInner | undefined
    inventories?: InventoriesGet200Response | undefined
    inputLogs?: WorkLogsGet200Response | undefined
    inputLogsAll?: WorkLogsGet200ResponseRowsInner[] | undefined
    outsource?: RoutingOutsourcesGet200ResponseRowsInner | undefined
    logsAll?: WorkLogsGet200Response | undefined
}

export type WorkState = {
    workData: WorkDataTypes
}

export interface WorkActions {
    setWorkData: (workData: Partial<WorkDataTypes>) => void
}

export interface WorkStore extends WorkState, WorkActions { }

export const useWorkDataStore = create<WorkStore>()(
    immer((set) => ({
        workData: {
            work: undefined,
            lots: undefined,
            inventories: undefined,
            inputLogs: undefined,
            inputLogsAll: undefined,
            outsource: undefined,
            logsAll: undefined
        },
        setWorkData: (workData) => {
            set((state) => {
                state.workData = { ...state.workData, ...workData }
            })
        },
    }))
)
