import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { COMMON_TEXT } from "@/constants/text";
import { TextInputProps } from "@mantine/core";

interface BarcodeInputProps extends TextInputProps {
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const BarcodeInput = ({
  handleBlur,
  ...etcParams
}: BarcodeInputProps) => {
  return (
    <FormTextInput
      auto-focus
      data-autofocus
      label={COMMON_TEXT.LABEL.INPUT_BARCODE_INFORM}
      aria-label="바코드정보"
      placeholder="Enter Input And Take a Barcode "
      {...etcParams}
      onBlur={(e) => {
        etcParams.onBlur && etcParams.onBlur(e);
        handleBlur && handleBlur(e);
      }}
    />
  );
};
