import { Point } from "@/components/atom/point/Point";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { InspectRadioCategory } from "@/components/molecule/inspectRadioCategory/InspectRadioCategory";
import { InspectSegmentedCategory } from "@/components/molecule/inspectSegmentedCategory/InspectSegmentedCategory";
import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useInspectLogsFormFetch } from "@/fetch/form/useInspectLogsFormFetch";
import { useSpecificationFormFetch } from "@/fetch/form/useSpecificationFormFetch";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { useInspectHandler } from "@/hooks/handler/useInspectHandler";
import { Pagination, Text } from "@mantine/core";
import {
  InspectionsGet200ResponseRowsInnerRecordInnerInner,
  SpecificationsGet200ResponseRowsInnerCriteriaInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface InspectionSpecificationsProps
  extends InspectionsGet200ResponseRowsInnerRecordInnerInner {
  createdAt: string | undefined;
  isSaved: boolean;
  creatorUserId: string | undefined;
}

const Inspect = Object.assign(
  {},
  {
    Template: FormTemplate,
    SubTitle: FormSubTitle,
    Point: Point,
    Table: MantineSelectableTable,
    SegmentedCategory: InspectSegmentedCategory,
    RadioCategory: InspectRadioCategory,
    Pagination: Pagination,
  }
);

export const InspectForm = () => {
  const { closeModal } = useModal();
  const [records, setRecords] = useState<
    Array<Array<InspectionSpecificationsProps>>
  >([]);
  const { data: specification } = useSpecificationFormFetch();
  const { data: inspection } = useInspectLogsFormFetch();
  const { onSubmit } = useInspectHandler();

  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const name = userData?.name;
  const id = userData?.id;

  const roles = userData?.permissions?.allow
    ?.map((role: string) => {
      if (role.includes("update")) return "UPDATE";
      else if (role.includes("create")) return "CREATE";
      else if (role.includes("delete")) return "DELETE";
      else if (role.includes("read")) return "READ";
      else return "READ";
    })
    .join(",");

  const specificationData = specification?.data?.rows?.[0];
  const inspectionsData = inspection?.data?.rows?.[0];
  const inspectionLength = inspection?.data?.rows?.length;

  const criteriaMatchingInspectionRecords = specificationData?.criteria.filter(
    (criteria, index) => {
      return index < (inspectionsData?.record?.length as number) && criteria;
    }
  );

  const specificationCriteria = criteriaMatchingInspectionRecords?.length
    ? criteriaMatchingInspectionRecords
    : specificationData?.criteria;

  useEffect(() => {
    if (inspectionLength) {
      setRecords(inspectionsData?.record as InspectionSpecificationsProps[][]);
    } else {
      const newRecords =
        specificationData?.criteria?.map(
          (criteria: SpecificationsGet200ResponseRowsInnerCriteriaInner) =>
            Array.from({ length: criteria.sampleNum ?? 0 }).map((_, i) => ({
              value: undefined,
              isPassed: undefined,
              createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              creatorUserName: name,
              isSaved: false,
              creatorUserId: String(id),
            }))
        ) ?? [];

      setRecords(newRecords);
    }
  }, [inspection, specificationData?.criteria]);

  const handleOnSubmit = () => {
    onSubmit({
      specification: specificationData,
      inspection: inspectionsData,
      records: records,
    });
  };

  const handleOnChange = (
    value: string,
    pointIndex: number,
    categoryIndex: number
  ) => {
    setRecords((prevRecords) => {
      const newRecords = [...prevRecords];
      newRecords[pointIndex][categoryIndex].creatorUserName = name;
      newRecords[pointIndex][categoryIndex].createdAt = dayjs().format(
        "YYYY-MM-DD HH:mm:ss"
      );
      newRecords[pointIndex][categoryIndex].value =
        value === COMMON_TEXT.INSPECT.NO_RESPONSE
          ? undefined
          : value === COMMON_TEXT.INSPECT.PASS;
      newRecords[pointIndex][categoryIndex].isPassed =
        value === COMMON_TEXT.INSPECT.NO_RESPONSE
          ? undefined
          : value === COMMON_TEXT.INSPECT.PASS;
      return newRecords;
    });
  };

  if (specification?.data.rows?.length === 0)
    return <Text>{COMMON_TEXT.DATA_EMPTY.NO_INSPECT}</Text>;

  return (
    <Inspect.Template
      onCancel={() => closeModal({})}
      onSubmit={handleOnSubmit}
      isButtonDisabled={false}
    >
      {specificationCriteria?.map((criteria, index) => (
        <>
          <Inspect.Point
            size={"xl"}
            pointNum={index + 1}
            name={criteria.name}
          />
          {Array.from({ length: criteria.sampleNum ?? 0 }).map((_, i) => (
            <Inspect.RadioCategory
              key={i}
              disabled={
                roles !== "UPDATE" &&
                inspectionsData?.record?.[index]?.[i]?.isSaved
              }
              checkNum={i}
              value={
                records?.[index]?.[i]?.value === undefined
                  ? COMMON_TEXT.INSPECT.NO_RESPONSE
                  : records?.[index]?.[i]?.isPassed
                  ? COMMON_TEXT.INSPECT.PASS
                  : COMMON_TEXT.INSPECT.FAIL
              }
              data={[
                {
                  value: COMMON_TEXT.INSPECT.NO_RESPONSE,
                  label: COMMON_TEXT.INSPECT.NO_RESPONSE,
                },
                {
                  value: COMMON_TEXT.INSPECT.PASS,
                  label: COMMON_TEXT.INSPECT.PASS,
                },
                {
                  value: COMMON_TEXT.INSPECT.FAIL,
                  label: COMMON_TEXT.INSPECT.FAIL,
                },
              ]}
              onChange={(value) => handleOnChange(value, index, i)}
              createdAt={records?.[index]?.[i]?.createdAt}
              creator={records?.[index]?.[i]?.creatorUserName}
            />
          ))}
        </>
      ))}
    </Inspect.Template>
  );
};
