import { workTrackingStatus } from "@/utils/tranferWorkStatus";
import { Card, Group, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { WorksItemGet200ResponseRowsInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";
import React from "react";

interface ProductionStatusCardProps {
  status: WorksItemGet200ResponseRowsInnerTrackingStatusEnum | undefined;
  plannedQuantity: string | undefined;
  equipmentName: string | undefined;
  itemName: string | undefined;
  workDate: string | undefined;
  specification: string | undefined;
  onClick?: () => void;
}

// 상태별 스타일 정의
const statusStyles = {
  WAITING: {
    borderColor: "#939393",
    textColor: "#939393",
  },
  PAUSED: {
    borderColor: "#FFD93D",
    textColor: "#FFD93D",
  },
  WORKING: {
    borderColor: "#4CAF50",
    textColor: "#4CAF50",
  },
  DONE: {
    borderColor: "#50cce2",
    textColor: "#50cce2",
  },
};

const ProductionStatusCard: React.FC<ProductionStatusCardProps> = ({
  status,
  plannedQuantity,
  equipmentName,
  itemName,
  workDate,
  specification,
  onClick,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  if (!status) return null;
  const { borderColor, textColor } = statusStyles[status];
  const statusText = workTrackingStatus[status];

  return (
    <Card
      onClick={onClick}
      shadow="sm"
      padding="sm"
      radius="md"
      style={{
        backgroundColor: "#2b2b2b",
        color: "white",
        width: "100%",
        maxWidth: isSmallScreen ? "13.2rem" : "16.1rem",
        minHeight: "14rem",
        border: `2px solid ${borderColor}`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onTouchStart={(e) => {
        e.currentTarget.style.transform = "scale(0.97)"; // 눌린 효과
        e.currentTarget.style.backgroundColor = "#3c3c3c"; // 배경색 변화
      }}
      onTouchEnd={(e) => {
        e.currentTarget.style.transform = "scale(1)"; // 원상복귀
        e.currentTarget.style.backgroundColor = "#2b2b2b"; // 원래 배경색
      }}
    >
      <Stack spacing={"xs"}>
        {/* 상태 텍스트 */}
        <Text size={"lg"} fw={500} c={textColor}>
          {statusText}
        </Text>

        {/* 각 정보 항목 */}
        <Stack spacing={"xs"}>
          {[
            { title: "계획수량", value: plannedQuantity },
            { title: "라인명(설비명)", value: equipmentName },
            { title: "품목명", value: itemName },
            { title: "작업일", value: workDate },
            { title: "규격", value: specification },
          ].map(({ title, value }, index) => (
            <Group
              key={index}
              position="apart"
              style={{
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "flex-start" : "center",
                gap: isSmallScreen ? "0.1px" : "10px",
              }}
            >
              <Text size={"xs"} c={"#a0a0a0"} fw={600}>
                {title}
              </Text>
              <Text
                size={"sm"}
                fw={600}
                style={{
                  wordWrap: "break-word",
                  textAlign: isSmallScreen ? "left" : "right",
                  marginTop: isSmallScreen ? "2px" : "0px",
                }}
              >
                {value}
              </Text>
            </Group>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProductionStatusCard;
