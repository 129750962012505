import { inspects } from "@/api/inspect/useInspectQuery";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import useFetcher from "@/hooks/useFetcher";
import { usePaginationStore } from "@/store/pagenation.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";

const { WAITING, PAUSED, WORKING, DONE } =
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;

export const useInspectRecordsFetch = () => {
  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));

  const userData = user?.data;

  const query: any = {
    $and: [],
  };

  if (userData?.equipmentCode) {
    query.$and.push({ equipmentCode: { $eq: userData.equipmentCode } });
  }

  const { queryKey, queryFn } = inspects.get({
    sort: "-createdAt, -inspectionAt",
    query: query,
    populate: ["lot"],
    page: currentPage,
  });

  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
