import ExpandableAccordion from "@/components/atom/accordion/expandable/ExpandableAccordion";
import { useWorkDataStore } from "@/store/work.store";

import { WorkText } from "@/components/atom/text/work/WorkText";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { COMMON_TEXT } from "@/constants/text";
import { Flex } from "@mantine/core";

const Location = Object.assign(
  {},
  {
    Title: WorkTitle,
    Text: WorkText,
    Accordion: ExpandableAccordion,
  }
);

export const LocationInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  return (
    <Flex w={"100%"} direction={"column"} gap={"xl"}>
      <Flex justify={"space-between"} direction={"column"}>
        <Location.Title order={5}>
          {COMMON_TEXT.TITLE.FROM_LOCATION}
        </Location.Title>
        <Flex wrap={"wrap"}>
          <Location.Text c={"white"}>
            [
            {work?.currentRoutingOutsourceData?.fromLocationCode ??
              work?.locationSetting?.fromLocation?.code}
            ]
          </Location.Text>
          <Location.Text c={"white"}>
            {work?.currentRoutingOutsourceData?.fromLocationName ??
              work?.locationSetting?.fromLocation?.name}
          </Location.Text>
        </Flex>
      </Flex>
      <Flex justify={"space-between"} direction={"column"}>
        <Location.Title order={5}>
          {COMMON_TEXT.TITLE.TO_LOCATION}
        </Location.Title>
        <Flex wrap={"wrap"}>
          <Location.Text c={"white"}>
            [
            {work?.currentRoutingOutsourceData?.toLocationCode ??
              work?.locationSetting?.toLocation?.code}
            ]
          </Location.Text>
          <Location.Text c={"white"}>
            {work?.currentRoutingOutsourceData?.toLocationName ??
              work?.locationSetting?.toLocation?.name}
          </Location.Text>
        </Flex>
      </Flex>
      <Flex justify={"space-between"} direction={"column"}>
        <Location.Title order={5}>
          {COMMON_TEXT.TITLE.TARGET_LOCATION}
        </Location.Title>
        <Flex wrap={"wrap"}>
          <Location.Text c={"white"}>
            [{work?.locationSetting?.targetLocation?.code ?? "-"}]
          </Location.Text>
          <Location.Text c={"white"}>
            {work?.locationSetting?.targetLocation?.name ?? "-"}
          </Location.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
