import { BaseButton } from "@/components/atom/button/BaseButton";
import { DecrementButton } from "@/components/atom/button/decrease/DecrementButton";
import { IncrementButton } from "@/components/atom/button/increase/IncrementButton";
import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { BaseTitle } from "@/components/atom/title/BaseTitle";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import { DefectAutoComplete } from "@/components/organism/autoComplete/defect/DefectAutoComplete";
import { DefectObject } from "@/components/organism/work/original/WorkCurrentDefectInform";
import { DefectObjectProps } from "@/components/organism/work/refactor/TempDefectInformBox";

import { BoxTemplate } from "@/components/template/box/BoxTemplate";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useWorkDataStore } from "@/store/work.store";
import { plusBigNumber } from "@/utils/calculate";
import { customNotification } from "@/utils/notificationShow";
import { Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconTrashOff } from "@tabler/icons-react";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const DefectInform = Object.assign(
  {},
  {
    Template: BoxTemplate,
    Button: BaseButton,
    Title: BaseTitle,
    Input: FormNumberInput,
    IncrementButton: IncrementButton,
    DecrementButton: DecrementButton,
    AutoComplete: DefectAutoComplete,
    Table: MantineDataTable,
  }
);

export const DefectInformBox = () => {
  const { tempWork, setTempWork } = useLocalStorage();
  const [defectCode, setDefectCode] = useState<string | null>("");

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const { handleOnDefect } = usePerformanceHandler();
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      defects: {} as any,
    },
  });

  // 임시 불량 저장
  const handleTempDefectSave = (data: DefectObjectProps) => {
    setTempWork({
      ...tempWork,
      defects: {
        ...tempWork?.defects,
        [data.defectCode as string]: {
          ...tempWork?.defects?.[data.defectCode || ""],
          defectName: data?.defectName,
          defectQuantity: plusBigNumber(
            tempWork?.defects?.[data.defectCode || ""]?.defectQuantity ?? 0,
            data?.defectQuantity
          ),
        },
      },
    });
  };

  const handleDefectSave = (data: DefectObjectProps) => {
    const isDefectQuantityGreaterThanEnd = BigNumber(
      data.defectQuantity
    ).isGreaterThan(work?.summary?.end as string);
    const isDefectQuantityGreaterThanEndDetailMessage = t(
      "불량 수량이 생산수량보다 많습니다."
    );

    if (isDefectQuantityGreaterThanEnd) {
      return customNotification.error({
        message: isDefectQuantityGreaterThanEndDetailMessage,
      });
    }

    handleOnDefect({
      defectCode: data.defectCode as string,
      quantity: data.defectQuantity,
    });
  };

  const handleDefectReport = () => {
    customFunctions.ADD_TEMP_FUNCTION_ENABLED
      ? handleTempDefectSave({
          ...form.values.defects[defectCode as string],
          defectCode: defectCode as string,
        })
      : handleDefectSave({
          ...form.values.defects[defectCode as string],
          defectCode: defectCode as string,
        });
    setDefectCode(null);
  };

  const handleDefectAutoComplete = (defectCode: string | null) => {
    if (defectCode !== null) {
      form.setValues({
        defects: {
          ...form.values.defects,
          [defectCode as string]: {
            ...form.values.defects?.[defectCode || ""],
            defectName: (work?.summary?.defect as DefectObject)?.[
              defectCode as string
            ]?.defectName,
            defectQuantity: "0",
          },
        },
      });
    }
    setDefectCode(defectCode);
  };

  return (
    <DefectInform.Template>
      <Flex display="flex" gap="xl">
        <Flex display="flex" direction="column" gap="md" w={"100%"}>
          <DefectInform.AutoComplete
            defectOperationCode={work?.routingData?.operationCode}
            value={defectCode}
            onChange={handleDefectAutoComplete}
          />
          <Flex
            display="flex"
            gap="xs"
            align="center"
            justify={"space-between"}
          >
            <DefectInform.Input
              w={"100%"}
              disabled={!defectCode}
              {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
              value={Number(
                form.values.defects?.[defectCode || ""]?.defectQuantity ?? 0
              )}
            />
            <DefectInform.Button
              onClick={handleDefectReport}
              bg="red.6"
              size="sm"
              rightIcon={<IconTrashOff />}
            >
              {COMMON_TEXT.BUTTON.DEFECT_REPORT}
            </DefectInform.Button>
          </Flex>
          <Flex display="flex" justify="space-between">
            <Flex display="flex" gap="xs">
              <DefectInform.DecrementButton
                disabled={!defectCode}
                size="sm"
                num="5"
                {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
              />
              <DefectInform.DecrementButton
                disabled={!defectCode}
                size="sm"
                num="1"
                {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
              />
            </Flex>
            <Flex display="flex" gap="xs">
              <DefectInform.IncrementButton
                disabled={!defectCode}
                size="sm"
                num="1"
                {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
              />
              <DefectInform.IncrementButton
                disabled={!defectCode}
                size="sm"
                num="5"
                {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </DefectInform.Template>
  );
};
