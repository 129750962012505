import { MasterInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { AxiosRequestConfig } from "axios";

export const WHO_AM_I_KEY = "whoami";

export const whoami = createQueryKeys(WHO_AM_I_KEY, {
  get: (params: AxiosRequestConfig) => {
    return {
      queryKey: [params],
      queryFn: () => MasterInstance.authWhoamiGet(params),
    };
  },
});
