import { Progress, ProgressProps } from "@mantine/core";
import React from "react";

interface ProgressBarProps
  extends ProgressProps,
    React.RefAttributes<HTMLDivElement> {}

const StatusProgressBar = (props: ProgressBarProps) => {
  const { value } = props;

  const progressColor = () => {
    if (value && value >= 70) return "#009000";
    return `#0026ff`;
  };

  return (
    <Progress
      animate
      color={progressColor()}
      styles={{
        label: {
          fontSize: "0.75rem",
        },
        bar: {
          maxWidth: "100%",
        },
        root: {
          height: "1.5rem",
        },
      }}
      value={value}
      bg={"gray.5"}
      {...props}
    />
  );
};

export default StatusProgressBar;
