import { WorkHeader } from "@/components/organism/header/refactor/workHeader/WorkHeader";
import { DefectInformTabs } from "@/components/organism/work/refactor/DefectInformTabs";
import { LogsInformTabs } from "@/components/organism/work/refactor/LogsInformTabs";
import { ProgressInformBox } from "@/components/organism/work/refactor/ProgressInformBox";
import { StatusInformBox } from "@/components/organism/work/refactor/StatusInformBox";
import { WarningPage } from "@/components/page/refactor/waningPage";
import PageTemplate from "@/components/template/page/PageTemplate";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useWorkFetch } from "@/fetch/page/work/useWorkFetch";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import useSocket from "@/hooks/useSocket.hook";
import { useEquipmentStore } from "@/store/equipment.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";

const Work = Object.assign(
  {},
  {
    Template: PageTemplate,
    Header: WorkHeader,
  }
);

export const WorkPage = () => {
  useSocket();
  const { workId } = useLocalStorage();
  const { handleLimitCounter } = usePerformanceHandler();
  const { data, Fetcher } = useWorkFetch();
  const workData = data?.data;
  const { name, code } = workData?.equipment ?? {};
  const { setEquipment, equipment } = useEquipmentStore((state) => ({
    setEquipment: state.setEquipment,
    equipment: state.Equipment,
  }));
  const { setWorkData } = useWorkDataStore((state) => ({
    setWorkData: state.setWorkData,
  }));
  const { getData } = useSocketStore((state) => ({ getData: state.getData }));

  const counterData = getData(equipment?.code as string);

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (!code || !name) return;
    setEquipment({
      name: name,
      code: code,
    });
    setWorkData({ work: workData });
  }, [workData]);

  useEffect(() => {
    const isLimitHit =
      counterData?.isLimitHit &&
      workData?.externalCounterSetting === "COUNTER_AND_HANDWRITING";
    if (isLimitHit) {
      handleLimitCounter();
    }
  }, [counterData?.isLimitHit, workData?.externalCounterSetting]);

  if (workId === "undefined" || !workId) return <WarningPage />;

  return (
    <Work.Template>
      {/* <Fetcher> */}
      <Flex direction={"column"} gap={"xs"} h={"100%"}>
        <Flex
          gap={"xs"}
          w={"100%"}
          direction={isSmallScreen ? "column" : "row"}
        >
          <div
            style={{
              width: isSmallScreen ? "100%" : "25%",
            }}
          >
            <StatusInformBox />
          </div>
          <div style={{ width: isSmallScreen ? "100%" : "75%" }}>
            <ProgressInformBox />
          </div>
        </Flex>
        <Flex
          gap={"xs"}
          w={"100%"}
          h={"100%"}
          direction={isSmallScreen ? "column" : "row"}
        >
          <LogsInformTabs />
          <DefectInformTabs />
        </Flex>
      </Flex>
      {/* </Fetcher> */}
    </Work.Template>
  );
};
