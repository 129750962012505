/**
 * 이 파일은 특정 업체의 기능을 활성화/비활성화하는 데 사용됩니다.
 *
 * 설명:
 * 이 파일에는 다른 업체에 대한 특정 기능의 가용성을 제어하는 데 사용할 수 있는 변수가 포함되어 있습니다.
 * 이러한 변수의 값을 수정함으로써 특정 업체에 대한 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 다른 업체의 요구 사항에 따라 응용 프로그램의 동작을 사용자 정의할 수 있는 유연성을 제공합니다.
 *
 * 사용법:
 * 이 파일을 사용하려면 여기에서 정의된 변수를 코드베이스의 다른 부분으로 가져올 수 있습니다.
 * 이러한 변수를 참조함으로써 업체에 따라 조건부로 특정 기능을 활성화하거나 비활성화할 수 있습니다.
 * 이를 통해 단일 코드베이스를 다른 클라이언트나 조직에 맞게 사용자 정의할 수 있습니다.
 *
 * 참고:
 * 이러한 변수의 값이 요구 사항에 맞게 제대로 관리되고 업데이트되도록 주의해야 합니다.
 * 코드에 하드코딩된 값을 피하고 대신 이러한 변수에 의존하여 구성 가능성을 확보하는 것이 중요합니다.
 */

export const customFunctions = {
  //플러싱 여부 ADD_FLUSHING : 재고 초기화
  ADD_FLUSHING: process.env.REACT_APP_ADD_FLUSHING === "true" || false,

  //플러싱 버튼 추가 ADD_FLUSHING_BUTTON : 재고 초기화 버튼 추가
  ADD_FLUSHING_BUTTON:
    process.env.REACT_APP_ADD_FLUSHING_BUTTON === "true" || false,

  //[스피드랙] ADD_WARN_INSUFFICIENCY: 생산실적 입력시 재고가 부족에 관련한 경고성 메세지를 백에서 체크하여 보여주는 기능 활성화 여부
  ADD_WARN_INSUFFICIENCY:
    process.env.REACT_APP_ADD_WARN_INSUFFICIENCY === "true" || false,
  //[스피드랙] ADD_ACCUMULATION_QUANTITY: 실적입력시 ACCUMULATION를 사용하는 기능 활성화 여부
  ADD_ACCUMULATION_QUANTITY:
    process.env.REACT_APP_ADD_ACCUMULATION_QUANTITY === "true" || false,

  //[스피드랙] ADD_TEMP_FUNCTION_ENABLED: 임시 기능 활성화 여부 [불량 및 실적]
  ADD_TEMP_FUNCTION_ENABLED:
    process.env.REACT_APP_ADD_TEMP_FUNCTION_ENABLED === "true" || false,
  //[스피드랙] ADD_WORK_TIME_TRACKING_POSITION: 작업시간 추적 위치 추가 기능 활성화 여부
  ADD_WORK_TIME_TRACKING_POSITION:
    process.env.REACT_APP_ADD_WORK_TIME_TRACKING_POSITION === "true" || false,
  //[스피드랙] 투입과 투입 사이에 생산 실적을 입력하지 않았습니다
  ADD_PRODUCTION_RECORD_ENTERED:
    process.env.REACT_APP_ADD_PRODUCTION_RECORD_ENTERED === "true" || false,
  //[스피드랙] ADD_INVENTORY_CORRECT:재고 보정(이전자재 사용여부)에 관련한 기능 사용여부
  ADD_INVENTORY_CORRECT:
    process.env.REACT_APP_ADD_INVENTORY_CORRECT === "true" || false,
  //[스피드랙] 파레트 분리 로그 활성화
  ADD_PALLET_LOGS: process.env.REACT_APP_ADD_PALLET_LOGS === "true" || false,

  //[우장] ADD_DOWNTIME_REASON: 비가동사유 신규 추가 기능 활성화 여부
  ADD_DOWNTIME_REASON:
    process.env.REACT_APP_ADD_DOWNTIME_REASON === "true" || false,
  //[우장] END_QUANTITY_MISS_MATCH: 작업지시 수량과 생산수량이 다를경우 종료 여부 메시지
  END_QUANTITY_MISS_MATCH:
    process.env.REACT_APP_END_QUANTITY_MISS_MATCH === "true" || false,

  //[경남] ADD_RECYCLE_DEFECT: 불량 재활용
  ADD_RECYCLE_DEFECT:
    process.env.REACT_APP_ADD_RECYCLE_DEFECT === "true" || false,
  //[경남] REACT_APP_ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING: 벌크이동시 BOM 검색 메뉴 설정 활성화 여부
  ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING:
    process.env.REACT_APP_ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING ===
      "true" || false,

  ADD_REFACTOR_DESIGN:
    process.env.REACT_APP_ADD_REFACTOR_DESIGN === "true" || false,

  ADD_SAVE_NO_MINUS_PERFORMANCE:
    process.env.REACT_APP_ADD_SAVE_NO_MINUS_PERFORMANCE === "true" || false,
};
