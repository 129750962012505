import { BaseSegmentedControl } from "@/components/atom/segmentedControl/BaseSegmentedControl";
import { SegmentedControlProps } from "@mantine/core";

interface InspectSegmentedControlProps extends SegmentedControlProps {}

export const InspectSegmentedControl = ({
  ...etcProps
}: InspectSegmentedControlProps) => {
  return (
    <BaseSegmentedControl
      size="xl"
      radius="1.5rem"
      w="100%"
      color="blue"
      {...etcProps}
    />
  );
};
