import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { ReactNode, forwardRef, useState } from "react";

import { AutoCompleteInputContainer } from "@/components/atom/inputContainer/autoCompleteInputContainer";
import {
  EquipmentOption,
  useEquipmentsAutoComplete,
  useEquipmentsCode,
} from "@/components/organism/autoComplete/equipment/auto-useGetEquipmentsQuery";
import { IconX } from "@tabler/icons-react";

interface EquipmentProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export interface EquipmentAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (equipmentCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

// 입력값: value (equipment 모델의 code)
// 출력값: onChange (equipment 모델의 code)

export const EquipmentAutoComplete = (params: EquipmentAutoCompleteProps) => {
  const {
    value: equipmentCode,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useEquipmentsAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useEquipmentsCode(
    !!equipmentCode,
    equipmentCode ?? null
  );

  let selectedEquipment = initialOptions?.find(
    (equipment) => equipment.value === equipmentCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedEquipment = options?.find((equipment) => equipment.value === e);
    onChange && onChange(e);
  };

  const SelectEquipment = forwardRef<HTMLDivElement, EquipmentProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(설비코드: {code})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <Select
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <AutoCompleteInputContainer selectedValue={equipmentCode ?? ""}>
          {children}
        </AutoCompleteInputContainer>
      )}
      value={equipmentCode}
      itemComponent={SelectEquipment}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: EquipmentProps[], option: EquipmentProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={EquipmentInfo({
        equipment: selectedEquipment as EquipmentOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      {...etcParams}
    />
  );
};

const EquipmentInfo = (params: {
  equipment?: EquipmentOption;
  onChange: (equipmentCode: string | null) => void;
}) => {
  const { equipment, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return equipment?.value ? (
    <IconX size="1.5rem" onClick={clearHandler} />
  ) : null;
};
