import {
  INVENTORIES_KEY,
  mutateInventories,
} from "@/api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "@/api/workLogs/useWorkLogsQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import {
  DefaultApiWmsBatchPostRequest,
  WmsBatchPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useOutgoingAllHandler = () => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const { mutate: transferAllMutate } = useMutation(
    (params: DefaultApiWmsBatchPostRequest) =>
      mutateInventories.outgoingAll(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.OUTGOING_ALL_SUCCESS,
        });
        closeModal();
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.OUTGOING_ALL_FAIL,
        });
      },
    }
  );

  const onSubmit = ({
    wmsBatchPostRequest,
  }: {
    wmsBatchPostRequest: WmsBatchPostRequest;
  }) => {
    transferAllMutate({ wmsBatchPostRequest });
  };

  return {
    onSubmit,
  };
};
