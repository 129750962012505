import { BASE_URL } from "@/instance/axios";

export const getPrinterCodeByUserWithWhoami = async () => {
  const token = localStorage.getItem("authToken") ?? "";

  try {
    const response = await fetch(`${BASE_URL}/auth/whoami`, {
      method: "GET", // HTTP 메소드 지정
      headers: {
        accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const data = await response.json(); // 응답을 JSON 형태로 파싱›

    return data.printerCode; // printerCode 반환
  } catch (error) {
    console.error("Error:", error); // 오류 처리
    return {};
  }
};
