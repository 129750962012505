import { GET_USERS_QUERY_KEY } from "@/api/users/useUserQuery";
import { MasterInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  AuthSignupPost201Response,
  MasterApiUsersFindPostRequest,
  MasterApiUsersGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

export const users = createQueryKeys(GET_USERS_QUERY_KEY, {
  all: null,
  get: (params: MasterApiUsersGetRequest) => {
    return {
      queryKey: [params],
      queryFn: () => MasterInstance.usersGet(params),
    };
  },
  getSelect: (params: MasterApiUsersFindPostRequest) => {
    return {
      queryKey: [params],
      queryFn: () =>
        MasterInstance.usersFindPost(params).then(
          (res: AxiosResponse<AuthSignupPost201Response[], any>) => res.data
        ),
    };
  },
});
