import { customFunctions } from "@/config/customFunctions";
import { useModal } from "@/context/ModalStackManager";

import { RawMaterialAllForm } from "@/components/organism/form/RawMaterialAllForm";
import { RawMaterialForm } from "@/components/organism/form/RawMaterialForm";
import { COMMON_TEXT } from "@/constants/text";
import { useWorkLogsFetch } from "@/fetch/logs/useWorkLogsFetch";
import { useInventoriesFetch } from "@/fetch/page/work/useInventoriesFetch";
import { useInventoryHandler } from "@/hooks/handler/useInventoryHandler";
import { useNotification } from "@/hooks/useNotification";
import { useWorkDataStore } from "@/store/work.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

export const useMaterialManagementHandler = () => {
  const { openModal, closeModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { data: logsData } = useWorkLogsFetch();
  const { data: inventoryData } = useInventoriesFetch({
    workData: workData.work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const inputLogsAll = logsData?.data;
  const inventories = inventoryData?.data;

  const {
    notifyProductionRecordEnteredModal,
    notifyPreviousMaterialUsedModal,
  } = useNotification();
  const { handleCorrect } = useInventoryHandler();

  // 조건에 사용되는 값
  const isLastLogInputType = inputLogsAll?.[0]?.workLogType === "INPUT";
  const isLastLogCanceled = inputLogsAll?.[0]?.alreadyCanceledWorkLog;
  const isInventory = (inventories?.length as number) > 0;
  const isPerformanceRecordedAfterInput =
    !isLastLogCanceled && isLastLogInputType;

  // 투입 이후 실적을 입력했는지에 대한 판단 하는 함수 및 모달
  const showProductionRecord = () => {
    if (isPerformanceRecordedAfterInput) {
      return notifyProductionRecordEnteredModal();
    }
    return true;
  };

  // 이전 자재 사용 여부 확인 모달
  const showPreviousMaterialUsed = () => {
    const isCorrect = isInventory && !isPerformanceRecordedAfterInput;
    if (isCorrect) {
      return notifyPreviousMaterialUsedModal();
    }
    return false;
  };

  // 원부자재 투입 모달
  const showRawMaterial = () => {
    return openModal(
      <RawMaterialForm />,
      "",
      COMMON_TEXT.FORM.RAW_MATERIAL_TITLE
    );
  };

  // 원부자재 일괄투입 모달
  const showRawMaterialAll = () => {
    return openModal(
      <RawMaterialAllForm />,
      "",
      COMMON_TEXT.FORM.RAW_MATERIAL_ALL_TITLE
    );
  };

  // 원부자재 투입 이벤트
  const handleInputRawMaterial = async () => {
    if (customFunctions.ADD_PRODUCTION_RECORD_ENTERED) {
      const productionRecord = await showProductionRecord();
      if (!productionRecord) return;
    }

    if (customFunctions.ADD_INVENTORY_CORRECT) {
      const previousMaterial = await showPreviousMaterialUsed();
      if (previousMaterial) {
        handleCorrect();
      }
    }

    showRawMaterial();
  };

  // 원부자재 일괄투입
  const handleInputRawMaterialAll = async () => {
    if (customFunctions.ADD_PRODUCTION_RECORD_ENTERED) {
      const productionRecord = await showProductionRecord();
      if (!productionRecord) return;
    }

    if (customFunctions.ADD_INVENTORY_CORRECT) {
      const previousMaterial = await showPreviousMaterialUsed();
      if (previousMaterial) {
        handleCorrect();
      }
    }

    showRawMaterialAll();
  };

  return {
    handleInputRawMaterial,
    handleInputRawMaterialAll,
  };
};
