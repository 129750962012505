import { BaseButton } from "@/components/atom/button/BaseButton";
import { ButtonProps, MantineColor } from "@mantine/core";

interface DecrementButtonProps
  extends ButtonProps,
    Partial<React.RefAttributes<HTMLButtonElement>> {
  color?: MantineColor;
  num: string;
  onChange?: (value: number) => void;
  value?: string;
}

export function DecrementButton(props: DecrementButtonProps) {
  const { value, onChange, num } = props;

  const handleClick = () => {
    onChange && onChange(Number(value) - Number(num));
  };
  return (
    <BaseButton onClick={handleClick} color="red" variant="outline" {...props}>
      -{num}
    </BaseButton>
  );
}
