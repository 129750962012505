import { BaseBox } from "@/components/atom/box/BaseBox";
import { BoxProps } from "@mantine/core";

export const OutsourceInformBox = (props: BoxProps) => {
  const { children, ...etcProps } = props;

  return (
    <BaseBox c={"red"} {...etcProps}>
      {children}
    </BaseBox>
  );
};
