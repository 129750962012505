import { usePutWorkData } from "@/hooks/usePutWorkData";
import { useWorkDataStore } from "@/store/work.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useCallback } from "react";

export const useStatus = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const work =
    workData?.work as ProductionPlansGet200ResponseRowsInnerWorksInner;

  const workId = work?.id as number;

  const { pauseMutate, endMutate, startMutate, resumeMutate, cancelEndMutate } =
    usePutWorkData();

  const onStart = useCallback(() => {
    return startMutate({
      worksWorkIdTrackingStartPostRequest: {
        workId: workId,
      },
      workId: workId,
    });
  }, [workData]);

  const onPause = useCallback(
    (downtimeReasonCode: string) => {
      pauseMutate({
        worksWorkIdTrackingPausePostRequest: {
          workId: workId || 0,
          downtimeReasonCode: downtimeReasonCode,
        },
        workId: workId || 0,
      });
    },
    [workData]
  );

  const onEnd = useCallback(() => {
    endMutate({
      workId: workId,
      worksWorkIdTrackingStartPostRequest: {
        workId: workId,
      },
    });
  }, [workData]);

  const onResume = useCallback(() => {
    resumeMutate({
      workId: workId,
      worksWorkIdTrackingStartPostRequest: {
        workId: workId,
      },
    });
  }, [workData]);

  const onCancelEnd = useCallback(() => {
    cancelEndMutate({
      workId: workId,
      worksWorkIdTrackingStartPostRequest: {
        workId: workId,
      },
    });
  }, [workData]);

  return {
    onStart,
    onPause,
    onEnd,
    onResume,
    onCancelEnd,
  };
};
