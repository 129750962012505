import { Flex } from "@mantine/core";

interface WorkByRowTemplateProps {
  children: React.ReactNode;
}

const PageTemplate = ({ children }: WorkByRowTemplateProps) => (
  <Flex direction={"column"} h={"100%"}>
    {children}
  </Flex>
);

export default PageTemplate;
