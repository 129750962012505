import {
  TabProps,
  Tabs,
  TabsListProps,
  TabsPanelProps,
  TabsProps,
} from "@mantine/core";
import React from "react";

interface CustomTab {
  value: string; // Tab 식별자
  label: string; // Tab 라벨
  content: React.ReactNode; // Tab에 표시할 내용
  color?: string; // Tab 색상 (선택적)
}

interface LogsTabsProps {
  tabs: CustomTab[]; // Tab 정보 배열
  tabsProps?: TabsProps; // Mantine Tabs 전체 Props
  listProps?: TabsListProps; // Tabs.List Props
  tabProps?: TabProps; // Tabs.Tab Props (각 Tab에 적용)
  panelProps?: TabsPanelProps; // Tabs.Panel Props (각 Panel에 적용)
}

export const LogsTabs: React.FC<LogsTabsProps> = ({
  tabs,
  tabsProps,
  listProps,
  tabProps,
  panelProps,
}) => {
  return (
    <Tabs
      styles={{
        tab: {
          fontSize: "0.9rem",
          color: "white",
          '&[aria-selected="true"]': {
            color: "white",
            background: "inherit",
            fontWeight: 900,
            borderBottom: "2px solid #228BE6", // 선택된 탭에만 흰색 하단 표시
          },
        },
        panel: {
          marginTop: "1rem",
        },
      }}
      {...tabsProps}
      defaultValue={tabs[0]?.value}
    >
      <Tabs.List {...listProps}>
        {tabs.map((tab) => (
          <Tabs.Tab
            key={tab.value}
            value={tab.value}
            color={tab.color}
            {...tabProps}
          >
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {tabs.map((tab) => (
        <Tabs.Panel key={tab.value} value={tab.value} {...panelProps}>
          {tab.content}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};
