import { INVENTORIES_KEY } from "@/api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY, workLogsMutate } from "@/api/workLogs/useWorkLogsQuery";
import { COMMON_TEXT } from "@/constants/text";
import { customNotification } from "@/utils/notificationShow";
import {
  ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelCorrectionPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

export const useWorkLogsHandler = () => {
  const queryClient = useQueryClient();

  const { mutate: cancelDefectMutate } = useMutation(
    (params: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest) =>
      workLogsMutate.cancelDefect(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_FAIL,
        });
      },
    }
  );

  const { mutate: cancelInputMutate } = useMutation(
    (params: ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest) =>
      workLogsMutate.cancelInput(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_FAIL,
        });
      },
    }
  );

  const { mutate: cancelPerformanceMutate } = useMutation(
    (params: ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest) =>
      workLogsMutate.cancelPerformance(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_FAIL,
        });
      },
    }
  );

  const { mutate: cancelSplitLotMutate } = useMutation(
    (
      params: ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest
    ) => workLogsMutate.cancelSplitLot(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_FAIL,
        });
      },
    }
  );

  const { mutate: cancelCorrectMutate } = useMutation(
    (params: ProductionActionApiWorkLogsWorkLogIdCancelCorrectionPutRequest) =>
      workLogsMutate.cancelCorrect(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_FAIL,
        });
      },
    }
  );

  const onCancel = ({
    workLogId,
    logType,
  }: {
    workLogId: number | undefined;
    logType: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum | undefined;
  }) => {
    if (workLogId === undefined) {
      return;
    }
    if (!window.confirm(COMMON_TEXT.NOTIFICATION.WORK_CANCEL)) {
      return;
    }
    switch (logType) {
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE:
        cancelPerformanceMutate({
          workLogId: workLogId,
          workLogsWorkLogIdCancelInputPutRequest: {
            createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          },
        });
        break;
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT:
        cancelDefectMutate({
          workLogId: workLogId,
        });
        break;
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT:
        cancelInputMutate({
          workLogId: workLogId,
        });
        break;
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.SPLIT_LOT:
        cancelSplitLotMutate({
          workLogId: workLogId,
        });
        break;
      case WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CORRECTION:
        cancelCorrectMutate({
          workLogId: workLogId,
        });
        break;
      default:
        break;
    }
  };

  return {
    onCancel,
  };
};
