import { BaseText } from "@/components/atom/text/BaseText";
import { Group, TextProps } from "@mantine/core";
import React from "react";

interface LabelValueDisplayProps extends TextProps {
  label: string;
  value: string | number | undefined;
  labelColor?: string;
  valueColor?: string;
  direction?: "horizontal" | "vertical";
}

const LabelValue: React.FC<LabelValueDisplayProps> = ({
  label,
  value,
  labelColor = "gray",
  valueColor = "black",
  direction = "horizontal",
  ...etcProps
}) => {
  return (
    <Group
      spacing="sm"
      dir={direction === "vertical" ? "column" : "row"}
      align={direction === "vertical" ? "flex-start" : "center"}
    >
      <BaseText weight={500} color={labelColor} {...etcProps}>
        {label}:
      </BaseText>
      <BaseText weight={700} color={valueColor} {...etcProps}>
        {value}
      </BaseText>
    </Group>
  );
};

export default LabelValue;
