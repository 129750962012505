import ExpandableInspectAccordion from "@/components/atom/accordion/inspect/ExpandableInspectAccordion";
import { InspectSegmentedControl } from "@/components/atom/segmentedControl/Inspect/InspectSegmentedControl";
import { CreatorAndDate } from "@/components/molecule/creatorAndDate/CreatorAndDate";
import { InspectCheckSheet } from "@/components/molecule/inspectCheckSheet/InspectCheckSheet";
import { InspectRadioGroup } from "@/components/molecule/inspectRadioGroup/inspectRadioGroup";
import { COMMON_TEXT } from "@/constants/text";
import { Accordion } from "@mantine/core";

const InspectRadio = Object.assign(
  {},
  {
    CheckSheet: InspectCheckSheet,
    SegmentedControl: InspectSegmentedControl,
    CreatorAndDate: CreatorAndDate,
    Accordion: ExpandableInspectAccordion,
  }
);

interface DataProps {
  value: string;
  label: string;
}

interface InspectRadioCategoryProps {
  data: DataProps[];
  checkNum: number;
  createdAt: string | undefined;
  creator: string | undefined;
  disabled?: boolean;
  onChange?: (value: string) => void;
  value?: string;
}

export const InspectRadioCategory = ({
  data,
  checkNum,
  createdAt,
  creator,
  onChange,
  value,
  disabled,
}: InspectRadioCategoryProps) => {
  const getBackgroundColor = () => {
    if (value === COMMON_TEXT.INSPECT.PASS) return "#00c458";
    if (value === COMMON_TEXT.INSPECT.FAIL) return "	#ef2e45";
    return undefined;
  };

  const bg = getBackgroundColor();

  return (
    <InspectRadio.Accordion>
      <Accordion.Item value="radioCategory">
        <Accordion.Control bg={bg}>
          <InspectRadio.CheckSheet checkNum={checkNum} />
        </Accordion.Control>
        <Accordion.Panel>
          <InspectRadio.CreatorAndDate
            createdAt={createdAt}
            creator={creator}
          />
          <InspectRadioGroup
            disabled={disabled}
            data={data}
            onChange={onChange}
            value={value}
          />
        </Accordion.Panel>
      </Accordion.Item>
    </InspectRadio.Accordion>
  );
};
