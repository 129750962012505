import { DateText } from "@/components/atom/text/dateText/DateText";
import { COMMON_TEXT } from "@/constants/text";

import { Flex, Text, TextProps } from "@mantine/core";

interface CreatorAndDateProps extends TextProps {
  creator: string | undefined;
  createdAt: string | undefined;
}

export const CreatorAndDate = ({
  creator,
  createdAt,
  ...etcProps
}: CreatorAndDateProps) => {
  return (
    <>
      <Flex gap="xs">
        <Text fw={"600"} {...etcProps}>
          {COMMON_TEXT.INSPECT.CREATOR} :{" "}
        </Text>
        <Text {...etcProps}>{creator}</Text>
      </Flex>
      <Flex gap="xs" c={"gray"}>
        <Text fw={"600"}>{COMMON_TEXT.INSPECT.CREATED_AT} : </Text>
        <DateText
          value={createdAt}
          dateFormat="YYYY-MM-DD hh:mm:ss"
          {...etcProps}
        />
      </Flex>
    </>
  );
};
