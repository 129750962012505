import { workLogs } from "@/api/logs/useWorksLogsQuery";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import useFetcher from "@/hooks/useFetcher";
import { usePaginationStore } from "@/store/pagenation.store";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";

export const useInputLogsFormFetch = () => {
  const { workId } = useLocalStorage();
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));
  const { queryKey, queryFn } = workLogs.get({
    query: {
      $and: [
        { workId: workId },
        {
          $or: [
            {
              workLogType: {
                $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT,
              },
            },
          ],
        },
      ],
    },
    page: currentPage,
    populate: [
      "downtimeReasonName",
      "defectName",
      "itemUnit",
      "alreadyCanceled",
      "alreadyCanceledWorkLog",
      "item",
    ],
    sort: "-createdAt",
  });

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
