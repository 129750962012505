import { Text } from "@mantine/core";

interface AutoCompleteInputContainerProps {
  children: React.ReactNode;
  selectedValue: string;
}

export const AutoCompleteInputContainer = ({
  children,
  selectedValue,
}: AutoCompleteInputContainerProps) => {
  return (
    <Text style={{ position: "relative" }}>
      {children}
      {selectedValue && (
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            top: "35px",
            fontSize: "0.8em",
            color: "#888",
            left: "5px",
          }}
        >
          {"Code: " + selectedValue}
        </div>
      )}
    </Text>
  );
};
