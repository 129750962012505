import { ProductionInstance } from "@/instance/axios";
import {
  createMutationKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import { ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

const SPLIT_KEY = "split";

export const mutateSplit = createMutationKeys(SPLIT_KEY, {
  split: (
    params: ProductionActionApiProductionPlansProductionPlanIdSplitProductLotPostRequest
  ) => ({
    mutationKey: [
      params.productionPlanId,
      params.productionPlansProductionPlanIdSplitProductLotPostRequest,
    ],
    mutationFn: () =>
      ProductionInstance.productionPlansProductionPlanIdSplitProductLotPost(
        params
      ),
  }),
});

export const defectQueryKeys = mergeQueryKeys(mutateSplit);
