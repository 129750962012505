import { BaseBox } from "@/components/atom/box/BaseBox";
import { BoxProps, Text } from "@mantine/core";

interface NavBoxProps extends BoxProps {
  onClick?: () => void;
}

export const NavBox = ({ children, onClick, ...etcProps }: NavBoxProps) => {
  return (
    <BaseBox
      onClick={onClick}
      p={"sm"}
      sx={{
        color: "white",
        border: "1px solid rgb(34, 139, 230)",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "rgb(34, 139, 230)",
          color: "white",
        },
      }}
      {...etcProps}
    >
      <Text size={"sm"}>{children}</Text>
    </BaseBox>
  );
};
