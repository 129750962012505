import { GlobalLoaderOverlay } from "@/components/atom/loadingOverlay/global/GlobalLoaderOverlay";
import App from "@/components/page";
import { LocalStorageProvider } from "@/context/LocalStorageProvider";
import ModalStackManager from "@/context/ModalStackManager";
import { WorksSearchProvider } from "@/context/WorksSearchProvider";
import i18n from "@/language/i18n";
import QueryProvider from "@/provider/QueryProvider";
import { customNotification } from "@/utils/notificationShow";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import {
  QueryClient,
  QueryClientProvider,
  useIsMutating,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { IoProvider } from "socket.io-react-hook";
import GlobalStyle from "./styles/Global";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// 커리 요청 공통에러
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // 에러가 발생했을 때 자동 재시도를 하지 않음
      // 단, 우선순위는 각 요청의 onError가 더 높음(Global Error < Fetcher Error)
      onError: (error) => {
        const axiosError = error as AxiosError<Error>;
        customNotification.error({
          message: axiosError?.response?.data.message as string,
        });
      },
    },
    mutations: {
      onError: (error) => {
        const axiosError = error as AxiosError<Error>;
        customNotification.error({
          message: axiosError?.response?.data.message as string,
        });
      },
    },
  },
});

// Mutating에 관련한 로딩은 공통으로 처리하되, Query에 대한 로딩은 각 Fetcher로 필요한 곳에서만 처리
const GlobalLoader = () => {
  const isMutating = useIsMutating();
  return (
    <GlobalLoaderOverlay
      h={"100vh"}
      overlayColor="none"
      visible={!!isMutating}
    />
  );
};

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <LocalStorageProvider>
        <IoProvider>
          <RecoilRoot>
            <I18nextProvider i18n={i18n}>
              <QueryClientProvider client={queryClient}>
                <QueryProvider>
                  <ModalStackManager>
                    <MantineProvider>
                      <WorksSearchProvider>
                        <Notifications />
                        <GlobalStyle />
                        <GlobalLoader />
                        <App />
                      </WorksSearchProvider>
                    </MantineProvider>
                  </ModalStackManager>
                </QueryProvider>
              </QueryClientProvider>
            </I18nextProvider>
          </RecoilRoot>
        </IoProvider>
      </LocalStorageProvider>
    </React.StrictMode>
  </BrowserRouter>
);
