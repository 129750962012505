import { usersMutate } from "@/api/users/useUserQuery";
import { WHO_AM_I_KEY } from "@/api/whoami/useAuthWhoamiGetQuery";
import { COMMON_TEXT } from "@/constants/text";
import { customNotification } from "@/utils/notificationShow";
import { MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUserHandler = () => {
  const queryClient = useQueryClient();
  const { mutate: InventoriesMutate } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      usersMutate.update(params).mutationFn(undefined),
    {
      onSuccess: (data) => {
        // 성공 메시지 표시
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.USER_MODIFY,
        });

        // 쿼리 무효화
        queryClient.invalidateQueries([WHO_AM_I_KEY]);
      },
    }
  );

  const onSubmit = ({
    masterApiUsersUserIdPutRequest,
    successCallback,
  }: {
    masterApiUsersUserIdPutRequest: MasterApiUsersUserIdPutRequest;
    successCallback?: (data: any) => void;
  }) => {
    InventoriesMutate(masterApiUsersUserIdPutRequest, {
      onSuccess: (data) => {
        if (successCallback) {
          successCallback(data);
        }
      },
    });
  };

  return { onSubmit };
};
