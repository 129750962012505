import { BaseButton } from "@/components/atom/button/BaseButton";
import { ButtonProps, MantineColor } from "@mantine/core";

interface StatusButtonProps extends ButtonProps {
  color?: MantineColor;
  onClick?: () => void;
}

export function StatusButton(props: StatusButtonProps) {
  const { children } = props;

  return (
    <BaseButton radius="sm" {...props}>
      {children}
    </BaseButton>
  );
}
