import { InspectBox } from "@/components/atom/box/inspect/InspectBox";
import { BaseRadio } from "@/components/atom/radio/BaseRadio";
import { RadioProps } from "@mantine/core";
import { useRef } from "react";

interface InspectRadio extends RadioProps {}

export const InspectRadio = ({ ...etcParams }: InspectRadio) => {
  const radioRef = useRef<HTMLInputElement>(null);

  const handleBoxClick = () => {
    if (radioRef.current) {
      radioRef.current.click(); // Radio 버튼 클릭 이벤트 트리거
    }
  };

  return (
    <InspectBox
      w={"100%"}
      sx={(theme) => ({
        padding: theme.spacing.md,
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[5]}`,
        display: "flex",
        flexDirection: "column",
      })}
      onClick={handleBoxClick} // InspectBox 클릭 이벤트 핸들러
    >
      <BaseRadio
        ref={radioRef}
        {...etcParams}
        styles={{
          label: {
            fontSize: "1rem",
          },
        }}
      />
    </InspectBox>
  );
};
