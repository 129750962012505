import ExpandableAccordion from "@/components/atom/accordion/expandable/ExpandableAccordion";
import { useWorkDataStore } from "@/store/work.store";

import { WorkText } from "@/components/atom/text/work/WorkText";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { COMMON_TEXT } from "@/constants/text";
import { Accordion, Flex } from "@mantine/core";

const WorkLocation = Object.assign(
  {},
  {
    Title: WorkTitle,
    Text: WorkText,
    Accordion: ExpandableAccordion,
  }
);

export const WorkLocationInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  return (
    <WorkLocation.Accordion>
      <Accordion.Item value="ledgerTable">
        <Accordion.Control>
          <Flex w={"100%"} justify={"space-between"}>
            <WorkLocation.Title c={"#FFFFFF"} order={5}>
              {COMMON_TEXT.TITLE.LOCATION}
            </WorkLocation.Title>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex w={"100%"} direction={"column"} p={10} gap={10}>
            <Flex justify={"space-between"}>
              <WorkLocation.Title size={"sm"}>
                {COMMON_TEXT.TITLE.FROM_LOCATION}
              </WorkLocation.Title>
              <Flex wrap={"wrap"}>
                <WorkLocation.Text fw={"bold"}>
                  [
                  {work?.currentRoutingOutsourceData?.fromLocationCode ??
                    work?.locationSetting?.fromLocation?.code}
                  ]
                </WorkLocation.Text>
                <WorkLocation.Text fw={"bold"}>
                  {work?.currentRoutingOutsourceData?.fromLocationName ??
                    work?.locationSetting?.fromLocation?.name}
                </WorkLocation.Text>
              </Flex>
            </Flex>
            <Flex justify={"space-between"}>
              <WorkLocation.Title size={"sm"}>
                {COMMON_TEXT.TITLE.TO_LOCATION}
              </WorkLocation.Title>
              <Flex wrap={"wrap"}>
                <WorkLocation.Text fw={"bold"}>
                  [
                  {work?.currentRoutingOutsourceData?.toLocationCode ??
                    work?.locationSetting?.toLocation?.code}
                  ]
                </WorkLocation.Text>
                <WorkLocation.Text fw={"bold"}>
                  {work?.currentRoutingOutsourceData?.toLocationName ??
                    work?.locationSetting?.toLocation?.name}
                </WorkLocation.Text>
              </Flex>
            </Flex>
            <Flex justify={"space-between"}>
              <WorkLocation.Title size={"sm"}>
                {COMMON_TEXT.TITLE.TARGET_LOCATION}
              </WorkLocation.Title>
              <Flex wrap={"wrap"}>
                <WorkLocation.Text fw={"bold"}>
                  [{work?.locationSetting?.targetLocation?.code ?? "-"}]
                </WorkLocation.Text>
                <WorkLocation.Text fw={"bold"}>
                  {work?.locationSetting?.targetLocation?.name ?? "-"}
                </WorkLocation.Text>
              </Flex>
            </Flex>
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </WorkLocation.Accordion>
  );
};
