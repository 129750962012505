import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useLoginHandler } from "@/hooks/handler/useLoginHandler";
import { Box, Button, Center, PasswordInput, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type FormType = {
  id: string;
  password: string;
};

export const LoginPage = () => {
  const { onSubmit } = useLoginHandler();
  const { setIsAuthenticated } = useLocalStorage();
  const token = localStorage.getItem("authToken") ?? "";
  const navigate = useNavigate();

  const form = useForm<FormType>({
    initialValues: {
      id: "",
      password: "",
    },
  });

  const handleLogin = (values: FormType) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true);
      navigate("/works");
    }
  }, [token]);

  return (
    <Center style={{ width: "100vw", height: "100vh" }}>
      <Box style={{ width: "400px" }}>
        <form onSubmit={form.onSubmit((values) => handleLogin(values))}>
          <TextInput
            styles={{
              label: {
                color: "white",
              },
            }}
            mb={"xl"}
            size="xl"
            label={COMMON_TEXT.LABEL.USER_ID}
            placeholder="ID"
            withAsterisk
            {...form.getInputProps("id")}
          />
          <PasswordInput
            styles={{
              label: {
                color: "white",
              },
            }}
            size="xl"
            label={COMMON_TEXT.LABEL.PASSWORD}
            placeholder="Password"
            withAsterisk
            {...form.getInputProps("password")}
          />
          <Button size="xl" type="submit" fullWidth mt="md">
            {COMMON_TEXT.BUTTON.LOGIN}
          </Button>
        </form>
      </Box>
    </Center>
  );
};
