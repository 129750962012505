import { InspectRadio } from "@/components/molecule/inspectRadio/InspectRadio";
import { Flex, Group, Radio } from "@mantine/core";

type RadioDataProps = {
  value: string;
  label: string;
};

interface InspectRadioGroupProps {
  disabled?: boolean;
  data: RadioDataProps[];
  value?: string;
  onChange?: (value: string) => void;
}

export const InspectRadioGroup = ({
  disabled,
  data,
  value,
  onChange,
}: InspectRadioGroupProps) => {
  return (
    <Radio.Group withAsterisk onChange={onChange} value={value}>
      <Group mt="xs">
        <Flex direction={"column"} gap={"sm"} w={"100%"}>
          {data.map((item) => (
            <InspectRadio
              disabled={disabled}
              key={item.value}
              value={item.value}
              label={item.label}
            />
          ))}
        </Flex>
      </Group>
    </Radio.Group>
  );
};
