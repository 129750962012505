import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { BarcodeInput } from "@/components/molecule/barcodeInput/BarcodeInput";
import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import { InventoriesAutoComplete } from "@/components/organism/autoComplete/inventory/inventories-autoComplete";
import FormTemplate from "@/components/template/form/FormTemplate";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useInventoriesFormFetch } from "@/fetch/form/useInventoriesFormFetch";
import { useRawMaterialAllHandler } from "@/hooks/handler/useRawMaterialAllHandler";
import { useWorkDataStore } from "@/store/work.store";
import { customNotification } from "@/utils/notificationShow";
import { setToLocaleString } from "@/utils/unitMark";
import { Flex, Pagination } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import {
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  WorksBulkInputPutRequestWorkLotPairsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { MRT_ColumnDef, MRT_Row } from "mantine-react-table";
import { useMemo } from "react";

const RawMaterialAll = Object.assign(
  {},
  {
    Template: FormTemplate,
    SubTitle: FormSubTitle,
    Description: FormDescription,
    TextInput: FormTextInput,
    NumberInput: FormNumberInput,
    BarcodeInput: BarcodeInput,
    Table: MantineSelectableTable,
    InventoriesAutoComplete: InventoriesAutoComplete,
    Pagination: Pagination,
  }
);

export const RawMaterialAllForm = () => {
  const { closeModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { work } = workData;
  const { onSubmit } = useRawMaterialAllHandler();

  const form = useForm({
    initialValues: {
      barcodeInput: "",
      worksBulkInputPutRequest: [],
    },
  });

  const locationCode = useMemo(() => {
    return (
      work?.locationSetting?.fromLocation?.code ??
      work?.routingData?.operation?.fromLocationCode ??
      work?.productionPlan?.routingsData?.find(
        (data) => data?.code === work?.routingCode
      )?.operation?.fromLocationCode
    );
  }, [work]);

  const fromLocationCode = useMemo(() => {
    return (
      work?.currentRoutingOutsourceData?.fromLocationCode ??
      work?.locationSetting?.fromLocation?.code ??
      work?.routingData?.operation?.fromLocationCode
    );
  }, [work]);

  const targetLocationCode = useMemo(() => {
    return (
      work?.equipment?.toLocationCode ??
      work?.locationSetting?.toLocation?.code ??
      work?.routingData?.operation?.toLocationCode ??
      work?.productionPlan?.routingsData?.find(
        (data: any) => data?.code === work?.routingCode
      )?.operation?.toLocationCode
    );
  }, [work]);

  const columns = () =>
    [
      {
        accessorKey: "order",
        header: COMMON_TEXT.COLUMN.ORDER,
      },
      {
        accessorKey: "itemCode",
        header: COMMON_TEXT.COLUMN.ITEM_CODE,
      },
      {
        accessorKey: "itemName",
        header: COMMON_TEXT.COLUMN.ITEM_NAME,
      },
      {
        accessorKey: "lotName",
        header: COMMON_TEXT.COLUMN.LOT_NAME,
      },
      {
        accessorKey: "lotExpiration",
        header: COMMON_TEXT.COLUMN.LOT_EXPIRATION_DATE,
      },
      {
        accessorKey: "locationCode",
        header: COMMON_TEXT.COLUMN.LOT_LOCATION,
      },
      {
        accessorKey: "quantity",
        header: COMMON_TEXT.COLUMN.QUANTITY,
        Cell: ({ cell }) => {
          return (
            <FormNumberInput
              w={"6rem"}
              {...form.getInputProps(
                `worksBulkInputPutRequest.${cell.row.index}.quantity`
              )}
              value={Number(cell.getValue())}
            />
          );
        },
      },
    ] as MRT_ColumnDef<any>[];

  const bomItemCodes = work?.routingData?.routingBoms?.map(
    (value) => value.itemCode
  );

  const { data, refetch } = useInventoriesFormFetch({
    itemCodes: bomItemCodes,
    locationCode: locationCode,
    lotId: form.values.barcodeInput,
  });

  const convertedData = useMemo(() => {
    if (!data?.data?.rows) return [];
    return data.data.rows.map((row) => ({
      value: row.lotId?.toString() ?? "",
      label: `로트이름: ${row.lot?.name ?? "-"}, 로트유효기한: ${
        row.lot?.expiration
          ? dayjs(row.lot?.expiration).format("YYYY-MM-DD")
          : "-"
      }, 위치: ${row.locationCode}, 수량: ${setToLocaleString(row.quantity)}`,
      isDisabled: row?.quantity && Number(row?.quantity) < 0 ? true : false,
    }));
  }, [data?.data?.rows]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!form.values.barcodeInput) return;
      if (checkDuplicateLot(form.values.barcodeInput)) return;
      refetchHandler();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!inputValue) return;
    if (checkDuplicateLot(inputValue)) return;
    refetchHandler();
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    form.removeListItem("worksBulkInputPutRequest", row.index);
  };

  const checkDuplicateLot = (value: string) => {
    if (
      form.values.worksBulkInputPutRequest.some(
        (data: any) => data.lotId === Number(value)
      )
    ) {
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.DUPLICATE_LOT,
      });
      form.setFieldValue("barcodeInput", "");
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    const request = form.values.worksBulkInputPutRequest.map(
      (data: WorksBulkInputPutRequestWorkLotPairsInner) => ({
        workId: work?.id as number,
        lotId: data.lotId,
        sourceLocationCode: data.sourceLocationCode,
        targetLocationCode: data.targetLocationCode,
        quantity: String(data.quantity),
      })
    );

    onSubmit({ workLotPairs: request });
  };

  const handleInventories = (value: string | null) => {
    if (value === null) return;
    if (!checkDuplicateLot(value)) {
      const { lotId } = data?.data?.rows?.filter(
        (data) => data.lotId === Number(value)
      )[0] as ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
      form.setFieldValue("barcodeInput", String(lotId));
      form.setFieldValue("useBarcode", true);
    }
  };

  const refetchHandler = async () => {
    const data = await refetch();
    const inventoryData = data.data.data.rows;
    const noData = !inventoryData?.length;
    if (noData) {
      form.setFieldValue("barcodeInput", "");
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.LOT_NOT_FOUND,
      });
    } else {
      const { lotId, itemCode, itemName, lot, locationCode, quantity } =
        inventoryData[0] as ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
      form.setFieldValue("barcodeInput", "");
      form.insertListItem("worksBulkInputPutRequest", {
        lotName: lot?.name,
        lotExpiration: lot?.expiration,
        lotId,
        itemCode,
        itemName,
        locationCode,
        quantity,
      });
    }
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <RawMaterialAll.Template
      w={isSmallScreen ? "36rem" : "50rem"}
      onSubmit={handleOnSubmit}
      onCancel={() => closeModal({})}
      label="일괄 투입"
    >
      <RawMaterialAll.SubTitle>
        {COMMON_TEXT.FORM.RAW_MATERIAL_ALL_SUBTITLE}
      </RawMaterialAll.SubTitle>
      <RawMaterialAll.Description>
        {COMMON_TEXT.DESCRIPTION.RAW_MATERIAL_ALL_DESC}
      </RawMaterialAll.Description>
      {customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING && (
        <RawMaterialAll.InventoriesAutoComplete
          label={COMMON_TEXT.LABEL.LOT_INFORM}
          value={form.values.barcodeInput}
          data={convertedData ?? []}
          onChange={handleInventories}
        />
      )}
      <Flex justify={"space-between"}>
        <RawMaterialAll.BarcodeInput
          handleBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...form.getInputProps("barcodeInput")}
          label={COMMON_TEXT.LABEL.INPUT_BARCODE_INFORM}
          required
        />
        <Flex gap={"sm"}>
          <RawMaterialAll.TextInput
            withAsterisk
            disabled
            label={COMMON_TEXT.LABEL.DELIVERY_LOCATION_CODE}
            value={fromLocationCode}
          />
          <RawMaterialAll.TextInput
            withAsterisk
            disabled
            label={COMMON_TEXT.LABEL.TARGET_LOCATION_CODE}
            value={targetLocationCode}
          />
        </Flex>
      </Flex>
      <RawMaterialAll.Table
        enableRowActions={true}
        enableRowSelection={false}
        localization={{
          noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_LOT_INFORM,
        }}
        columns={columns()}
        data={form.values.worksBulkInputPutRequest.map(
          (data: WorksBulkInputPutRequestWorkLotPairsInner, index: number) => ({
            order: index + 1,
            ...data,
          })
        )}
        onClickDelete={handleRowDelete}
      />
    </RawMaterialAll.Template>
  );
};
