import DefaultInstance from "@/instance/axios";
import { setToLocaleString } from "@/utils/unitMark";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";

export type InventoryOption = {
  value: string;
  label: string;
  isDisabled: boolean;
};

const getAutoCompleteKeyword = (keyword: string, query: any) =>
  DefaultInstance.inventoriesFindPost({
    query: JSON.stringify(query),
    search: keyword ? keyword : "",
    searchFields: ["lotName"], // 로트명으로 검색하기 위함.
    populate: ["lot"],
  });

export const useInventoriesAutoComplete = (
  focus = false,
  query = {},
  keyword = "",
  options?: {
    onSuccess: (data: InventoryOption[]) => void;
  }
) => {
  return useQuery(
    ["Inventories-get-autocomplete", keyword],
    () => getAutoCompleteKeyword(keyword, query),
    {
      enabled: focus,
      select: (
        response: AxiosResponse<
          ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[],
          any
        >
      ) => {
        return (
          response.data?.map((inventory) => {
            // 화면에 표시할 데이터로 바로 변환. value는 id 할당
            return {
              value: inventory.lotId?.toString() ?? "",
              label: `로트이름: ${inventory.lot?.name ?? "-"}, 로트유효기한: ${
                inventory.lot?.expiration
                  ? dayjs(inventory.lot?.expiration).format("YYYY-MM-DD")
                  : "-"
              }, 위치: ${inventory.locationCode}, 수량: ${setToLocaleString(
                inventory.quantity
              )}`,
              isDisabled:
                inventory?.quantity && Number(inventory?.quantity) < 0
                  ? true
                  : false,
              data: inventory,
            };
          }) ?? []
        );
      },
      onSuccess: options?.onSuccess,
    }
  );
};
