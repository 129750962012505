import { BaseDatePicker } from "@/components/atom/datePicker/BaseDatePicker";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import "dayjs/locale/ko";
import { useState } from "react";

interface DateRangePickerProps {
  dates: [Date | null, Date | null];
}

export const DateRangePicker = ({ dates }: DateRangePickerProps) => {
  const { language } = useLocalStorage();
  const { closeModal } = useModal();

  const [value, setValue] = useState<[Date | null, Date | null]>([
    dates[0],
    dates[1],
  ]);

  // 날짜 범위 선택
  const handleChange = (e: any) => {
    setValue(e);
    // 두 번째 날짜가 선택되었을 때 모달을 닫는다.
    if (e[1] !== null) {
      closeModal(e);
    }
  };

  return (
    <BaseDatePicker
      type="range"
      allowSingleDateInRange // 동일날짜 선택가능
      value={value}
      onChange={(e) => handleChange(e)}
      size="xl"
      previousIcon={<IconChevronLeft />}
      nextIcon={<IconChevronRight />}
      locale={language}
      monthLabelFormat="MM/YYYY"
    />
  );
};
