import { WorkHeader } from "@/components/organism/header/original/workHeader/WorkHeader";
import { ItemInfoBox } from "@/components/organism/work/original/ItemInfoBox";
import { WorkCurrentDefectInform } from "@/components/organism/work/original/WorkCurrentDefectInform";
import { WorkDefectActions } from "@/components/organism/work/original/WorkDefectReport";
import { WorkEndReportActions } from "@/components/organism/work/original/WorkEndReportActions";
import { WorkInventoryInform } from "@/components/organism/work/original/WorkInventoryInform";
import { WorkLocationInform } from "@/components/organism/work/original/WorkLocationInform";
import { WorkProgressInform } from "@/components/organism/work/original/WorkProgressInform";
import { WorkTimeTrackInform } from "@/components/organism/work/original/WorkTimeTrackInform";
import { WarningPage } from "@/components/page/refactor/waningPage";
import PageTemplate from "@/components/template/page/PageTemplate";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useWorkFetch } from "@/fetch/page/work/useWorkFetch";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import useSocket from "@/hooks/useSocket.hook";
import { useEquipmentStore } from "@/store/equipment.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { Flex, Grid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";

const Work = Object.assign(
  {},
  {
    Template: PageTemplate,
    Header: WorkHeader,
  }
);

export const WorkPage = () => {
  useSocket();
  const { workId } = useLocalStorage();
  const { handleLimitCounter } = usePerformanceHandler();
  const { data } = useWorkFetch();
  const workData = data?.data;
  const { name, code } = workData?.equipment ?? {};
  const { setEquipment, equipment } = useEquipmentStore((state) => ({
    setEquipment: state.setEquipment,
    equipment: state.Equipment,
  }));
  const { setWorkData } = useWorkDataStore((state) => ({
    setWorkData: state.setWorkData,
  }));
  const { getData } = useSocketStore((state) => ({ getData: state.getData }));

  const counterData = getData(equipment?.code as string);

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (!code || !name) return;
    setEquipment({
      name: name,
      code: code,
    });
    setWorkData({ work: workData });
  }, [workData]);

  // useEffect(() => {
  //   const isLimitHit =
  //     counterData?.isLimitHit &&
  //     workData?.externalCounterSetting === "COUNTER_AND_HANDWRITING";
  //   if (isLimitHit) {
  //     handleLimitCounter();
  //   }
  // }, [counterData?.isLimitHit, workData?.externalCounterSetting]);

  if (workId === "undefined" || !workId) return <WarningPage />;

  return (
    <Work.Template>
      <WorkHeader />
      <Grid>
        {/* 왼쪽 칼럼: 상단 그룹 */}
        <Grid.Col span={isSmallScreen ? 12 : 6}>
          <Flex direction={"column"} gap={"lg"}>
            <ItemInfoBox />
            <WorkProgressInform />
            <WorkInventoryInform />
            <WorkLocationInform />
          </Flex>
        </Grid.Col>
        {/* 오른쪽 칼럼: 하단 그룹 */}
        <Grid.Col span={isSmallScreen ? 12 : 6}>
          <Flex direction={"column"} gap={"lg"}>
            <WorkTimeTrackInform />
            <WorkCurrentDefectInform />
            <WorkDefectActions />
            <WorkEndReportActions />
          </Flex>
        </Grid.Col>
      </Grid>
    </Work.Template>
  );
};
