import { works } from "@/api/work/useWorkQuery";
import useFetcher from "@/hooks/useFetcher";
import { useWorksSearchState } from "@/hooks/useWorksState.hook";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";

const { WAITING, PAUSED, WORKING, DONE } =
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;

interface UseWorksHeaderFetchProps {
  equipmentCode: string | undefined;
}

export const useWorksHeaderFetch = ({
  equipmentCode,
}: UseWorksHeaderFetchProps) => {
  const { dates, value, item, spec } = useWorksSearchState();

  const query: any = {
    $and: [
      {
        trackingStatus:
          value.length > 0 ? value : [WAITING, PAUSED, WORKING, DONE],
      },
    ],
  };

  if (equipmentCode) {
    query.$and.push({ equipmentCode: { $eq: equipmentCode } });
  }

  if (item) {
    query.$and.push({ itemName: { $eq: item } });
  }

  if (spec) {
    query.$and.push({ spec: { $eq: spec } });
  }

  const { queryKey, queryFn } = works.itemWorkFind({
    query: query,
    sort: "-createdAt",
    dates,
    pageSize: 10,
    populate: ["equipment", "summary", "item", "productionPlan", "routingData"],
  });

  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
