import { TrackingInstance } from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import {
  ProductionTimeTrackingActionApiWorksWorkIdTrackingCancelEndPostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const PRODUCTION_TRACKING_ACTION_API = "ProductionTimeTrackingActionApi";

const trackingActions = createMutationKeys(PRODUCTION_TRACKING_ACTION_API, {
  start: (
    params: ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest
  ) => ({
    mutationKey: ["start", params.workId],
    mutationFn: () => TrackingInstance.worksWorkIdTrackingStartPost(params),
  }),
  end: (
    params: ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest
  ) => ({
    mutationKey: ["end", params.workId],
    mutationFn: () => TrackingInstance.worksWorkIdTrackingEndPost(params),
  }),
  pause: (
    params: ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest
  ) => ({
    mutationKey: ["pause", params.workId],
    mutationFn: () => TrackingInstance.worksWorkIdTrackingPausePost(params),
  }),
  resume: (
    params: ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest
  ) => ({
    mutationKey: ["resume", params.workId],
    mutationFn: () => TrackingInstance.worksWorkIdTrackingResumePost(params),
  }),
  cancelEnd: (
    params: ProductionTimeTrackingActionApiWorksWorkIdTrackingCancelEndPostRequest
  ) => ({
    mutationKey: ["cancelEnd", params.workId],
    mutationFn: () => TrackingInstance.worksWorkIdTrackingCancelEndPost(params),
  }),
});

export default trackingActions;
