import { ProductionInstance } from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import { ProductionActionApiEquipmentsCorrectionPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const CORRECT_KEY = "correct";

export const correct = createMutationKeys(CORRECT_KEY, {
  put: (params: ProductionActionApiEquipmentsCorrectionPutRequest) => ({
    mutationKey: ["putCorrection", params],
    mutationFn: () => ProductionInstance.equipmentsCorrectionPut(params),
  }),
});
