import {
  InventoryOption,
  useInventoriesAutoComplete,
} from "@/components/organism/autoComplete/inventory/auto-useGetInventoriesQuery";
import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import React, { ReactNode, forwardRef, useEffect, useState } from "react";

interface InventoryProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface InventoriesAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (lotId: string | null) => void;
  maxDropdownHeight?: number;
  query?: any;
  // workData?: any;
  itemCode?: string | undefined;
  locationCode?: string | undefined;
  // defectLocationCode?: string;
  width?: string;
  setSelectedOption?: (
    data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | undefined
  ) => void;
}

export const InventoriesAutoComplete = (
  params: InventoriesAutoCompleteProps
) => {
  const {
    value: lotId,
    onChange,
    maxDropdownHeight,
    width,
    query,
    // workData,
    itemCode,
    locationCode,
    setSelectedOption,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useInventoriesAutoComplete(
    focused,
    query,
    searchKeyword
  );

  let selectedLot = options?.find((item) => {
    return item.value === lotId;
  });

  const [sLot, setSLot] = useState<InventoryOption | null>(null);

  // lotId나 options가 변경될 때마다 selectedLot 계산 및 sLot 업데이트
  // 화면에는 lotId가 아닌 lotName을 표시해야 하기 때문에 useEffect로 리렌더링이 필요하다.
  useEffect(() => {
    const selectedLot = options?.find((item) => item.value === lotId) || null;
    setSLot(selectedLot);
  }, [lotId, onChange]);

  const onChangeHandler = (e: string | null) => {
    const selectedOption = options?.find((item) => item.value === e) || null;
    setSLot(selectedOption);
    setSelectedOption && setSelectedOption(selectedOption?.data);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, InventoryProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            {/* <Text fz="xs"> (name: {code})</Text> */}
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <Select
      size="sm"
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedLot?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"lotId: " + selectedLot?.value}
            </div>
          )}
        </div>
      )}
      value={lotId && lotId}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={
        (options &&
          options.map((option) => ({
            value: option.value?.toString() || "",
            label: option.label,
            disabled: option.isDisabled,
          }))) ||
        []
      }
      searchable
      clearable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={LotInfo({
        lot: sLot as InventoryOption,
        onChange: onChangeHandler,
        setSLot: setSLot,
      })}
      {...etcParams}
    />
  );
};

const LotInfo = (params: {
  lot?: InventoryOption;
  onChange: (lot: string | null) => void;
  setSLot: React.Dispatch<React.SetStateAction<InventoryOption | null>>; // setSLot 함수를 props로 받음
}) => {
  const { lot, onChange, setSLot } = params;
  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
    setSLot(null); // sLot 상태도 초기화
  };

  return lot?.value ? <IconX size="2rem" onClick={clearHandler} /> : null;
};
