import { FormActionButtonGroup } from "@/components/molecule/formActionButtonGroup/FormActionButtonGroup";
import { Flex, FlexProps } from "@mantine/core";

interface FormTemplateProps extends FlexProps {
  children?: React.ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  isButtonDisabled?: boolean;
  label?: string | React.ReactElement;
}

const FormTemplate = ({
  children,
  onCancel,
  onSubmit,
  isButtonDisabled,
  label,
  ...etcProps
}: FormTemplateProps) => (
  <form className="form-content" style={{ minWidth: "30rem" }}>
    <Flex direction="column" gap="md" {...etcProps}>
      {children}
      {!isButtonDisabled && (
        <FormActionButtonGroup
          onSubmit={onSubmit}
          onCancel={onCancel}
          label={label}
        />
      )}
    </Flex>
  </form>
);

export default FormTemplate;
