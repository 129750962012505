import { usePutWorkData } from "@/hooks/usePutWorkData";
import { useWorkDataStore } from "@/store/work.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useCallback } from "react";

export const useInventory = () => {
  const { flushMutate, correctMutate, cancelMutate } = usePutWorkData();

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const work =
    workData?.work as ProductionPlansGet200ResponseRowsInnerWorksInner;

  const workId = work?.id as number;
  const locationCode = work?.locationSetting?.toLocation?.code as string;

  const onFlush = useCallback(() => {
    return flushMutate({
      wmsFlushPutRequest: {
        locationCode: locationCode,
      },
    });
  }, [workData]);

  const onCorrect = useCallback(() => {
    correctMutate({
      equipmentsCorrectionPutRequest: {
        workId: workId,
      },
    });
  }, [workData]);

  const onCancel = useCallback(
    (id: number) => {
      cancelMutate({
        workLogId: id,
      });
    },
    [workData]
  );

  return {
    onFlush,
    onCorrect,
    onCancel,
  };
};
