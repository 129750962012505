import { CalendarTrigger } from "@/components/molecule/calendarTrigger/CalendarTrigger";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import ProductionStatusCard from "@/components/molecule/productionStatusCard/ProductionStatusCard";
import { StatusMultipleChipGroup } from "@/components/molecule/statusMultipleChipGroup/StatusMultipleChipGroup";
import { WorksActionHeader } from "@/components/organism/header/refactor/worksActionHeader/WorksActionHeader";
import PageTemplate from "@/components/template/page/PageTemplate";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useWorksFetch } from "@/fetch/page/useWorksFetch";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { useWorksSearchActions } from "@/hooks/useWorksActions.hook";
import { useWorksSearchState } from "@/hooks/useWorksState.hook";
import { usePaginationStore } from "@/store/pagenation.store";
import { Flex, Pagination, Select } from "@mantine/core";
import { WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Works = Object.assign(
  {},
  {
    Template: PageTemplate,
    CalendarTrigger: CalendarTrigger,
    StatusMultipleChipGroup: StatusMultipleChipGroup,
    WorksActionHeader: WorksActionHeader,
    Table: MantineDataTable,
    ProductionStatusCard: ProductionStatusCard,
    Select: Select,
    Pagination: Pagination,
  }
);

export const WorksPage = () => {
  const navigate = useNavigate();
  const { setWorkId } = useLocalStorage();
  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const equipmentCode = userData?.equipmentCode;
  const { data, Fetcher: WorksFetch } = useWorksFetch({
    equipmentCode: equipmentCode,
  });
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));
  const { dates, value, item, spec } = useWorksSearchState();
  const { setDates, setValue, setItem, setSpec } = useWorksSearchActions();

  const worksData = data?.data.rows as WorksItemGet200ResponseRowsInner[];
  const totalPages = data?.data.totalPages;

  useEffect(() => {
    setPage(1);
  }, [dates, value, item, spec]);

  const onClickMemorize = (id: number | undefined) => {
    setWorkId(id);
  };

  const onClickNavigate = (id: number | undefined) => {
    navigate(`/work/${id}`);
  };

  return (
    <WorksFetch>
      <Works.Template>
        <Flex direction={"column"} gap={"md"} align={"center"}>
          <Works.CalendarTrigger
            size={"1.5rem"}
            dates={dates}
            setDates={setDates}
          />
          <Works.WorksActionHeader
            equipmentCode={equipmentCode}
            value={value}
            spec={spec}
            item={item}
            setValue={setValue}
            setSpec={setSpec}
            setItem={setItem}
          />
          <Flex wrap={"wrap"} gap={"xs"} align={"center"}>
            {worksData?.map((workData) => {
              return (
                <Works.ProductionStatusCard
                  key={workData.id}
                  status={workData.trackingStatus}
                  plannedQuantity={`${workData.targetQuantity} ${workData.item?.unitText}`}
                  equipmentName={workData.equipmentName}
                  itemName={workData.itemName}
                  workDate={dayjs(workData.scheduledAt).format("YYYY-MM-DD")}
                  specification={workData.spec ?? "-"}
                  onClick={() => {
                    onClickNavigate(workData.id);
                    onClickMemorize(workData.id);
                  }}
                />
              );
            })}
          </Flex>
          <Works.Pagination
            total={totalPages || 0}
            value={currentPage}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </Works.Template>
    </WorksFetch>
  );
};
