import { DateRangePicker } from "@/components/molecule/dateRangePicker/DateRangePicker";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { ActionIcon, Flex, Text, TextProps } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";

interface CalendarTriggerProps extends TextProps {
  dates: [Date, Date];
  setDates: (dates: [Date, Date]) => void;
}

export const CalendarTrigger = ({
  dates,
  setDates,
  ...etcProps
}: CalendarTriggerProps) => {
  const { openModal } = useModal();

  const openCalendar = () => {
    openModal(
      <DateRangePicker dates={dates} />,
      null,
      COMMON_TEXT.FORM.CALENDAR,
      true
    ).then((result: [Date, Date]) => {
      if (Array.isArray(result)) setDates(result);
    });
  };

  return (
    <Flex gap={"md"} justify={"center"} align={"center"}>
      <Text fw={"bold"} size={"xl"} c={"white"} {...etcProps}>
        {dayjs(dates[0] ?? undefined).format("YYYY-MM-DD")} ~{" "}
        {dayjs(dates[1] ?? undefined).format("YYYY-MM-DD")}
      </Text>
      <ActionIcon {...etcProps} onClick={openCalendar}>
        <IconCalendar size={etcProps.size} />
      </ActionIcon>
    </Flex>
  );
};
