import { useEquipmentStore } from "@/store/equipment.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { useCallback, useEffect, useRef } from "react";

export interface CounterProps {
  equipmentCounter: string;
  equipmentLimitCounter: string;
  isLimitHit: boolean;
  activeWorkId: number;
}

const useSocket = () => {
  const { socket, initializeSocket, joinRoom, leaveRoom, setData } =
    useSocketStore((state) => ({
      socket: state.socket,
      initializeSocket: state.initializeSocket,
      joinRoom: state.joinRoom,
      leaveRoom: state.leaveRoom,
      setData: state.setData,
    }));

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { equipment } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
  }));

  const { work } = workData;

  const disabledSocket =
    work?.externalCounterSetting === "HANDWRITING" ||
    work?.trackingStatus === "DONE" ||
    work?.trackingStatus === "WAITING";

  const previousRoomRef = useRef<string | null>(null);

  // 소켓 초기화
  useEffect(() => {
    if (!socket) {
      console.log("소켓 초기화 중...");
      initializeSocket();
    } else {
      console.log("소켓이 이미 초기화됨");
    }
  }, [socket, initializeSocket]);

  // 소켓 연결 상태 관리
  useEffect(() => {
    if (!socket) return;

    const handleDisconnect = () => {
      console.log("소켓 연결 끊김, 재연결 시도...");
      initializeSocket();
    };

    const handleReconnect = (attempt: number) => {
      console.log(`소켓 재연결 성공, 시도 횟수: ${attempt}`);
    };

    socket.on("disconnect", handleDisconnect);
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("disconnect", handleDisconnect);
      socket.off("reconnect", handleReconnect);
    };
  }, [socket, initializeSocket]);

  // 소켓 룸 관리 및 리스너 설정
  useEffect(() => {
    if (!socket || disabledSocket) {
      console.log("소켓 비활성 상태");
      return;
    }

    // 기존 룸 리스너 제거 및 나가기
    if (previousRoomRef.current) {
      console.log(`이전 룸 리스너 제거: ${previousRoomRef.current}`);
      socket.off(previousRoomRef.current, handleEvent);
      leaveRoom(previousRoomRef.current);
    }

    // 새로운 룸 조인 및 리스너 추가
    if (equipment?.code) {
      const newRoom = `EC_${equipment.code}`;
      console.log(`새 룸 조인 및 리스너 추가: ${newRoom}`);
      joinRoom(newRoom);
      socket.on(newRoom, handleEvent);
      previousRoomRef.current = newRoom;
    } else {
      previousRoomRef.current = null;
    }

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      if (previousRoomRef.current) {
        console.log(`언마운트 시 리스너 제거: ${previousRoomRef.current}`);
        socket.off(previousRoomRef.current, handleEvent);
        leaveRoom(previousRoomRef.current);
        previousRoomRef.current = null;
      }
    };
  }, [socket, equipment?.code, disabledSocket, joinRoom, leaveRoom]);

  // 화면 활성화 시 소켓 상태 확인
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("화면 활성화, 소켓 상태 확인");
        if (!socket || !socket.connected) {
          console.log("소켓 연결 끊김, 재연결 시도...");
          initializeSocket();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [socket, initializeSocket]);

  // 소켓 이벤트 처리
  const handleEvent = useCallback(
    (message: CounterProps) => {
      console.log("소켓 이벤트 발생", message);
      setData(equipment?.code as string, message);
    },
    [setData, equipment?.code]
  );

  // 룸 이벤트 리스너 관리
  useEffect(() => {
    if (!socket || disabledSocket) {
      console.log("이벤트 리스너 비활성 상태");
      return;
    }

    if (previousRoomRef.current) {
      console.log(`이전 룸 리스너 제거: ${previousRoomRef.current}`);
      socket.off(previousRoomRef.current, handleEvent);
    }

    if (previousRoomRef.current) {
      console.log(`새로운 룸 리스너 추가: ${previousRoomRef.current}`);
      socket.on(previousRoomRef.current, handleEvent);
    }

    return () => {
      if (previousRoomRef.current) {
        console.log(`언마운트 시 리스너 제거: ${previousRoomRef.current}`);
        socket.off(previousRoomRef.current, handleEvent);
      }
    };
  }, [socket, previousRoomRef.current, handleEvent, disabledSocket]);
};

export default useSocket;
