import { BaseNumberInput } from "@/components/atom/numberInput/BaseNumberInput";
import { NumberInputProps } from "@mantine/core";

interface FormNumberInputProps extends NumberInputProps {}

export const FormNumberInput = ({ ...etcProps }: FormNumberInputProps) => {
  return (
    <BaseNumberInput
      size="sm"
      styles={{
        input: {
          "font-size": "1.0rem",
        },
      }}
      {...etcProps}
    />
  );
};
