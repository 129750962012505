import { AutoCompleteInputContainer } from "@/components/atom/inputContainer/autoCompleteInputContainer";
import {
  LocationOption,
  useLocationsAutoComplete,
  useLocationsCode,
} from "@/components/organism/autoComplete/location/auto-useGetLocationsQuery";
import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { ReactNode, forwardRef, useEffect, useRef, useState } from "react";

interface LocationProps extends React.ComponentPropsWithoutRef<"div"> {
  // group: string;
  label: string;
  value: string;
}

export interface LocationAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (locationCode: string | null) => void;
  maxDropdownHeight?: number;
  outCode?: string | null;
  query?: any;
  width?: string;
}

export const LocationAutoComplete = (params: LocationAutoCompleteProps) => {
  const {
    value: locationCode,
    onChange,
    maxDropdownHeight,
    width,
    outCode,
    query,
    ...etcParams
  } = params;

  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const { data: options } = useLocationsAutoComplete(
    focused,
    searchKeyword,
    JSON.stringify(query)
  );

  const { data: initialOptions } = useLocationsCode(
    !!locationCode,
    locationCode ?? null
  );

  const selectRef = useRef<HTMLInputElement>(null); // Select 컴포넌트를 참조하기 위한 ref

  const [localOptions, setLocalOptions] = useState<LocationOption[]>([]);

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  let selectedLocation = initialOptions?.find((item) => {
    return item.value === locationCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedLocation = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, LocationProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <div>
            <Text>{name}</Text>
            <Text fz="xs"> (code: {code})</Text>
          </div>
        </Flex>
      </div>
    )
  );

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === "Enter") {
      const matchingOption = localOptions?.find(
        (item) => item.value === searchKeyword
      );
      if (matchingOption) {
        onChangeHandler(matchingOption.value);
        selectRef.current?.blur(); // Select 컴포넌트의 포커스를 잃음
      }
    }
  };

  return (
    <Select
      size="sm"
      ref={selectRef} // Select 컴포넌트에 ref 연결
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <AutoCompleteInputContainer selectedValue={locationCode ?? ""}>
          {children}
        </AutoCompleteInputContainer>
      )}
      value={locationCode && locationCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: LocationProps[], option: LocationProps) => {
          return unique.some((u) => u.value === option.value) ||
            option.value === outCode
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={LocationInfo({
        location: selectedLocation as LocationOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound="No options"
      {...etcParams}
      onKeyDown={handleKeyDown}
    />
  );
};

const LocationInfo = (params: {
  location?: LocationOption;
  onChange: (locationCode: string | null) => void;
}) => {
  const { location, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return location?.value ? (
    <IconX size="1.5rem" onClick={clearHandler} />
  ) : null;
};
