import { spc } from "@/api/inspect/useSpecificationQuery";
import useFetcher from "@/hooks/useFetcher";
import { useWorkDataStore } from "@/store/work.store";

export const useSpecificationFormFetch = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const { queryKey, queryFn } = spc.get({
    query: { $and: [{ itemCode: { $eq: work?.item?.code } }] },
  });

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
