import { BaseButton } from "@/components/atom/button/BaseButton";
import FormSelect from "@/components/atom/select/form/FormSelect";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { EquipmentAutoComplete } from "@/components/organism/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/components/organism/autoComplete/location/location-autoComplate";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useUserFormFetch } from "@/fetch/form/useUserFormFetch";
import { useUserHandler } from "@/hooks/handler/useUserHandler";
import { SelectItem } from "@mantine/core";
import { useForm } from "@mantine/form";

const User = Object.assign(
  {},
  {
    Template: FormTemplate,
    Button: BaseButton,
    TextInput: FormTextInput,
    Select: FormSelect,
    EquipmentAutoComplete: EquipmentAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
  }
);

interface UserUpdateProps {
  fromLocationCode?: string;
  toLocationCode?: string;
  printerCode?: string;
  equipmentCode?: string;
  userId?: number;
  userName?: string;
  name?: string;
}

export const UserUpdate = ({
  userId,
  userName,
  fromLocationCode,
  toLocationCode,
  printerCode,
  equipmentCode,
  name,
}: UserUpdateProps) => {
  const { closeModal } = useModal();
  const { onSubmit } = useUserHandler();
  const data = useUserFormFetch();
  const printerData = data.data
    ?.filter(
      (user, index, self) =>
        user?.printerCode !== "" &&
        user?.printerCode !== null &&
        index === self.findIndex((t) => t.printerCode === user.printerCode)
    )
    .map((user) => {
      return {
        value: user?.printerCode as string,
        label: user?.printerCode as string,
      };
    });

  const form = useForm({
    initialValues: {
      id: userId, // pk
      name: name, // 이름
      username: userName, // 아이디
      equipmentCode: equipmentCode, //설비코드
      fromLocationCode: fromLocationCode,
      toLocationCode: toLocationCode,
      printerCode: printerCode,
    },
  });

  const handleSubmit = () => {
    onSubmit({
      masterApiUsersUserIdPutRequest: {
        userId: form.values.id as number,
        usersUserIdDeleteRequest: {
          name: form.values.name,
          username: form.values.username,
          equipmentCode: form.values.equipmentCode,
          fromLocationCode: form.values.fromLocationCode,
          toLocationCode: form.values.toLocationCode,
          printerCode: form.values.printerCode,
        },
      },
    });
    closeModal({});
  };

  return (
    <User.Template onSubmit={handleSubmit} onCancel={() => closeModal({})}>
      <User.TextInput
        label={COMMON_TEXT.LABEL.USER_ID}
        defaultValue={form.values?.username}
        disabled
      />
      <User.TextInput
        label={COMMON_TEXT.LABEL.USER_NAME}
        defaultValue={form.values?.name}
        disabled
      />
      <User.EquipmentAutoComplete
        label={COMMON_TEXT.LABEL.EQUIPMENT}
        {...form.getInputProps("equipmentCode")}
      />
      <User.LocationAutoComplete
        label={COMMON_TEXT.LABEL.FROM_LOCATION}
        defaultValue={form?.values?.fromLocationCode}
        {...form.getInputProps("fromLocationCode")}
      />
      <User.LocationAutoComplete
        label={COMMON_TEXT.LABEL.TO_LOCATION}
        defaultValue={form?.values?.toLocationCode}
        {...form.getInputProps("toLocationCode")}
      />
      <User.Select
        size="sm"
        label={COMMON_TEXT.LABEL.PRINTER}
        clearable
        {...form.getInputProps("printerCode")}
        data={(printerData as (string | SelectItem)[]) ?? []}
        withinPortal
      />
    </User.Template>
  );
};
