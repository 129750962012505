import { defects } from "@/api/defects/useDefectsQuery";
import { works } from "@/api/work/useWorkQuery";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import useFetcher from "@/hooks/useFetcher";

import { useQuery } from "@tanstack/react-query";

export const useWorkFetch = () => {
  const { workId } = useLocalStorage();
  const { data: defectsData } = useQuery(defects.find({}));

  const { queryKey, queryFn } = works.detail(
    {
      workId: workId,
    },
    {
      params: {
        defectCodes: defectsData?.data?.map((row) => row.code),
        populate: [
          "summary",
          "item",
          "equipment",
          "routingData",
          "productionPlan",
          "locationSetting",
          "currentLotSummary",
          "currentRoutingOutsourceData",
          "downtimeReasonCode",
        ],
      },
    }
  );

  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    {
      enabled: workId !== undefined && workId !== "undefined" && !!defectsData,
    }
  );

  return { data, Fetcher };
};
