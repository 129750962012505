import { Box, Flex, MantineSize, Text, Title } from "@mantine/core";

interface UserInformBoxProps {
  title: string;
  value: string;
  size?: MantineSize;
  icon?: JSX.Element;
  onClick?: () => void;
}

const UserInformBox = ({
  icon,
  title,
  value,
  size,
  onClick,
}: UserInformBoxProps) => {
  return (
    <Box c={"white"} onClick={onClick}>
      <Flex align={"center"} gap={"xs"} justify={"space-between"}>
        {icon}
        <div>
          <Title align={"right"} order={6}>
            {title}
          </Title>
          <Text align={"right"} size={size}>
            {value}
          </Text>
        </div>
      </Flex>
    </Box>
  );
};

export default UserInformBox;
