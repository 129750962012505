import DefaultInstance, { ProductionInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiWorkLogsFindPostRequest,
  DefaultApiWorkLogsGetRequest,
  ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelCorrectionPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const WORK_LOGS_KEY = "work_logs";

export const workLogs = createQueryKeys(WORK_LOGS_KEY, {
  all: null,
  get: (params: DefaultApiWorkLogsGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.workLogsGet(query),
    };
  },
  find: (params: DefaultApiWorkLogsFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.workLogsFindPost(query),
    };
  },
});

export const workLogsMutate = createMutationKeys(WORK_LOGS_KEY, {
  cancelInput: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest
  ) => ({
    mutationKey: [
      param.workLogId,
      param.workLogsWorkLogIdCancelInputPutRequest,
    ],
    mutationFn: () => ProductionInstance.workLogsWorkLogIdCancelInputPut(param),
  }),
  cancelPerformance: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelPerformancePutRequest
  ) => ({
    mutationKey: [
      param.workLogId,
      param.workLogsWorkLogIdCancelInputPutRequest,
    ],
    mutationFn: () =>
      ProductionInstance.workLogsWorkLogIdCancelPerformancePut(param),
  }),
  cancelDefect: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelDefectPutRequest
  ) => ({
    mutationKey: [param.workLogId],
    mutationFn: () =>
      ProductionInstance.workLogsWorkLogIdCancelDefectPut(param),
  }),
  cancelSplitLot: (
    param: ProductionActionApiProductionPlansWorkLogIdCancelSplitProductLotPostRequest
  ) => ({
    mutationKey: [param.workLogId],
    mutationFn: () =>
      ProductionInstance.productionPlansWorkLogIdCancelSplitProductLotPost(
        param
      ),
  }),

  cancelCorrect: (
    param: ProductionActionApiWorkLogsWorkLogIdCancelCorrectionPutRequest
  ) => ({
    mutationKey: [param.workLogId],
    mutationFn: () =>
      ProductionInstance.workLogsWorkLogIdCancelCorrectionPut(param),
  }),
});

export const workLogsQueryKeys = mergeQueryKeys(workLogs, workLogsMutate);
