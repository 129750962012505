import { BaseButton } from "@/components/atom/button/BaseButton";
import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import { PalletBarcodeForm } from "@/components/organism/form/PalletBarcodeForm";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import {
  getColorOfLogType,
  getKoreanWorkLogType,
} from "@/constants/workLogType";
import { useModal } from "@/context/ModalStackManager";
import { usePalletLogsFormFetch } from "@/fetch/form/usePalletLogsFormFetch";
import { usePaginationStore } from "@/store/pagenation.store";
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Pagination,
  Text,
} from "@mantine/core";
import {
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { MRT_ColumnDef } from "mantine-react-table";
import { useEffect } from "react";

const PalletLogs = Object.assign(
  {},
  {
    Template: FormTemplate,
    Description: FormDescription,
    Table: MantineSelectableTable,
    Pagination: Pagination,
    Button: BaseButton,
  }
);

interface PalletLogsFormProps
  extends Pick<
    WorkLogsGet200ResponseRowsInner,
    | "createdAt"
    | "id"
    | "workLogType"
    | "lotData"
    | "quantity"
    | "creatorUserName"
    | "alreadyCanceledWorkLog"
  > {
  lotName: string | undefined;
}

export const PalletLogsForm = () => {
  const { openModal } = useModal();
  const { data, Fetcher: PalletLogsFormFetcher } = usePalletLogsFormFetch();
  const palletLogs = data?.data.rows;
  const totalPages = data?.data?.totalPages;
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));

  const columns = () =>
    [
      {
        accessorKey: "logNumber",
        header: COMMON_TEXT.COLUMN.LOG_NUMBER,
      },
      {
        accessorKey: "createAt",
        header: COMMON_TEXT.COLUMN.CREATED_AT,
      },
      {
        accessorKey: "type",
        header: COMMON_TEXT.COLUMN.TYPE,
      },
      {
        accessorKey: "lotName",
        header: COMMON_TEXT.COLUMN.LOT_NAME,
      },
      {
        accessorKey: "quantity",
        header: COMMON_TEXT.COLUMN.QUANTITY,
      },
      {
        accessorKey: "userCode",
        header: COMMON_TEXT.COLUMN.WORKER,
      },
      {
        accessorKey: "print",
        header: COMMON_TEXT.COLUMN.PRINT,
        Cell: ({ cell }) => {
          const { alreadyCanceledWorkLog, lotName, quantity } =
            cell.row.original;
          return alreadyCanceledWorkLog ? (
            <ActionIcon sx={{ display: "flex", width: "100%" }}>
              <Text color="purple">{COMMON_TEXT.BUTTON.CANCELLED}</Text>
            </ActionIcon>
          ) : (
            <Button
              onClick={() =>
                handlePrint({
                  lotName: lotName,
                  quantity: quantity,
                })
              }
              variant="outline"
            >
              {COMMON_TEXT.BUTTON.PRINT}
            </Button>
          );
        },
      },
    ] as MRT_ColumnDef<PalletLogsFormProps>[];

  const handlePrint = ({
    lotName,
    quantity,
  }: {
    lotName: string | undefined;
    quantity: string | undefined;
  }) => {
    openModal(
      <PalletBarcodeForm lotName={lotName} quantity={quantity} />,
      "",
      COMMON_TEXT.FORM.PALLET_BARCODE_TITLE
    );
  };

  const convertPalletLogsTableData = palletLogs?.map((log) => {
    const logType = getKoreanWorkLogType(
      log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
    );
    const colorOfLogType = getColorOfLogType(
      log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
    );
    const lotName = log?.lotData?.name ?? "-";
    return {
      logNumber: log.id,
      createAt: dayjs(log?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      type: (
        <Badge size="lg" color={colorOfLogType}>
          {logType}
        </Badge>
      ),
      lotName: lotName ?? "-",
      quantity: log?.quantity ? `${log?.quantity} ${log?.unitText}` : "-",
      userCode: log?.creatorUserName ?? "-",
      alreadyCanceledWorkLog: log?.alreadyCanceledWorkLog,
    };
  });

  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <PalletLogsFormFetcher>
      <PalletLogs.Template isButtonDisabled={true}>
        <PalletLogs.Description>
          {COMMON_TEXT.DESCRIPTION.PALLET_LOGS_DESC}
        </PalletLogs.Description>
        <PalletLogs.Table
          enableRowSelection={false}
          data={convertPalletLogsTableData || []}
          columns={columns()}
        />
        <Flex mt={"sm"} justify={"center"}>
          <PalletLogs.Pagination
            total={totalPages ?? 0}
            value={currentPage}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </PalletLogs.Template>
    </PalletLogsFormFetcher>
  );
};
