import { BaseBox } from "@/components/atom/box/BaseBox";
import { BaseText } from "@/components/atom/text/BaseText";
import { setToLocaleString } from "@/utils/unitMark";
import { BoxProps, MantineColor, MantineSize } from "@mantine/core";

interface QuantityBoxProps extends BoxProps {
  size?: MantineSize;
  color?: MantineColor;
  value: string | undefined;
  title: string;
  direction?: "column" | "row";
}

export const QuantityBox = ({
  size,
  value,
  title,
  color,
  direction = "column",
  ...etcProps
}: QuantityBoxProps) => {
  return (
    <BaseBox
      style={{
        background: "inherit",
      }}
      sx={(theme) => ({
        backgroundColor: theme.colors.gray[9],
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: direction,
      })}
      {...etcProps}
    >
      <BaseText
        sx={{
          whiteSpace: "pre-wrap", // 또는 'pre-line'
        }}
        size={size}
        c={"gray"}
        fw={700}
        align="center"
      >
        {title}
      </BaseText>
      <BaseText size={size} fw={900} c={color} align="center">
        {setToLocaleString(value)}
      </BaseText>
    </BaseBox>
  );
};
