import { COMMON_TEXT } from "@/constants/text";
import { useWorkLotsFetch } from "@/fetch/page/work/useWorkLotsFetch";
import { useWorkDataStore } from "@/store/work.store";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkAuth";
import { customNotification } from "@/utils/notificationShow";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import axios from "axios";

interface useBarcodePrintHandlerProps {
  lotName: string | undefined;
}

export const useBarcodePrintHandler = ({
  lotName,
}: useBarcodePrintHandlerProps) => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { data } = useWorkLotsFetch({
    workData: workData.work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const lot_name = lotName ?? data?.data.name;
  const location_code =
    work?.targetLocationCode ??
    work?.equipment?.targetLocationCode ??
    work?.routingOutsourceData?.to_location_code ??
    work?.routingData?.operation?.toLocationCode;
  const item_code = work?.item?.code;

  const onSubmit = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_INVENTORY}`,
        {
          company_code: process.env.REACT_APP_COMPANY_CODE,
          lot_name: [lot_name],
          printer_code: await getPrinterCodeByUserWithWhoami(),
          quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
          location_code: [location_code],
          item_code: [item_code],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.BARCODE_PRINT_FAIL,
      });
      return false;
    }
  };

  return { onSubmit };
};
