import { BaseChip } from "@/components/atom/chip/BaseChip";
import { COMMON_TEXT } from "@/constants/text";
import { ChipProps } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";

interface StatusChipProps extends ChipProps {
  value: keyof typeof ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;
}

export const StatusChip = ({ value, ...etcProps }: StatusChipProps) => {
  const Status = {
    WAITING: COMMON_TEXT.STATUS.WAITING,
    PAUSED: COMMON_TEXT.STATUS.PAUSED,
    WORKING: COMMON_TEXT.STATUS.WORKING,
    DONE: COMMON_TEXT.STATUS.DONE,
  };

  return (
    <BaseChip
      variant={"filled"}
      radius={"xs"}
      size={"md"}
      value={value}
      {...etcProps}
    >
      {Status[value]}
    </BaseChip>
  );
};
