// src/components/ItemInfo.tsx
import { DataBox } from "@/components/molecule/dataBox/DataBox";
import { COMMON_TEXT } from "@/constants/text";
import { useWorkDataStore } from "@/store/work.store";
import { getWorkTrackingStatus } from "@/utils/tranferWorkStatus";
import { Badge, Flex } from "@mantine/core";

export const ItemInfoBox = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { item, status } = work ?? {};
  const { code, name, spec, width, height, description, depth } = item ?? {};

  return (
    <Flex wrap="wrap" bg={"#212529"} direction={"column"} p={"md"}>
      <Flex>
        <DataBox
          label={COMMON_TEXT.LABEL.CURRENT_WORK_STATUS}
          data={
            <Badge color={getWorkTrackingStatus(work?.trackingStatus).color}>
              {getWorkTrackingStatus(work?.trackingStatus).status}
            </Badge>
          }
        />
        <DataBox
          label={COMMON_TEXT.LABEL.ROUTING_CODE}
          data={work?.routingCode}
        />
        <DataBox
          h={"100%"}
          label={COMMON_TEXT.LABEL.ITEM_CODE}
          data={work?.item?.code ?? "-"}
        />
        <DataBox
          h={"100%"}
          label={COMMON_TEXT.LABEL.ITEM_NAME}
          data={work?.item?.name ?? "-"}
        />
      </Flex>
      <Flex>
        <DataBox label={COMMON_TEXT.LABEL.SPEC} data={spec ?? "-"} />
        <DataBox label={COMMON_TEXT.LABEL.WIDTH} data={width ?? "-"} />
        <DataBox label={COMMON_TEXT.LABEL.HEIGHT} data={height ?? "-"} />
        <DataBox label={COMMON_TEXT.LABEL.DEPTH} data={depth ?? "-"} />
        <DataBox
          label={COMMON_TEXT.LABEL.DESCRIPTION}
          data={description ?? "-"}
        />
      </Flex>
    </Flex>
  );
};
