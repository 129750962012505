import { NavBox } from "@/components/atom/box/nav/NavBox";
import UserInformBox from "@/components/molecule/userInformBox/UserInformBox";
import { UserUpdate } from "@/components/organism/form/UserUpdateForm";
import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { Aside, Flex, Select } from "@mantine/core";
import {
  IconMapPin,
  IconPrinter,
  IconSpacingVertical,
  IconUser,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const AppShellAside = () => {
  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const {
    id,
    username, // 아이디
    name, // 사용자이름
    equipmentName,
    equipmentCode,
    fromLocationCode,
    fromLocationName,
    toLocationCode,
    toLocationName,
    printerCode,
  } = userData ?? {};

  const { openModal } = useModal();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language, setLanguage } = useLocalStorage();

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
      method: "POST",
      body: JSON.stringify({ username: username }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    navigate("/");
    localStorage.clear();
    window.location.reload();
  };

  const changeLanguage = (lang: "ko" | "en") => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const openUserUpdateModal = () => {
    openModal(
      <UserUpdate
        fromLocationCode={fromLocationCode}
        toLocationCode={toLocationCode}
        printerCode={printerCode}
        equipmentCode={equipmentCode}
        userId={id}
        userName={username}
        name={name}
      />,
      "",
      COMMON_TEXT.TITLE.USER
    );
  };

  return (
    <Aside
      p="xs"
      hiddenBreakpoint="sm"
      w={200}
      width={{ sm: 200, lg: 300 }}
      bg={"#272727"}
    >
      <Aside.Section grow>
        <Flex gap={"md"} direction={"column"}>
          <UserInformBox
            title={COMMON_TEXT.TITLE.USER}
            value={name ?? "-"}
            size="xs"
            icon={<IconUser />}
            onClick={openUserUpdateModal} // 여기에 클릭 이벤트를 바인딩
          />
          <UserInformBox
            title={COMMON_TEXT.TITLE.EQUIPMENT}
            value={equipmentName ?? COMMON_TEXT.DATA_EMPTY.NO_EQUIPMENT}
            size="xs"
            icon={<IconSpacingVertical />}
            onClick={openUserUpdateModal} // 여기에 클릭 이벤트를 바인딩
          />
          <UserInformBox
            title={COMMON_TEXT.TITLE.FROM_LOCATION}
            value={fromLocationName ?? COMMON_TEXT.DATA_EMPTY.NO_LOCATION}
            size="xs"
            icon={<IconMapPin />}
            onClick={openUserUpdateModal} // 여기에 클릭 이벤트를 바인딩
          />
          <UserInformBox
            title={COMMON_TEXT.TITLE.TO_LOCATION}
            value={toLocationName ?? COMMON_TEXT.DATA_EMPTY.NO_LOCATION}
            size="xs"
            icon={<IconMapPin />}
            onClick={openUserUpdateModal} // 여기에 클릭 이벤트를 바인딩
          />
          <UserInformBox
            title={COMMON_TEXT.TITLE.PRINTER}
            value={printerCode ?? COMMON_TEXT.DATA_EMPTY.NO_PRINTER}
            size="xs"
            icon={<IconPrinter />}
            onClick={openUserUpdateModal} // 여기에 클릭 이벤트를 바인딩
          />
        </Flex>
      </Aside.Section>
      <Aside.Section>
        <Flex direction={"column"} gap={"xs"}>
          <Select
            value={language}
            size="xs"
            data={[
              { value: "ko", label: COMMON_TEXT.LANGUAGE.KOREAN },
              { value: "en", label: COMMON_TEXT.LANGUAGE.ENGLISH },
            ]}
            onChange={(value: "en" | "ko") => changeLanguage(value ?? "")}
          />
          <NavBox onClick={handleLogout}>{COMMON_TEXT.BUTTON.LOGOUT}</NavBox>
        </Flex>
      </Aside.Section>
    </Aside>
  );
};
