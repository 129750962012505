import { BaseButton } from "@/components/atom/button/BaseButton";
import { ButtonProps, MantineColor } from "@mantine/core";

interface IncrementButtonProps
  extends ButtonProps,
    Partial<React.RefAttributes<HTMLButtonElement>> {
  color?: MantineColor;
  num: string;
  onChange?: (value: number) => void;
  value?: string;
}

export function IncrementButton(props: IncrementButtonProps) {
  const { num, onChange, value } = props;

  const handleClick = () => {
    onChange && onChange(Number(value) + Number(num));
  };

  return (
    <BaseButton
      onClick={handleClick}
      color="green"
      variant="outline"
      {...props}
    >
      +{num}
    </BaseButton>
  );
}
