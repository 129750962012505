import BigNumber from "bignumber.js";

export const plusBigNumber = (
  num1: string | number | BigNumber,
  num2: number | string | BigNumber
): string => {
  const bigNum1 = new BigNumber(num1);
  const bigNum2 = new BigNumber(num2);
  return bigNum1.plus(bigNum2).toString();
};
