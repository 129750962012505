import { inventories } from "@/api/inventories/useInventoriesQuery";
import useFetcher from "@/hooks/useFetcher";
import { usePaginationStore } from "@/store/pagenation.store";
import { DefaultApiInventoriesGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const useInventoryFetch = () => {
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));

  const request: DefaultApiInventoriesGetRequest = {
    populate: ["lot", "item", "location"],
    page: currentPage,
    sort: "-id",
  };
  const { queryKey, queryFn } = inventories.get(request);

  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
