import { HeaderTabs } from "@/components/molecule/headerTabs/HeaderTabs";
import { LogsTabs } from "@/components/molecule/logsTabs/LogsTabs";
import { InventoryInform } from "@/components/organism/work/refactor/InventoryInform";
import { LocationInform } from "@/components/organism/work/refactor/LocationInform";
import { LotInformBox } from "@/components/organism/work/refactor/LotInformBox";
import { TimeTrackInform } from "@/components/organism/work/refactor/TimeTrackInform";
import { BoxTemplate } from "@/components/template/box/BoxTemplate";
import { customFunctions } from "@/config/customFunctions";

const LogsInform = Object.assign(
  {},
  {
    Template: BoxTemplate,
    HeaderTabs: HeaderTabs,
  }
);

export const LogsInformTabs = () => {
  const tabs = [
    {
      label: "생산가능재고",
      value: "first",
      content: <InventoryInform />,
    },
    {
      label: "로케이션",
      value: "second",
      content: <LocationInform />,
    },
    {
      label: "작업추적",
      value: "third",
      content: <TimeTrackInform />,
    },
  ];

  if (customFunctions.ADD_PALLET_LOGS) {
    tabs.unshift({
      label: "로트진행도",
      value: "fourth",
      content: <LotInformBox />,
    });
  }

  return (
    <LogsInform.Template>
      <LogsTabs tabs={tabs} />
    </LogsInform.Template>
  );
};
