import { SpcInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  InspectionsGetRequest,
  SpcApiInspectionsInspectionIdPutRequest,
  SpcApiInspectionsInspectionIdSubmitPostRequest,
  SpcApiInspectionsPostRequest,
  SpcApiSpecificationsGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const INSPECT_KEY = "inspects";

export const inspects = createQueryKeys(INSPECT_KEY, {
  all: null,
  get: (params: SpcApiSpecificationsGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => SpcInstance.inspectionsGet(query),
    };
  },
});

export interface UpdateParams extends SpcApiInspectionsInspectionIdPutRequest {
  inspectionsGetRequest: InspectionsGetRequest & {
    specificationData: any;
    record: any;
  };
}

export const inspectsMutate = createMutationKeys(INSPECT_KEY, {
  create: (params: SpcApiInspectionsPostRequest) => ({
    mutationKey: [params.inspectionsGetRequest],
    mutationFn: () => SpcInstance.inspectionsPost(params),
  }),
  update: (params: UpdateParams) => ({
    mutationKey: [params.inspectionId, params.inspectionsGetRequest],
    mutationFn: () => SpcInstance.inspectionsInspectionIdPut(params),
  }),
  submit: (params: SpcApiInspectionsInspectionIdSubmitPostRequest) => ({
    mutationKey: [
      params.inspectionId,
      params.inspectionsInspectionIdSubmitPostRequest,
    ],
    mutationFn: () => SpcInstance.inspectionsInspectionIdSubmitPost(params),
  }),
});

export const defectQueryKeys = mergeQueryKeys(inspects, inspectsMutate);
