import { COMMON_TEXT } from "@/constants/text";
import { notifications } from "@mantine/notifications";

export const customNotification = {
  error: ({ message }: { message: string }) => {
    notifications.show({
      title: COMMON_TEXT.COMMON.FAIL,
      message: message,
      styles: (theme) => ({
        root: {
          borderColor: theme.colors.red[6],
          "&::before": { backgroundColor: theme.colors.red[6] },
        },

        title: { color: theme.black },
        description: { color: theme.black },
        closeButton: {
          color: theme.black,
          "&:hover": { backgroundColor: theme.colors.red[7] },
        },
      }),
    });
  },
  success: ({ message }: { message: string }) => {
    notifications.show({
      title: COMMON_TEXT.COMMON.SUCCESS,
      message: message,
      styles: (theme) => ({
        root: {
          borderColor: theme.colors.green[6],
          "&::before": { backgroundColor: theme.colors.green[6] },
        },
        title: { color: theme.black },
        description: { color: theme.black },
        closeButton: {
          color: theme.black,
          "&:hover": { backgroundColor: theme.colors.green[7] },
        },
      }),
    });
  },
  information: ({ message }: { message: string }) => {
    notifications.show({
      title: COMMON_TEXT.COMMON.INFORMATION,
      message: message,
      styles: (theme) => ({
        root: {
          borderColor: theme.colors.green[6],
          "&::before": { backgroundColor: theme.colors.blue[6] },
        },
        title: { color: theme.black },
        description: { color: theme.black },
        closeButton: {
          color: theme.black,
          "&:hover": { backgroundColor: theme.colors.blue[7] },
        },
      }),
    });
  },
};
