import { BaseTitle } from "@/components/atom/title/BaseTitle";
import { TitleProps } from "@mantine/core";

export const FormTitle = ({ ...props }: TitleProps) => {
  return (
    <BaseTitle
      order={5}
      mb={"lg"}
      c={"#000000"}
      weight={700}
      lh={"130%"}
      {...props}
    />
  );
};
