import { COMMON_TEXT } from "@/constants/text";
import { Text } from "@mantine/core";
interface InspectCheckSheetProps {
  checkNum: number;
}

export const InspectCheckSheet = ({ checkNum }: InspectCheckSheetProps) => {
  return (
    <Text size="20px" miw="18rem" px="xl" align="center">
      {checkNum === 0
        ? COMMON_TEXT.INSPECT.INITIAL_PRODUCT
        : checkNum === 1
        ? COMMON_TEXT.INSPECT.INTERMEDIATE_PRODUCT
        : checkNum === 2
        ? COMMON_TEXT.INSPECT.FINAL_PRODUCT
        : ""}
    </Text>
  );
};
