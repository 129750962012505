import { BaseButton } from "@/components/atom/button/BaseButton";
import LabelValue from "@/components/molecule/labelValue/LabelValue";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useWorkLotsFetch } from "@/fetch/page/work/useWorkLotsFetch";
import { useBarcodePrintHandler } from "@/hooks/handler/useBarcodePrintHandler";
import { usePalletHandler } from "@/hooks/handler/usePalletHandler";
import { useNotification } from "@/hooks/useNotification";
import { useWorkDataStore } from "@/store/work.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconBarcode } from "@tabler/icons-react";

const PalletBarcode = Object.assign(
  {},
  {
    Template: FormTemplate,
    Button: BaseButton,
    LabelValue: LabelValue,
  }
);

interface PalletBarcodeFormProps {
  lotName?: string;
  quantity?: string;
}

export const PalletBarcodeForm = ({
  lotName,
  quantity,
}: PalletBarcodeFormProps) => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { data } = useWorkLotsFetch({
    workData: work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });
  const lotData = data?.data;
  const { closeModal } = useModal();
  const { onSubmit: onPallet } = usePalletHandler();
  const { onSubmit: onBarcodePrint } = useBarcodePrintHandler({
    lotName: lotName,
  });
  const { notifyPallet } = useNotification();

  const handleBarcodePrint = async () => {
    const result = await onBarcodePrint();
    if (result) {
      onPallet();
      closeModal({});
    }
  };

  const handlePallet = async () => {
    const result = await notifyPallet();
    if (result) {
      onPallet();
    }
    closeModal({});
  };

  return (
    <PalletBarcode.Template
      onSubmit={handlePallet}
      onCancel={() => closeModal({})}
    >
      <PalletBarcode.LabelValue
        size={"xl"}
        label={COMMON_TEXT.LABEL.ITEM_NAME}
        value={work?.item?.name ?? "-"}
      />
      <PalletBarcode.LabelValue
        size={"xl"}
        label={COMMON_TEXT.LABEL.SPEC}
        value={work?.item?.spec ?? "-"}
      />
      <PalletBarcode.LabelValue
        size={"xl"}
        label={COMMON_TEXT.LABEL.TOTAL_QUANTITY}
        value={quantity ?? work?.currentLotSummary?.totalQuantity ?? "-"}
      />
      <PalletBarcode.LabelValue
        size={"xl"}
        label={COMMON_TEXT.LABEL.LOT_NAME}
        value={lotData?.name ?? "-"}
      />
      <PalletBarcode.Button
        rightIcon={<IconBarcode size={"1.5rem"} />}
        onClick={handleBarcodePrint}
      >
        {COMMON_TEXT.BUTTON.BARCODE_PRINT ?? "-"}
      </PalletBarcode.Button>
    </PalletBarcode.Template>
  );
};
