import { BaseButton } from "@/components/atom/button/BaseButton";
import { DecrementButton } from "@/components/atom/button/decrease/DecrementButton";
import { IncrementButton } from "@/components/atom/button/increase/IncrementButton";
import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import StatusProgressBar from "@/components/atom/progress/status/StatusProgressBar";
import { BaseTitle } from "@/components/atom/title/BaseTitle";
import { QuantityBox } from "@/components/molecule/quantityBox/QuantityBox";
import { InputLogsForm } from "@/components/organism/form/InputLogsForm";
import { InspectForm } from "@/components/organism/form/InspectForm";
import { SettingForm } from "@/components/organism/form/SettingForm";
import { WorkLogsForm } from "@/components/organism/form/WorkLogsForm";
import { ButtonConfig } from "@/components/organism/header/refactor/workHeader/WorkHeader";
import { BoxTemplate } from "@/components/template/box/BoxTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useMaterialManagementHandler } from "@/hooks/handler/useMaterialManagementHandler";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useEquipmentStore } from "@/store/equipment.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { ActionIcon, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconDeviceFloppy, IconSettings } from "@tabler/icons-react";
import { useEffect } from "react";

const ProgressInform = Object.assign(
  {},
  {
    Template: BoxTemplate,
    Button: BaseButton,
    Title: BaseTitle,
    Input: FormNumberInput,
    IncrementButton: IncrementButton,
    DecrementButton: DecrementButton,
    Progress: StatusProgressBar,
  }
);

export const ProgressInformBox = () => {
  const { getData, data } = useSocketStore((state) => ({
    getData: state.getData,
    data: state.data,
  }));

  const { equipment } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
  }));

  const { openModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const { handleInputRawMaterial } = useMaterialManagementHandler();

  const { handlePerformanceWithValidation, handlePerformance } =
    usePerformanceHandler();

  const disabled = work?.externalCounterSetting === "COUNTER_AND_AUTO_REPORT";
  const counterData = getData(equipment?.code as string);
  const form = useForm({
    initialValues: {
      end: Number(counterData?.equipmentCounter ?? "0"),
    },
  });

  // 소켓 통신으로 값 업데이트 시 상태와 폼 값 업데이트
  useEffect(() => {
    if (counterData?.equipmentCounter !== undefined) {
      form.setValues({
        end: Number(counterData.equipmentCounter),
      });
    } else {
      form.setValues({
        end: 0,
      });
    }
  }, [counterData?.equipmentCounter]);

  const logButtonConfigs: ButtonConfig[] = [
    {
      buttons: [
        {
          key: "log",
          color: "yellow.6",
          onClick: () =>
            openModal(<InputLogsForm />, "", COMMON_TEXT.FORM.INPUT_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.INPUT_LOGS,
        },
        {
          key: "workLogs",
          color: "cyan.6",

          onClick: () =>
            openModal(<WorkLogsForm />, "", COMMON_TEXT.FORM.WORK_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.WORK_LOGS,
        },
        {
          key: "inspection",
          color: "blue.6",

          onClick: () =>
            openModal(<InspectForm />, "", COMMON_TEXT.FORM.INSPECT_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.RECORD_INSPECT,
        },
        {
          key: "inputAll",
          color: "teal.6",

          onClick: handleInputRawMaterial,
          text: COMMON_TEXT.BUTTON.RAW_MATERIAL_INPUT,
        },
      ],
    },
  ];

  return (
    <ProgressInform.Template>
      <Flex gap={"xs"} justify={"flex-end"} mb={"xl"}>
        {logButtonConfigs.map((config) =>
          config.buttons.map((button) => (
            <ProgressInform.Button
              variant="outline"
              key={button.key}
              onClick={button.onClick}
              color={button.color}
              leftIcon={button.icon}
            >
              {button.text}
            </ProgressInform.Button>
          ))
        )}
      </Flex>
      <Flex gap={"xl"} justify={"space-between"}>
        <Flex direction={"column"} gap={"xl"}>
          <ProgressInform.Progress
            label={Number(work?.summary?.percent).toFixed(2) + "%"}
            value={Number(work?.summary?.percent)}
            animate
          />
          <Flex gap={"xl"}>
            <QuantityBox
              size="lg"
              color="violet.4"
              value={work?.summary?.end}
              title={COMMON_TEXT.TITLE.PRODUCTION_QUANTITY}
            />
            <QuantityBox
              size="lg"
              color="teal.4"
              value={work?.summary?.totalQuantity}
              title={COMMON_TEXT.TITLE.TOTAL_QUANTITY}
            />
            <QuantityBox
              size="lg"
              color="red.4"
              value={work?.summary?.defectTotal}
              title={COMMON_TEXT.TITLE.DEFECT_QUANTITY}
            />
            <QuantityBox
              size="lg"
              color="blue.4"
              value={work?.summary?.todoQuantity}
              title={COMMON_TEXT.TITLE.TODO_QUANTITY}
            />
            <QuantityBox
              size="lg"
              color="white"
              value={`${work?.summary?.totalQuantity}`}
              title={`${COMMON_TEXT.TITLE.TARGET_QUANTITY}`}
            />
          </Flex>
        </Flex>
        <Flex gap={"xs"} direction={"column"}>
          <Flex justify={"space-between"} align={"center"}>
            <ProgressInform.Title c={"#FFFFFF"} size={"xl"}>
              {COMMON_TEXT.TITLE.PRODUCTION_INPUT}
            </ProgressInform.Title>
            <ActionIcon>
              <IconSettings
                onClick={() =>
                  openModal(<SettingForm />, "", COMMON_TEXT.FORM.SETTING_INPUT)
                }
              />
            </ActionIcon>
          </Flex>
          <Flex justify={"space-between"} gap={"xs"}>
            <ProgressInform.Input
              w={"100%"}
              size="sm"
              disabled={disabled}
              {...form.getInputProps("end")}
            />
            <ProgressInform.Button
              size="sm"
              onClick={() => {
                handlePerformanceWithValidation(form.values.end.toString());
                form.setValues({ end: 0 });
              }}
              disabled={disabled}
              type={"submit"}
              rightIcon={<IconDeviceFloppy />}
            >
              {"저장"}
            </ProgressInform.Button>
          </Flex>
          <Flex justify={"space-between"} w={"100%"} mt={"sm"}>
            <Flex gap={"xs"}>
              <ProgressInform.DecrementButton
                size="sm"
                num={"5"}
                disabled={disabled}
                {...form.getInputProps("end")}
              />
              <ProgressInform.DecrementButton
                size="sm"
                num={"1"}
                disabled={disabled}
                {...form.getInputProps("end")}
              />
            </Flex>
            <Flex gap={"xs"}>
              <ProgressInform.IncrementButton
                size="sm"
                num={"1"}
                disabled={disabled}
                {...form.getInputProps("end")}
              />
              <ProgressInform.IncrementButton
                size="sm"
                num={"5"}
                disabled={disabled}
                {...form.getInputProps("end")}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ProgressInform.Template>
  );
};
