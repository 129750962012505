import { COMMON_TEXT } from "@/constants/text";
import { Text, TextProps } from "@mantine/core";

interface PointProps extends TextProps {
  pointNum: number;
  name: string;
}

export const Point = ({ pointNum, name, ...etcProps }: PointProps) => {
  return (
    <Text {...etcProps}>
      {COMMON_TEXT.INSPECT.POINT}
      {pointNum}. {name}
    </Text>
  );
};
