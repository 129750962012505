import DefaultInstance from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiWorksFindPostRequest,
  DefaultApiWorksGetRequest,
  DefaultApiWorksItemGetRequest,
  DefaultApiWorksWorkIdDeleteRequest,
  DefaultApiWorksWorkIdGetRequest,
  DefaultApiWorksWorkIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";

export const WORK_KEY = "works";

export const works = createQueryKeys(WORK_KEY, {
  all: null,
  get: (params: DefaultApiWorksGetRequest | any) => {
    const query = { ...params };

    if (params?.query) {
      const [beforeDate, afterDate] = params.dates;

      const before = dayjs(
        dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
      ).toISOString();

      const after = afterDate
        ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
        : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

      const date = after ? [before, after] : [before, before];

      query.query.$and.push({
        scheduledAt: {
          $between: date,
        },
      });
    }
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.worksGet(query),
    };
  },
  itemWork: (params: DefaultApiWorksItemGetRequest | any) => {
    const query = { ...params };

    if (params?.query) {
      const [beforeDate, afterDate] = params.dates;

      const before = dayjs(
        dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
      ).toISOString();

      const after = afterDate
        ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
        : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

      const date = after ? [before, after] : [before, before];

      query.query.$and.push({
        scheduledAt: {
          $between: date,
        },
      });
    }
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.worksItemGet(query),
    };
  },
  itemWorkFind: (params: DefaultApiWorksItemGetRequest | any) => {
    const query = { ...params };

    if (params?.query) {
      const [beforeDate, afterDate] = params.dates;

      const before = dayjs(
        dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
      ).toISOString();

      const after = afterDate
        ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
        : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

      const date = after ? [before, after] : [before, before];

      query.query.$and.push({
        scheduledAt: {
          $between: date,
        },
      });
    }
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.worksItemFindPost(query),
    };
  },
  find: (params: DefaultApiWorksFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.worksFindPost(query),
    };
  },
  detail: (
    params: DefaultApiWorksWorkIdGetRequest,
    options?: AxiosRequestConfig
  ) => {
    return {
      queryKey: [params.workId],
      queryFn: () => DefaultInstance.worksWorkIdGet(params, options),
    };
  },
});

export const mutateWorks = createMutationKeys("works", {
  update: (params: DefaultApiWorksWorkIdPutRequest) => {
    return {
      mutationKey: [params.workId, params.worksWorkIdDeleteRequest],
      mutationFn: () => DefaultInstance.worksWorkIdPut(params),
    };
  },
  delete: (params: DefaultApiWorksWorkIdDeleteRequest) => {
    return {
      mutationKey: [params.workId],
      mutationFn: () => DefaultInstance.worksWorkIdDelete(params),
    };
  },
});
