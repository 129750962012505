import { create, StateCreator } from "zustand";

export type Equipment = {
  name: string;
  code: string;
};

export type EquipmentState = {
  Equipment: Equipment | null;
};

export interface EquipmentActions {
  setEquipment: (equipment: Equipment | null) => void;
}

export type EquipmentPersist = (
  config: StateCreator<EquipmentState>
) => StateCreator<EquipmentState>;
export interface EquipmentStore extends EquipmentState, EquipmentActions {}

const useEquipmentStore = create<EquipmentStore>()((set) => ({
  Equipment: null,
  setEquipment: (equipment: Equipment | null) => {
    set({ Equipment: equipment });
  },
}));

export { useEquipmentStore };
