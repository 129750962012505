import { create } from "zustand";

export type PaginationState = {
    currentPage: number;
};

export interface PaginationActions {
    setPage: (page: number) => void;
}

export type PaginationStore = PaginationState & PaginationActions;

export const usePaginationStore = create<PaginationStore>()((set) => ({
    currentPage: 1,
    setPage: (page: number) => {
        set({ currentPage: page });
    },
}));
