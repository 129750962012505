import { UserInstance } from "@/instance/axios";
import { createMutationKeys } from "@lukemorales/query-key-factory";
import { UserApiAuthLoginPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const AUTH_KEY = "auth";

export const authMutate = createMutationKeys(AUTH_KEY, {
  login: (params: UserApiAuthLoginPostRequest) => ({
    mutationKey: [params.authLoginPostRequest],
    mutationFn: () => UserInstance.authLoginPost(params),
  }),
});
