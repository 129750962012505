import {
  INVENTORIES_KEY,
  mutateInventories,
} from "@/api/inventories/useInventoriesQuery";
import { WORK_LOGS_KEY } from "@/api/workLogs/useWorkLogsQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import {
  ProductionActionApiWorksBulkInputPutRequest,
  WorksBulkInputPutRequestWorkLotPairsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useRawMaterialAllHandler = () => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const { mutate: InventoriesAllMutate } = useMutation(
    (params: ProductionActionApiWorksBulkInputPutRequest) =>
      mutateInventories.workInputAll(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.RAW_MATERIAL_INPUT_SUCCESS,
        });
        closeModal();
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.RAW_MATERIAL_INPUT_FAIL,
        });
      },
    }
  );

  const onSubmit = ({
    workLotPairs,
  }: {
    workLotPairs: WorksBulkInputPutRequestWorkLotPairsInner[];
  }) => {
    InventoriesAllMutate({ worksBulkInputPutRequest: { workLotPairs } });
  };

  return {
    onSubmit,
  };
};
