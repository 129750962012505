import { useInventoriesFetch } from "@/fetch/page/work/useInventoriesFetch";
import { useInventory } from "@/hooks/useInventory";
import { useNotification } from "@/hooks/useNotification";
import { useWorkDataStore } from "@/store/work.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

export const useInventoryHandler = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { data } = useInventoriesFetch({
    workData: workData.work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const inventories = data?.data;
  const { work } = workData;
  const { onFlush, onCorrect, onCancel } = useInventory();

  const { notifyFlushModal } = useNotification();

  const isWaitingStatus = work?.trackingStatus === "WAITING";
  const isInventory = (inventories?.length as number) > 0;
  const isFirstPerformance = work?.summary?.end === "0";
  const isFlush = isFirstPerformance && isInventory && isWaitingStatus;

  // 초기화(플러싱) 여부를 확인하는 함수
  const handleFlush = async () => {
    if (isFlush) {
      const confirmFlush = await notifyFlushModal({
        workInventoriesData: inventories,
      });
      if (confirmFlush) {
        onFlush();
      }
    }
    return false;
  };

  const handleCorrect = () => {
    onCorrect();
  };

  const handleCancel = (id: number) => {
    onCancel(id);
  };

  return {
    handleFlush,
    handleCorrect,
    handleCancel,
  };
};
