import { TableActionBox } from "@/components/molecule/tableActionBox/TableActionBox";
import { COMMON_TEXT } from "@/constants/text";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useState } from "react";

interface MantineSelectableTableProps<TData extends Record<string, any>>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  onRowSelection?: (selectedRows: TData[]) => void; // 선택된 로우 변경 시 호출되는 핸들러
  onClickDelete?: (data: any) => void; // 삭제 버튼 클릭 시 호출되는 핸들러
  onClickCancel?: (data: any) => void; // 취소 버튼 클릭 시 호출되는 핸들러
}

export const MantineSelectableTable = <TData extends Record<string, any>>({
  columns,
  data,
  onRowSelection,
  ...props
}: MantineSelectableTableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  useEffect(() => {
    if (onRowSelection) {
      const selectedRows = Object.keys(rowSelection)
        .filter((key) => rowSelection[key]) // 선택된 로우만 필터링
        .map((key) => data[Number(key)]);
      onRowSelection(selectedRows);
    }
  }, [rowSelection]);

  const table = useMantineReactTable<TData>({
    columns,
    data: data,
    enableRowSelection: true,
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    enableRowActions: false,
    positionActionsColumn: "last",
    mantineTableContainerProps: { sx: { maxHeight: "15rem" } },
    mantineTableBodyRowProps: ({ row }) => ({
      //add onClick to row to select upon clicking anywhere in the row
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    localization: {
      noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_DATA,
    },
    renderRowActions: ({ row }) => {
      return <TableActionBox row={row} {...props} />;
    },
    state: {
      rowSelection,
    },
    ...props,
    defaultColumn: {
      size: 30,
    },
  });

  return <MantineReactTable table={table} />;
};
