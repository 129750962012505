import { authMutate } from "@/api/auth/useAuthLoginQuery";
import { COMMON_TEXT } from "@/constants/text";
import { customNotification } from "@/utils/notificationShow";
import { UserApiAuthLoginPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const authEnum = {
  authToken: "authToken",
};

export const useLoginHandler = () => {
  const { mutate: loginMutate } = useMutation(
    (params: UserApiAuthLoginPostRequest) =>
      authMutate.login(params).mutationFn(undefined),
    {
      onSuccess: (data) => {
        const authToken = data.data?.token;
        localStorage.setItem(authEnum.authToken, authToken);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data.message ??
            COMMON_TEXT.NOTIFICATION.LOGIN_FAIL,
        });
      },
    }
  );

  const onSubmit = ({ id, password }: { id: string; password: string }) => {
    loginMutate({
      authLoginPostRequest: {
        identifier: id,
        password: password,
      },
    });
  };

  return {
    onSubmit,
  };
};
