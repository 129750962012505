import { BaseBox } from "@/components/atom/box/BaseBox";
import { BaseText } from "@/components/atom/text/BaseText";
import { BoxProps } from "@mantine/core";
import React from "react";

interface DataBoxProps extends BoxProps {
  label: string;
  data: string | React.ReactNode;
}

export const DataBox = ({ label, data, ...etcProps }: DataBoxProps) => {
  return (
    <BaseBox
      w={"100%"}
      sx={(theme) => ({
        paddingBottom: theme.spacing.sm,
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.gray[9],
        cursor: "pointer",
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        flexDirection: "column",
      })}
      {...etcProps}
    >
      <BaseText fz={"md"} c={"gray"} fw={700}>
        {label}
      </BaseText>
      <BaseText fz={"sm"} fw={900} c={"white"}>
        {data}
      </BaseText>
    </BaseBox>
  );
};
