import { BaseButton } from "@/components/atom/button/BaseButton";
import { DecrementButton } from "@/components/atom/button/decrease/DecrementButton";
import { IncrementButton } from "@/components/atom/button/increase/IncrementButton";
import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { SettingForm } from "@/components/organism/form/SettingForm";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useEquipmentStore } from "@/store/equipment.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { ActionIcon, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconDeviceFloppy, IconSettings } from "@tabler/icons-react";
import { useEffect, useRef } from "react";

const WorkEndReport = Object.assign(
  {},
  {
    Title: WorkTitle,
    Button: BaseButton,
    Input: FormNumberInput,
    IncrementButton: IncrementButton,
    DecrementButton: DecrementButton,
  }
);

const COUNTER_AND_HANDWRITING =
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_HANDWRITING;
const COUNTER_AND_AUTO_REPORT =
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_AUTO_REPORT;

export const WorkEndReportActions = () => {
  const { getData, data } = useSocketStore((state) => ({
    getData: state.getData,
    data: state.data,
  }));

  const prevCounterRef = useRef<string | null>(null);

  const { handlePerformanceWithValidation, handlePerformance } =
    usePerformanceHandler();

  const { openModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { equipment } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
  }));

  const counterData = getData(equipment?.code as string);
  const disabled = work?.externalCounterSetting === "COUNTER_AND_AUTO_REPORT";

  const form = useForm({
    initialValues: {
      end: Number(counterData?.equipmentCounter ?? "0"),
    },
  });

  // 소켓 통신으로 값 업데이트 시 상태와 폼 값 업데이트
  useEffect(() => {
    if (counterData?.equipmentCounter !== undefined) {
      form.setValues({
        end: Number(counterData.equipmentCounter),
      });
    } else {
      form.setValues({
        end: 0,
      });
    }
  }, [counterData?.equipmentCounter]);

  // 실적 저장 + 자동보고 일때 사용
  useEffect(() => {
    if (counterData?.equipmentCounter !== undefined) {
      const currentCounter = counterData.equipmentCounter;

      // 이전 값이 존재하고, 현재 값이 이전 값보다 클 때만 처리
      if (
        prevCounterRef.current !== null &&
        Number(currentCounter) > Number(prevCounterRef.current)
      ) {
        const externalCounterSetting =
          work?.externalCounterSetting ?? COUNTER_AND_HANDWRITING;

        const isCounterAndTemSaveActive =
          externalCounterSetting === COUNTER_AND_AUTO_REPORT &&
          (work?.trackingStatus === "WORKING" ||
            work?.trackingStatus === "PAUSED");

        if (isCounterAndTemSaveActive) {
          handlePerformance(
            customFunctions.ADD_ACCUMULATION_QUANTITY
              ? {
                  accQuantity: currentCounter,
                  warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY,
                }
              : {
                  quantity: currentCounter,
                  warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY,
                }
          );
        }
      }

      // 현재 counter 값을 이전 값으로 업데이트
      prevCounterRef.current = currentCounter;
    }
  }, [
    counterData?.equipmentCounter,
    work?.externalCounterSetting,
    work?.trackingStatus,
  ]);

  return (
    <>
      <Flex w={"100%"} justify={"space-between"} mt={"lg"}>
        <Flex gap={10} align={"center"}>
          <WorkEndReport.Title c={"#FFFFFF"} order={5}>
            {COMMON_TEXT.TITLE.PRODUCTION_INPUT}
          </WorkEndReport.Title>
          <ActionIcon>
            <IconSettings
              onClick={() =>
                openModal(<SettingForm />, "", COMMON_TEXT.FORM.SETTING_INPUT)
              }
            />
          </ActionIcon>
        </Flex>
        <WorkEndReport.Button
          size="sm"
          onClick={() => {
            handlePerformanceWithValidation(form.values.end.toString());
            form.setValues({ end: 0 });
          }}
          disabled={disabled}
          type={"submit"}
          rightIcon={<IconDeviceFloppy />}
        >
          {COMMON_TEXT.BUTTON.PRODUCTION_SAVE}
        </WorkEndReport.Button>
      </Flex>
      <Flex gap={10} w={"100%"} mt={"sm"}>
        <WorkEndReport.DecrementButton
          size="sm"
          num={"5"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.DecrementButton
          size="sm"
          num={"1"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.Input
          w={"100%"}
          size="sm"
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.IncrementButton
          size="sm"
          num={"1"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.IncrementButton
          size="sm"
          num={"5"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
      </Flex>
    </>
  );
};
