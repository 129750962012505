import { users } from "@/api/user/useUserQuery";
import useFetcher from "@/hooks/useFetcher";

export const useUserFormFetch = () => {
  const { queryKey, queryFn } = users.getSelect({});

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
