// src/hooks/useConfirmModal.js
import { NotificationForm } from "@/components/organism/form/NotificationForm";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useWorkDataStore } from "@/store/work.store";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import BigNumber from "bignumber.js";

export const useNotification = () => {
  const { openModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const commonForm = async ({
    message,
    title,
  }: {
    message: string | JSX.Element;
    title: string;
  }) => {
    return await openModal(
      <NotificationForm message={message} />,
      "",
      title,
      false
    );
  };

  const notifyPreviousOrderClosureModal = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.PREV_ORDER_CLOSURE_DESC,
      title: COMMON_TEXT.TITLE.WORK_CLOSE,
    });
  };

  const notifyProductionRecordEnteredModal = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.PROD_RECORD_ENTERED_DESC,
      title: COMMON_TEXT.TITLE.PROD_INPUT,
    });
  };

  const notifyOutOfStockModal = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.INVENTORY_LACK_DESC,
      title: COMMON_TEXT.TITLE.OUT_OF_STOCK,
    });
  };

  const notifyPreviousMaterialUsedModal = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.PREV_MATERIAL_USED_DESC,
      title: COMMON_TEXT.TITLE.INVENTORY_AVAILABLE,
    });
  };

  const notifyWorkClosureModal = async () => {
    const message =
      BigNumber(work?.targetQuantity as string).isGreaterThan(
        work?.summary?.totalQuantity as string
      ) && customFunctions.END_QUANTITY_MISS_MATCH
        ? COMMON_TEXT.DESCRIPTION.END_QUANTITY_MISS_MATCH
        : COMMON_TEXT.DESCRIPTION.WORK_CLOSURE_DESC;

    return await commonForm({
      message,
      title: COMMON_TEXT.TITLE.WORK_CLOSE,
    });
  };

  const notifyInputCancellationModal = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.CANCEL_INPUT_DESC,
      title: COMMON_TEXT.TITLE.INPUT_CANCEL,
    });
  };

  const notifyLimitHitModal = async ({
    unitText,
    equipmentLimitCounter,
    defectTotal,
  }: {
    unitText: string | undefined;
    equipmentLimitCounter: string | undefined;
    defectTotal?: string;
  }) => {
    const limitHitMessage = (
      <div>
        <div>{COMMON_TEXT.DESCRIPTION.LIMIT_HIT_DESC}</div>
        <div>
          {COMMON_TEXT.TITLE.PRODUCTION_QUANTITY} : {equipmentLimitCounter} (
          {unitText})
        </div>
        {customFunctions.ADD_TEMP_FUNCTION_ENABLED &&
          (defectTotal ? (
            <div>
              {COMMON_TEXT.TITLE.TOTAL_DEFECT_QUANTITY} : {defectTotal} (
              {unitText})
            </div>
          ) : undefined)}
      </div>
    );

    return await commonForm({
      message: limitHitMessage,
      title: COMMON_TEXT.TITLE.LIMIT_HIT,
    });
  };

  const notifyFlushModal = async ({
    workInventoriesData,
  }: {
    workInventoriesData:
      | ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
      | undefined;
  }) => {
    const message = workInventoriesData
      ?.map(
        (row) =>
          `${row.itemName}(${row.itemCode}) 재고가 ${row.quantity} ${row.unitText} 남아 있습니다. 초기화 하시겠습니까?`
      )
      .join("\n") as string;

    return await commonForm({
      message,
      title: COMMON_TEXT.TITLE.FLUSH,
    });
  };

  const notifyPallet = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.PALLET_SPLIT_DESC,
      title: COMMON_TEXT.TITLE.PALLET_SEPARATE,
    });
  };

  const notifyCancelEnd = async () => {
    return await commonForm({
      message: COMMON_TEXT.DESCRIPTION.CANCEL_END_DESC,
      title: COMMON_TEXT.TITLE.CANCEL_END,
    });
  };

  return {
    notifyPreviousOrderClosureModal,
    notifyProductionRecordEnteredModal,
    notifyOutOfStockModal,
    notifyPreviousMaterialUsedModal,
    notifyWorkClosureModal,
    notifyInputCancellationModal,
    notifyLimitHitModal,
    notifyFlushModal,
    notifyPallet,
    notifyCancelEnd,
  };
};
