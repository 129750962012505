import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import PageTemplate from "@/components/template/page/PageTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useInspectRecordsFetch } from "@/fetch/page/useInpectRecordsFetch";
import { usePaginationStore } from "@/store/pagenation.store";
import { Flex, Pagination } from "@mantine/core";
import dayjs from "dayjs";
import { MRT_ColumnDef } from "mantine-react-table";
import { useEffect, useMemo } from "react";

const InspectRecords = Object.assign(
  {},
  {
    Template: PageTemplate,
    Table: MantineDataTable,
    Pagination: Pagination,
  }
);

export const InspectRecordsPage = () => {
  const { data, Fetcher: InspectRecordsFetcher } = useInspectRecordsFetch();
  const InspectRecordsData = data?.data.rows;
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));
  const totalPages = data?.data.totalPages;

  const convertedInspectRecordsData = InspectRecordsData?.map((data) => {
    const summaryNames = data.specificationData?.criteria?.map(
      (data) => data.name
    );
    const summaryLength = summaryNames?.length as number;

    return {
      lotName: data.lot?.name,
      itemName: data.itemCode,
      inspectionAt: data.inspectionAt
        ? dayjs(data.inspectionAt).format("YYYY-MM-DD hh:mm:ss")
        : "-",
      summary:
        summaryLength > 1
          ? summaryNames?.[0] + "외 " + (summaryLength - 1) + "개"
          : summaryNames?.[0],
      passOrNot: data.isDone
        ? data.isPassed
          ? COMMON_TEXT.INSPECT.PASS
          : COMMON_TEXT.INSPECT.FAIL
        : COMMON_TEXT.INSPECT.INSPECTING,
    };
  });

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "lotName",
        header: COMMON_TEXT.COLUMN.LOT_NAME,
      },
      {
        accessorKey: "itemName",
        header: COMMON_TEXT.COLUMN.ITEM_NAME,
      },
      {
        accessorKey: "inspectionAt",
        header: COMMON_TEXT.COLUMN.INSPECTION_AT,
      },
      {
        accessorKey: "summary",
        header: COMMON_TEXT.COLUMN.INSPECT_SUMMARY,
      },
      {
        accessorKey: "passOrNot",
        header: COMMON_TEXT.COLUMN.PASS_OR_NOT,
      },
    ],
    []
  );

  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <InspectRecordsFetcher>
      <InspectRecords.Template>
        <InspectRecords.Table
          columns={columns}
          data={convertedInspectRecordsData ?? []}
        />
        <Flex mt={20} justify={"center"}>
          <InspectRecords.Pagination
            total={totalPages || 0}
            value={currentPage}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </InspectRecords.Template>
    </InspectRecordsFetcher>
  );
};
