import { MasterInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  AuthSignupPost201Response,
  MasterApiUsersFindPostRequest,
  MasterApiUsersGetRequest,
  MasterApiUsersUserIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

export const GET_USERS_QUERY_KEY = "users";

export const users = createQueryKeys(GET_USERS_QUERY_KEY, {
  all: null,
  get: (params: MasterApiUsersGetRequest) => {
    return {
      queryKey: [params],
      queryFn: () => MasterInstance.usersGet(params),
    };
  },
  getSelect: (params: MasterApiUsersFindPostRequest) => {
    return {
      queryKey: [params],
      queryFn: () =>
        MasterInstance.usersFindPost(params).then(
          (res: AxiosResponse<AuthSignupPost201Response[], any>) => res.data
        ),
    };
  },
});

export const usersMutate = createMutationKeys(GET_USERS_QUERY_KEY, {
  update: (params: MasterApiUsersUserIdPutRequest) => {
    return {
      mutationKey: [params.userId, params.usersUserIdDeleteRequest],
      mutationFn: () => MasterInstance.usersUserIdPut(params),
    };
  },
});

export const userQueryKeys = mergeQueryKeys(users, usersMutate);
