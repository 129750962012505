import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { FormTitle } from "@/components/atom/title/form/FormTitle";
import { DowntimeReasonsAutoComplete } from "@/components/organism/autoComplete/downtime/downtimeReasons-autoComplete";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useWorkDataStore } from "@/store/work.store";
import { Flex } from "@mantine/core";
import { useState } from "react";

const DownTimeReasons = Object.assign(
  {},
  {
    Template: FormTemplate,
    Title: FormTitle,
    SubTitle: FormSubTitle,
    DowntimeReasonsAutoComplete: DowntimeReasonsAutoComplete,
  }
);

interface DownTimeReasonsFormProps {
  onClick: (downtimeReasonCode: string) => void;
}

export const DownTimeReasonsForm = ({ onClick }: DownTimeReasonsFormProps) => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const [downtimeReasonCode, setDowntimeReasonCode] = useState<string>("");

  const handleClick = () => {
    onClick(downtimeReasonCode);
  };

  return (
    <DownTimeReasons.Template onSubmit={handleClick} label={"저장"}>
      <DownTimeReasons.SubTitle>
        {COMMON_TEXT.FORM.DOWNTIME_REASONS}
      </DownTimeReasons.SubTitle>
      <Flex align={"center"} gap={8}>
        <DownTimeReasons.DowntimeReasonsAutoComplete
          w={"100%"}
          value={downtimeReasonCode}
          operationCode={work?.routingData?.operationCode}
          onChange={(e) => setDowntimeReasonCode(e ?? "")}
          downtimeReasonCode={work?.equipment?.downtimeReasonCode}
        />
      </Flex>
    </DownTimeReasons.Template>
  );
};
