import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import FormTemplate from "@/components/template/form/FormTemplate";
import { useWorkDataStore } from "@/store/work.store";
import { Flex } from "@mantine/core";

const ItemInform = Object.assign(
  {},
  {
    Template: FormTemplate,
    Subtitle: FormSubTitle,
  }
);

export const ItemInformForm = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  // spec : 규격
  // height : 높이
  // depth : 깊이
  // texture : 재질
  // description : 비고
  const { spec, height, depth, texture, description } = work?.item ?? {};

  return (
    <ItemInform.Template isButtonDisabled>
      <Flex align={"center"} gap={8}>
        <Flex gap={8}>
          <span>규격</span>
          <span>{spec ?? "-"}</span>
        </Flex>
        <Flex gap={8}>
          <span>높이</span>
          <span>{height ?? "-"}</span>
        </Flex>
        <Flex gap={8}>
          <span>깊이</span>
          <span>{depth ?? "-"}</span>
        </Flex>
        <Flex gap={8}>
          <span>재질</span>
          <span>{texture ?? "-"}</span>
        </Flex>
        <Flex gap={8}>
          <span>비고</span>
          <span>{description ?? "-"}</span>
        </Flex>
      </Flex>
    </ItemInform.Template>
  );
};
