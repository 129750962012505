import { StatusChip } from "@/components/atom/chip/status/StatusChip";
import { Chip, Group } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";

interface StatusMultipleChipGroupProps {
  chipStatus: (keyof typeof ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum)[];
  value: string[] | undefined;
  onChange?: (value: string[]) => void;
}

export const StatusMultipleChipGroup = ({
  chipStatus,
  value,
  onChange,
}: StatusMultipleChipGroupProps) => {
  return (
    <Chip.Group multiple value={value} onChange={onChange}>
      <Group noWrap>
        {chipStatus?.map((status) => {
          return (
            <StatusChip key={status} value={status}>
              {status}
            </StatusChip>
          );
        })}
      </Group>
    </Chip.Group>
  );
};
