import React, { createContext, useContext, useEffect, useState } from "react";

// LocalStorageContext를 생성합니다.
const LocalStorageContext = createContext<{
  workId?: any;
  tempWork?: any;
  isAuthenticated?: string;
  language?: "ko" | "en";
  setLanguage: (value: "ko" | "en") => void;
  setWorkId: (value: any) => void;
  setTempWork: (value: any) => void;
  setIsAuthenticated: (value: boolean) => void;
}>({
  workId: null,
  tempWork: null,
  isAuthenticated: "",
  language: "ko",
  setLanguage: () => {},
  setWorkId: () => {},
  setTempWork: () => {},
  setIsAuthenticated: () => {},
});

// LocalStorageProvider 컴포넌트를 정의합니다.
export const LocalStorageProvider: React.FC<{
  initialId?: any;
  initAuthenticated?: any;
  children?: any;
}> = ({ initialId, children, initAuthenticated = false }: any) => {
  const [workId, setWorkId] = useState(() => {
    try {
      const item = window.localStorage.getItem("workId");
      return item ? item : initialId;
    } catch (error) {
      return initialId;
    }
  });

  const [tempWork, setTempWork] = useState(() => {
    try {
      const item = window.localStorage.getItem(`tempWork_${workId}`);
      return item
        ? JSON.parse(item)
        : {
            defects: undefined,
            end: undefined,
          };
    } catch (error) {
      return {
        defects: undefined,
        end: undefined,
      };
    }
  });

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    try {
      const item = window.localStorage.getItem("isAuthenticated");
      return item ? item : initAuthenticated;
    } catch (error) {
      return initAuthenticated;
    }
  });

  const [language, setLanguage] = useState<"ko" | "en">(() => {
    try {
      const item = window.localStorage.getItem("language");
      return item ? (item as "ko" | "en") : "ko";
    } catch (error) {
      return "ko";
    }
  });

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(`tempWork_${workId}`);
      const newTempWork = item
        ? JSON.parse(item)
        : {
            defects: undefined,
            end: undefined,
          };
      setTempWork(newTempWork);
    } catch (error) {
      setTempWork({
        defects: undefined,
        end: undefined,
      });
    }
  }, [workId]);

  useEffect(() => {
    if (tempWork !== undefined) {
      window.localStorage.setItem(
        `tempWork_${workId}`,
        JSON.stringify(tempWork)
      );
    }
  }, [tempWork]);

  useEffect(() => {
    window.localStorage.setItem("workId", workId);
    window.localStorage.setItem("isAuthenticated", isAuthenticated);
  }, [workId, isAuthenticated]);

  return (
    <LocalStorageContext.Provider
      value={{
        workId,
        tempWork,
        isAuthenticated,
        language,
        setWorkId,
        setTempWork,
        setIsAuthenticated,
        setLanguage,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

// useLocalStorage 훅을 정의합니다.
export const useLocalStorage = () => {
  return useContext(LocalStorageContext);
};
