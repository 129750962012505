import { usePutWorkData } from "@/hooks/usePutWorkData";
import { useWorkDataStore } from "@/store/work.store";
import {
  ProductionActionApiWorksWorkIdDefectPutRequest,
  ProductionActionApiWorksWorkIdPerformancePutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksWorkIdDefectPut200Response,
  WorksWorkIdDefectPutRequest,
  WorksWorkIdDeleteRequestExternalCounterSettingEnum,
  WorksWorkIdPerformancePut200Response,
  WorksWorkIdPerformancePutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MutateOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useCallback } from "react";

export const usePerformance = () => {
  const { performanceMutate, defectMutate, settingMutate, defectMutateAsync } =
    usePutWorkData();

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const work =
    workData?.work as ProductionPlansGet200ResponseRowsInnerWorksInner;

  const workId = work?.id as number;
  const locationCode = work?.locationSetting?.toLocation?.code as string;

  const onPerformance = useCallback(
    (
      performanceRequest: WorksWorkIdPerformancePutRequest,
      options?: MutateOptions<
        AxiosResponse<WorksWorkIdPerformancePut200Response, any>,
        unknown,
        ProductionActionApiWorksWorkIdPerformancePutRequest,
        unknown
      >
    ) => {
      return performanceMutate(
        {
          worksWorkIdPerformancePutRequest: performanceRequest,
          workId: workId,
        },
        options
      );
    },
    [workData]
  );

  const onSetting = useCallback(
    (settingType: WorksWorkIdDeleteRequestExternalCounterSettingEnum) => {
      settingMutate({
        workId: workId as number,
        worksWorkIdDeleteRequest: {
          externalCounterSetting: settingType,
        },
      });
    },
    [workData]
  );

  const onDefect = useCallback(
    (
      defectRequest: WorksWorkIdDefectPutRequest,
      options?: MutateOptions<
        AxiosResponse<WorksWorkIdDefectPut200Response, any>,
        unknown,
        ProductionActionApiWorksWorkIdDefectPutRequest,
        unknown
      >
    ) => {
      defectMutate(
        {
          workId: workId as number,
          worksWorkIdDefectPutRequest: defectRequest,
        },
        options
      );
    },
    [workData]
  );

  return {
    onPerformance,
    onDefect,
    onSetting,
    defectMutateAsync,
  };
};
