import { useModal } from "@/context/ModalStackManager";

import { DownTimeReasonsForm } from "@/components/organism/form/DownTimeReasonsForm";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useInventoryHandler } from "@/hooks/handler/useInventoryHandler";
import { useNotification } from "@/hooks/useNotification";
import { useStatus } from "@/hooks/useStatus";
import { useWorkDataStore } from "@/store/work.store";

export const useStatusHandler = () => {
  const { openModal, closeModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const {
    notifyPreviousOrderClosureModal,
    notifyWorkClosureModal,
    notifyCancelEnd,
  } = useNotification();

  const { handleFlush } = useInventoryHandler();

  const { onStart, onResume, onEnd, onPause, onCancelEnd } = useStatus();

  const activeWorkId = work?.equipment?.activeWorkId;
  const isActiveWorkInProgress =
    activeWorkId !== work?.id && activeWorkId !== null;

  // 이전 작업 지시서가 종료를 확인하는 함수
  const handlePreviousOrderClosure = async () => {
    if (isActiveWorkInProgress) {
      const confirmClosure = await notifyPreviousOrderClosureModal();
      if (!confirmClosure) return false;
    }
    return true;
  };

  // 작업시작 이벤트
  const handleStart = async () => {
    const startWork = async () => {
      onStart();

      closeModal({});
    };

    // 이전 작업지시서가 종료 되었는지를 묻는다.
    const PreviousOrderClosure = await handlePreviousOrderClosure();
    if (!PreviousOrderClosure) return;

    // 플러싱 여부를 묻는다.
    if (customFunctions.ADD_FLUSHING) {
      await handleFlush();
    }
    await startWork();
  };

  // 작업재개 이벤트
  const handleResume = async () => {
    const resumeWork = async () => {
      return onResume();
    };

    // 이전 작업지시서가 종료 되었는지를 묻는다.
    const PreviousOrderClosure = await handlePreviousOrderClosure();
    if (PreviousOrderClosure) {
      await resumeWork();
    }
  };

  // 작업종료 이벤트
  const handleEnd = async () => {
    const confirmWorkClosure = await notifyWorkClosureModal();
    if (confirmWorkClosure) {
      return onEnd();
    }
  };

  // 작업일시정지 이벤트
  const handlePause = () => {
    return openModal(
      <DownTimeReasonsForm
        onClick={(reason) => {
          onPause(reason);
          closeModal({});
        }}
      />,
      "",
      COMMON_TEXT.FORM.DOWNTIME_REASONS
    );
  };

  const handleCancelEnd = async () => {
    const result = await notifyCancelEnd();
    if (result) {
      onCancelEnd();
    }
  };

  return {
    handleStart,
    handleResume,
    handleEnd,
    handlePreviousOrderClosure,
    handlePause,
    handleCancelEnd,
  };
};
