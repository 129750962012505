import { InspectSegmentedControl } from "@/components/atom/segmentedControl/Inspect/InspectSegmentedControl";
import { CreatorAndDate } from "@/components/molecule/creatorAndDate/CreatorAndDate";
import { InspectCheckSheet } from "@/components/molecule/inspectCheckSheet/InspectCheckSheet";
import { Flex, SegmentedControlProps } from "@mantine/core";

const InspectCategory = Object.assign(
  {},
  {
    CheckSheet: InspectCheckSheet,
    SegmentedControl: InspectSegmentedControl,
    CreatorAndDate: CreatorAndDate,
  }
);

interface InspectSegmentedCategoryProps extends SegmentedControlProps {
  checkNum: number;
  createdAt: string | undefined;
  creator: string | undefined;
}

export const InspectSegmentedCategory = ({
  checkNum,
  createdAt,
  creator,
  ...etcProps
}: InspectSegmentedCategoryProps) => {
  return (
    <>
      <Flex align={"center"}>
        <InspectCategory.CheckSheet checkNum={checkNum} />
        <InspectCategory.SegmentedControl {...etcProps} />
      </Flex>
      <InspectCategory.CreatorAndDate createdAt={createdAt} creator={creator} />
    </>
  );
};
