import { lots } from "@/api/lots/useLotsQuery";
import useFetcher from "@/hooks/useFetcher";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

interface useWorkLogsFetchProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const useWorkLotsFetch = ({ workData }: useWorkLogsFetchProps) => {
  const { queryKey, queryFn } = lots.detail({
    lotId: workData?.productionPlan?.lastLotId as number,
  });
  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    { enabled: !!workData?.productionPlan?.lastLotId }
  );

  return { data, Fetcher };
};
