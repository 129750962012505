import { COMMON_TEXT } from "@/constants/text";
import { Box, Text } from "@mantine/core";

export const WarningPage = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        whiteSpace: "pre-line",
      }}
    >
      <Text c={"red"} size={"2.5rem"}>
        {COMMON_TEXT.DATA_EMPTY.NO_WORK}
      </Text>
    </Box>
  );
};
