import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import {
  getColorOfLogType,
  getKoreanWorkLogType,
} from "@/constants/workLogType";
import { useWorkLogsFormFetch } from "@/fetch/form/useWorkLogsFormFetch";
import { useWorkLogsHandler } from "@/hooks/handler/useWorkLogsHandler";
import { usePaginationStore } from "@/store/pagenation.store";
import { Badge, Flex, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { t } from "i18next";
import { MRT_Row } from "mantine-react-table";
import { useEffect } from "react";

const WorkLogs = Object.assign(
  {},
  {
    Template: FormTemplate,
    Description: FormDescription,
    Table: MantineSelectableTable,
    Pagination: Pagination,
  }
);

export const WorkLogsForm = () => {
  const { data, Fetcher: WorkLogsFormFetcher } = useWorkLogsFormFetch();
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));
  const { onCancel } = useWorkLogsHandler();
  const workLogs = data?.data;

  const columns = () => [
    {
      accessorKey: "logNumber",
      header: COMMON_TEXT.COLUMN.LOG_NUM,
    },
    {
      accessorKey: "relatedLogNumber",
      header: COMMON_TEXT.COLUMN.RELATED_LOG_NUM,
    },
    {
      accessorKey: "createAt",
      header: COMMON_TEXT.COLUMN.CREATED_AT,
    },
    {
      accessorKey: "type",
      header: COMMON_TEXT.COLUMN.TYPE,
    },
    {
      accessorKey: "lotName",
      header: COMMON_TEXT.COLUMN.LOT_NAME,
    },
    {
      accessorKey: "quantity",
      header: COMMON_TEXT.COLUMN.QUANTITY,
    },
    {
      accessorKey: "userCode",
      header: COMMON_TEXT.COLUMN.WORKER,
    },
  ];

  const convertInputsLogsToTableData =
    workLogs?.rows?.map((log) => {
      const logType = getKoreanWorkLogType(
        log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
      );
      const colorOfLogType = getColorOfLogType(
        log?.workLogType as WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum
      );
      const lotName = ["INPUT", "RECYCLE"].includes(log?.workLogType as string)
        ? log.logData?.incoming?.lotData?.name ?? "-"
        : undefined;

      return {
        logNumber: log?.id ?? "-",
        relatedLogNumber: log.parentWorkLogId ?? "-",
        createAt: dayjs(log?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
        type: (
          <Badge size="lg" color={colorOfLogType}>
            {t(logType)}
          </Badge>
        ),
        lotName: lotName ?? "-",
        quantity: log?.quantity ? `${log?.quantity} ${log.unitText}` : "-",
        userCode: log?.creatorUserName,
        alreadyCanceledWorkLog: log.alreadyCanceledWorkLog,
        workLogId: log.id,
        logType: log.workLogType,
      };
    }) ?? [];

  const onClickCancel = (row: MRT_Row<any>) => {
    const { workLogId, logType } = row.original;
    onCancel({ workLogId, logType });
  };

  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <WorkLogsFormFetcher>
      <WorkLogs.Template isButtonDisabled={true}>
        <WorkLogs.Description>
          {COMMON_TEXT.DESCRIPTION.WORK_LOGS_CANCEL_DESC}
        </WorkLogs.Description>
        <WorkLogs.Table
          onClickCancel={onClickCancel}
          enableRowActions={true}
          enableRowSelection={false}
          data={convertInputsLogsToTableData || []}
          columns={columns()}
        />
        <Flex mt={"sm"} justify={"center"}>
          <WorkLogs.Pagination
            total={workLogs?.totalPages || 0}
            value={currentPage}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </WorkLogs.Template>
    </WorkLogsFormFetcher>
  );
};
