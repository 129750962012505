import { BaseText } from "@/components/atom/text/BaseText";
import { TextProps } from "@mantine/core";
import dayjs from "dayjs";

interface DateTextProps extends TextProps {
  value: string | undefined;
  dateFormat: string;
}

export const DateText = ({ value, dateFormat, ...etcProps }: DateTextProps) => {
  return <BaseText {...etcProps}>{dayjs(value).format(dateFormat)}</BaseText>;
};
