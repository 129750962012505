import ExpandableAccordion from "@/components/atom/accordion/expandable/ExpandableAccordion";
import { OutsourceInformBox } from "@/components/atom/box/outsource/OutSourceInformBox";
import { BaseButton } from "@/components/atom/button/BaseButton";
import StatusProgressBar from "@/components/atom/progress/status/StatusProgressBar";
import { WorkText } from "@/components/atom/text/work/WorkText";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import { COMMON_TEXT } from "@/constants/text";
import { useInventoriesFetch } from "@/fetch/page/work/useInventoriesFetch";
import { useOutsourceFetch } from "@/fetch/page/work/useOutsourceFetch";
import { useInventory } from "@/hooks/useInventory";
import { useWorkDataStore } from "@/store/work.store";
import { setToLocaleString } from "@/utils/unitMark";
import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

const Inventories = Object.assign(
  {},
  {
    Box: OutsourceInformBox,
    Text: WorkText,
    Title: WorkTitle,
    Button: BaseButton,
    Table: MantineDataTable,
    Progress: StatusProgressBar,
    Accordion: ExpandableAccordion,
  }
);

export const InventoryInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { data: inventory, Fetcher: InventoriesFetcher } = useInventoriesFetch({
    workData: work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const { data: outsource, Fetcher: OutSourceFetcher } = useOutsourceFetch({
    workData: work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const { onFlush } = useInventory();

  const inventories = inventory?.data;
  const outsourceData = outsource?.data;

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: COMMON_TEXT.COLUMN.ITEM_CODE,
      },
      {
        accessorKey: "lotName",
        header: COMMON_TEXT.COLUMN.LOT_NAME,
      },
      {
        accessorKey: "lotExpiration",
        header: COMMON_TEXT.COLUMN.LOT_EXPIRATION_DATE,
      },
      {
        accessorKey: "remainQuantity",
        header: COMMON_TEXT.COLUMN.REMAIN_QUANTITY,
      },
    ],
    []
  );

  const convertedInventoryData = inventories?.map((row) => {
    return {
      itemCode: row.itemCode,
      lotName: row.lot?.name ?? "-",
      lotExpiration: row.lot?.expiration
        ? dayjs(row.lot?.expiration).format("YYYY-MM-DD")
        : "-",
      remainQuantity:
        setToLocaleString(row.quantity ?? "") + " " + row.unitText,
    };
  });

  return (
    <InventoriesFetcher>
      <Flex justify={"flex-end"} align={"center"} mb={"sm"}>
        <Inventories.Button size="xs" onClick={onFlush}>
          {"재고 초기화"}
        </Inventories.Button>
      </Flex>
      <Inventories.Table
        maxHeight={200}
        columns={columns}
        data={convertedInventoryData ?? []}
      />
    </InventoriesFetcher>
  );
};
