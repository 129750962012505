import { HeaderActionButton } from "@/components/atom/button/headerAction/HeaderActionButton";
import { WorkText } from "@/components/atom/text/work/WorkText";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { QuantityBox } from "@/components/molecule/quantityBox/QuantityBox";
import { PalletLogsForm } from "@/components/organism/form/PalletLogsForm";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useWorkLotsFetch } from "@/fetch/page/work/useWorkLotsFetch";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useWorkDataStore } from "@/store/work.store";
import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

const LotInform = Object.assign(
  {},
  {
    Button: HeaderActionButton,
    Title: WorkTitle,
    Text: WorkText,
  }
);

export const LotInformBox = () => {
  const { openModal } = useModal();
  const { handlePalletSeparate } = usePerformanceHandler();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { data } = useWorkLotsFetch({
    workData: workData.work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const lotsName = data?.data.name;

  const { work } = workData;

  return (
    <Flex direction={"column"} gap={"xs"}>
      <Flex gap={"xs"} justify={"flex-start"}>
        <LotInform.Button
          size="sm"
          disabled={work?.currentLotSummary?.totalQuantity === "0"}
          onClick={handlePalletSeparate}
          color={"grape.6"}
        >
          {COMMON_TEXT.BUTTON.PALLET_SEPARATE}
        </LotInform.Button>
        <LotInform.Button
          variant="outline"
          size="sm"
          color={"grape.6"}
          onClick={() =>
            openModal(
              <PalletLogsForm />,
              "",
              COMMON_TEXT.FORM.PALLET_LOGS_TITLE
            )
          }
        >
          {COMMON_TEXT.BUTTON.PALLET_LOGS}
        </LotInform.Button>
      </Flex>
      <div>
        <LotInform.Title c={"#FFFFFF"} order={6}>
          {COMMON_TEXT.TITLE.LOT_NUMBER}
        </LotInform.Title>
        <LotInform.Text c={"#FFFFFF"} size={"xl"}>
          {lotsName ? lotsName.split("#").pop() : "-"}
        </LotInform.Text>
      </div>
      <Flex justify={"space-between"} gap={"xs"} w={"100%"}>
        {[
          {
            title: COMMON_TEXT.TITLE.PRODUCTION_QUANTITY,
            color: "violet.4",
            value: work?.currentLotSummary?.end,
          },
          {
            title: COMMON_TEXT.TITLE.TOTAL_QUANTITY,
            color: "teal.4",
            value: work?.currentLotSummary?.totalQuantity,
          },
          {
            title: COMMON_TEXT.TITLE.DEFECT_QUANTITY,
            color: "red.4",
            value: work?.currentLotSummary?.defectTotal,
          },
        ].map((data, index) => (
          <QuantityBox
            size="lg"
            key={index + data.title}
            color={data.color}
            value={data.value}
            title={data.title}
          />
        ))}
      </Flex>
    </Flex>
  );
};
