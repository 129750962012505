import { inspects } from "@/api/inspect/useInspectQuery";
import { useWorkLotsFetch } from "@/fetch/page/work/useWorkLotsFetch";
import useFetcher from "@/hooks/useFetcher";
import { useWorkDataStore } from "@/store/work.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

export const useInspectLogsFormFetch = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const { data: lots } = useWorkLotsFetch({
    workData: work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const lotsId = lots?.data.id;

  const query: any = {
    $and: [
      { itemCode: { $eq: work?.item?.code } },
      {
        productionPlanId: {
          $eq: work?.productionPlanId,
        },
      },
    ],
  };

  if (lotsId) {
    query.$and.push({
      lotId: {
        $eq: lotsId,
      },
    });
  }

  const { queryKey, queryFn } = inspects.get({
    query: query,
    populate: ["user", "lot"],
  });

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
