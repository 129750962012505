import DefaultInstance from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiLotsFindPostRequest,
  DefaultApiLotsGetRequest,
  DefaultApiLotsLotIdDeleteRequest,
  DefaultApiLotsLotIdGetRequest,
  DefaultApiLotsLotIdPutRequest,
  DefaultApiLotsPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

const LOTS_KEY = "lots";

export const lots = createQueryKeys(LOTS_KEY, {
  all: null,
  get: (params: DefaultApiLotsGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.lotsGet(query),
    };
  },
  detail: (params: DefaultApiLotsLotIdGetRequest) => {
    return {
      queryKey: [params.lotId],
      queryFn: () => DefaultInstance.lotsLotIdGet(params),
    };
  },
  find: (params: DefaultApiLotsFindPostRequest) => {
    return {
      queryKey: [params],
      queryFn: () => DefaultInstance.lotsFindPost(params),
    };
  },
});

export const mutateLots = createMutationKeys(LOTS_KEY, {
  create: (params: DefaultApiLotsPostRequest) => {
    return {
      mutationKey: [params.lotsGetRequest],
      mutationFn: () => DefaultInstance.lotsPost(params),
    };
  },
  update: (params: DefaultApiLotsLotIdPutRequest) => {
    return {
      mutationKey: [params.lotId, params.lotsGetRequest],
      mutationFn: () => DefaultInstance.lotsLotIdPut(params),
    };
  },
  delete: (params: DefaultApiLotsLotIdDeleteRequest) => {
    return {
      mutationKey: [params.lotId],
      mutationFn: () => DefaultInstance.lotsLotIdDelete(params),
    };
  },
});

export const lotsQuery = mergeQueryKeys(lots, mutateLots);
