import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { forwardRef, useState } from "react";
import {
  useWorkDefectAutoComplete,
  useWorkDefectsCode,
} from "./useWorkDefectGetQuery";
// import { DefectForm } from "../../../Defect/form";
interface DefectProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export type DefectOption = {
  value: string;
  label: string;
  group: string;
};

export interface DefectAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  defectOperationCode?: string;
  onChange?: (DefectCode: string | null) => void;
  onSelectValue?: (data: string) => void;
  maxDropdownHeight?: number;
}

// 입력값: value (Defect 모델의 code)
// 출력값: onChange (Defect 모델의 code)

export const DefectAutoComplete = (params: DefectAutoCompleteProps) => {
  const {
    value: DefectCode,
    defectOperationCode,
    onChange,
    maxDropdownHeight,
    onSelectValue,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useWorkDefectAutoComplete(
    focused,
    searchKeyword,
    defectOperationCode
  );
  const { data: initialOptions } = useWorkDefectsCode(
    !!DefectCode,
    DefectCode ?? null,
    defectOperationCode
  );

  let selectedDefect = initialOptions?.find(
    (Defect) => Defect.value === DefectCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedDefect = options?.find((Defect) => Defect.value === e);
    onChange && onChange(e);
  };

  const SelectDefect = forwardRef<HTMLDivElement, DefectProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text fz="sm">{name}</Text>
            <Text fz="sm">(불량코드: {code})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <Select
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      value={DefectCode}
      itemComponent={SelectDefect}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: DefectProps[], option: DefectProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 250}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={DefectInfo({
        Defect: selectedDefect as DefectOption,
        onChange: onChangeHandler,
      })}
      nothingFound="검색 결과가 없습니다."
      {...etcParams}
    />
  );
};

const DefectInfo = (params: {
  Defect?: DefectOption;
  onChange: (DefectCode: string | null) => void;
}) => {
  const { Defect, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return Defect?.value ? <IconX size="1.5rem" onClick={clearHandler} /> : null;
};
