import ExpandableAccordion from "@/components/atom/accordion/expandable/ExpandableAccordion";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useWorkDataStore } from "@/store/work.store";
import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

const TempDefectInform = Object.assign(
  {},
  {
    Title: WorkTitle,
    Table: MantineDataTable,
    Accordion: ExpandableAccordion,
  }
);

export type DefectObject = {
  [key: string]: DefectObjectProps;
};

export interface DefectObjectProps {
  defectQuantity: string;
  reworkQuantity?: string;
  actualDefectQuantity?: string;
  defectName?: string;
  defectCode?: string;
}

export const TempDefectInformBox = () => {
  const { tempWork } = useLocalStorage();

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { work } = workData;

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "defectName",
        header: COMMON_TEXT.COLUMN.DEFECT_NAME,
      },
      {
        accessorKey: "defectQuantity",
        header: COMMON_TEXT.COLUMN.DEFECT_QUANTITY,
      },
    ],
    []
  );

  const getTempDefectList = (
    work: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined
  ) => {
    const defect = tempWork?.defects as DefectObject | undefined;
    if (!defect) return [];

    return Object.keys(defect).map((code) => ({
      defectName: defect[code]?.defectName ?? "",
      defectCode: code,
      defectQuantity: defect[code]?.defectQuantity ?? "",
      setUnitText: work?.item?.unitText ?? "",
    }));
  };

  const filterAndConvertDefectList = (
    defectList: {
      defectName: string;
      defectCode: string;
      defectQuantity: string;
      setUnitText: string;
    }[]
  ) => {
    return defectList
      .filter((defect) => defect.defectQuantity !== "0")
      .map(({ defectName, defectQuantity, setUnitText }) => ({
        defectName,
        defectQuantity: `${defectQuantity}${setUnitText}`,
      }));
  };

  const currentDefectList = getTempDefectList(work);
  const convertedDefectList = filterAndConvertDefectList(currentDefectList);

  return (
    <Flex direction={"column"}>
      <TempDefectInform.Table
        maxHeight={200}
        columns={columns}
        data={convertedDefectList ?? []}
      />
    </Flex>
  );
};
