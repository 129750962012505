import { routingOutsources } from "@/api/routingOutsources/useRoutingOutsourcesQuery";
import useFetcher from "@/hooks/useFetcher";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

interface useOutsourceFetchProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const useOutsourceFetch = ({ workData }: useOutsourceFetchProps) => {
  const { queryKey, queryFn } = routingOutsources.detail({
    routingOutsourceId: workData?.routingOutsourceId as number,
  });

  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    { enabled: !!workData?.routingOutsourceId }
  );

  return { data, Fetcher };
};
