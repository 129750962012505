import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import FormTemplate from "@/components/template/form/FormTemplate";
import { useModal } from "@/context/ModalStackManager";

const Notification = Object.assign(
  {},
  {
    SubTitle: FormSubTitle,
    Template: FormTemplate,
    Description: FormDescription,
  }
);

interface NotificationFormProps {
  message: string | React.ReactElement;
}

export const NotificationForm = ({ message }: NotificationFormProps) => {
  const { closeModal } = useModal();

  const handleSubmit = () => {
    closeModal(true); // 입력 값을 모달 결과로 전달
  };

  const handleClose = () => {
    closeModal(false); // 모달을 닫음
  };

  return (
    <Notification.Template onSubmit={handleSubmit} onCancel={handleClose}>
      <Notification.Description>{message}</Notification.Description>
    </Notification.Template>
  );
};
