import { inventories } from "@/api/inventories/useInventoriesQuery";
import useFetcher from "@/hooks/useFetcher";
import { DefaultApiInventoriesGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

interface useInspectLogsFormFetchProps {
  itemCodes: (string | undefined)[] | string | undefined;
  locationCode: string | undefined;
  lotId: string | undefined;
  enabled?: boolean;
}

export const useInventoriesFormFetch = ({
  itemCodes,
  locationCode,
  lotId,
  enabled,
}: useInspectLogsFormFetchProps) => {
  const request: DefaultApiInventoriesGetRequest = {
    query: {
      $and: [{ quantity: { $gt: 0 } }],
    },
    populate: ["lot", "item", "location"],
  };

  if (itemCodes) {
    request.query.$and.push({ itemCode: itemCodes });
  }
  if (lotId) {
    request.query.$and.push({ lotId: { $eq: lotId } });
  }
  if (locationCode) {
    request.query.$and.push({ locationCode: locationCode });
  }

  const { queryKey, queryFn } = inventories.get(request);

  const { data, Fetcher, refetch } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    { enabled: enabled }
  );

  return { data, Fetcher, refetch };
};
