import { StatusButton } from "@/components/atom/button/status/StatusButton";
import { ButtonConfig } from "@/components/organism/header/refactor/workHeader/WorkHeader";
import { customFunctions } from "@/config/customFunctions";
import { Flex } from "@mantine/core";

interface WorkStatusButtonProps {
  buttonConfigs: ButtonConfig[];
}

export const StatusButtonGroup = ({ buttonConfigs }: WorkStatusButtonProps) => {
  const matchingConfig = buttonConfigs.find((config) => config.condition?.());
  const refactorDesign = customFunctions.ADD_REFACTOR_DESIGN;

  return (
    <Flex gap={"xs"} direction={refactorDesign ? "column" : "row"}>
      {matchingConfig?.buttons.map((button) => (
        <StatusButton
          size={refactorDesign ? "xs" : "md"}
          key={button.key}
          color={button.color}
          onClick={button.onClick}
          leftIcon={button.icon}
        >
          {button.text}
        </StatusButton>
      ))}
    </Flex>
  );
};
