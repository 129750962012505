import { MasterInstance } from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiEquipmentsEquipmentIdGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const EQUIPMENTS_KEY = "equipments";

export const equipments = createQueryKeys(EQUIPMENTS_KEY, {
  all: null,
  detail: (params: MasterApiEquipmentsEquipmentIdGetRequest) => {
    return {
      queryKey: [params.equipmentId, params],
      queryFn: () => MasterInstance.equipmentsEquipmentIdGet(params),
    };
  },
});
