import { HeaderTabs } from "@/components/molecule/headerTabs/HeaderTabs";
import { LogsTabs } from "@/components/molecule/logsTabs/LogsTabs";
import { DefectInformBox } from "@/components/organism/work/refactor/DefectInformBox";
import { DefectLogsBox } from "@/components/organism/work/refactor/DefectLogsBox";
import { TempDefectInformBox } from "@/components/organism/work/refactor/TempDefectInformBox";
import { BoxTemplate } from "@/components/template/box/BoxTemplate";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";

const LogsInform = Object.assign(
  {},
  {
    Template: BoxTemplate,
    HeaderTabs: HeaderTabs,
  }
);

export const DefectInformTabs = () => {
  const tabs = [
    {
      label: "불량 보고",
      value: "first",
      content: <DefectInformBox />,
    },
    {
      label: customFunctions.ADD_TEMP_FUNCTION_ENABLED
        ? COMMON_TEXT.TITLE.CURRENT_LOT_DEFECT
        : COMMON_TEXT.TITLE.CURRENT_DEFECT,
      value: "second",
      content: <DefectLogsBox />,
    },
  ];

  if (customFunctions.ADD_TEMP_FUNCTION_ENABLED) {
    tabs.push({
      label: COMMON_TEXT.TITLE.TEMPORARY_LOT_DEFECT,
      value: "third",
      content: <TempDefectInformBox />,
    });
  }

  return (
    <LogsInform.Template>
      <LogsTabs tabs={tabs} />
    </LogsInform.Template>
  );
};
