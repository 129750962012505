import { BaseBox } from "@/components/atom/box/BaseBox";
import { BoxProps } from "@mantine/core";

interface InspectBox extends BoxProps {
  onClick?: () => void;
}

export const InspectBox = ({ onClick, ...etcProps }: InspectBox) => {
  return (
    <BaseBox
      onClick={onClick}
      w={"100%"}
      sx={(theme) => ({
        padding: theme.spacing.md,
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[4]}`,
        display: "flex",
        flexDirection: "column",
      })}
      {...etcProps}
    />
  );
};
